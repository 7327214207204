import React from "react";
import "./SubmissionPage.scss";


function Submission() {

    return (

        <div className="Container">
          <div className="logo">
            <label for="name"></label>
            <img src="/Submit.png" alt="submit logo" />
          </div>
          <h3 className="remarks">Thank you for contacting Noccarc Robotics. We have received your request. Our team will contact you soon.</h3>
        
      </div>
    );
}


export default Submission;