import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import { FLOW, MACHINE_GROUP_CONST, PAGE_CONST, SELL_MACHINE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";
import { CustomerDetail } from "../../components/CustomerDetail/CustomerDetail";
import { Header } from "../../components/Header/Header";
import { SuccessPage } from "../../components/SuccessPage/SuccessPage";
import { sellMachine } from "../../helper/machineHelper";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

import "./SellMachine.scss";

function SellMachine(props) {
    const navigate = useNavigate();

    const [filteredDataList, setFilteredDataList] = useState(props.machineGroupData);
    const [selectedDataList, setSelectedDataList] = useState([]);
    const [currentState, setCurrentState] = useState(SELL_MACHINE_CONST.SELECT_MACHINE);
    const [customerData, setCustomerData] = useState({})
    const [errorMsg, setErrorMsg] = useState(null);

    const handleHeaderBackClick = () => {

        if (currentState === SELL_MACHINE_CONST.CUSTOMER_DETAIL) {
            setCurrentState(SELL_MACHINE_CONST.SELECT_MACHINE);
        } else {
            redirect(navigate, PAGE_CONST.MACHINE_GROUP);
        }
    }

    const handleSearchChange = (event) => {
        let value = event.target.value;

        if (!value) {
            setFilteredDataList(props.machineGroupData);
            return;
        }

        let udl = props.machineGroupData.filter(
            (data) => data.serial_name.toLowerCase().indexOf(value.toLowerCase()) > -1
        );

        setFilteredDataList(udl);
    }

    const handleCheckboxClick = (event, data, type) => {

        let checked = event && event.target && event.target.checked;
        let sdl = selectedDataList;
        let index = sdl.indexOf(data);
        if (checked || (type && type !== SELL_MACHINE_CONST.REMOVE)) {
            if (index < 0) sdl.push(data);
        } else {
            if (index > -1) sdl.splice(index, 1);
        }

        setSelectedDataList([...sdl]);

        console.log("selectedDataList", selectedDataList);
    }

    const handleNextClick = () => {

        setCurrentState(SELL_MACHINE_CONST.CUSTOMER_DETAIL);
    }

    const handleSubmit = (data) => {

        setCustomerData(data);
        sellMachine(data)
            .then((res) => {
                console.log("res: " + res);
                setCurrentState(SELL_MACHINE_CONST.SUCCESS_PAGE);
            }).catch((err) => {
                setErrorMsg(err);
                console.log("err" + err);
            })
    }

    const successHomeClickHandler = () => {
        console.log("home clicked");
        redirect(navigate, PAGE_CONST.MY_MACHINE);
    }

    const successShareClickHandler = () => {
        console.log("share clicked");
    }

    const getSuccessMsg = () => {
        let message = `${selectedDataList.length} Machines sold to 
            '${customerData.customer && customerData.customer.organizationName}, 
            ${customerData.customer && customerData.customer.city}'  Successfully!`
        console.log(message);

        return message;
    }

    return (
        <div className="sell-machine-container">
            <Header handleBackClick={() => handleHeaderBackClick()} />
            {
                currentState === SELL_MACHINE_CONST.SELECT_MACHINE &&
                <>
                    <div className="machine-group-name">In Stock</div>
                    <div className="search-container">
                        <input
                            id="search-input"
                            onFocus={(event) => handleOnFocusById(event.target.id)}
                            className="search-input"
                            placeholder="Search"
                            onChange={(event) => handleSearchChange(event)}
                        />
                    </div>
                    <div className="sell-machine-content-container">
                        <div className="available-machines">
                            Available {`(${filteredDataList.length})`}
                        </div>
                        <div className="machine-content">
                            {
                                filteredDataList.map((data) => {
                                    return (<div className="content" key={data.id}>
                                        <div className="machine-detail">
                                            <div className="machine-name">
                                                {data.serial_name}
                                            </div>
                                            <div className="stock-date">

                                                {`Stock Entry Date: ${moment(data.stocked_at)
                                                    .format(MACHINE_GROUP_CONST.DATE_FORMAT)}`}
                                            </div>
                                        </div>
                                        <div className="checkbox-container">
                                            <input
                                                className="checkbox"
                                                type="checkbox"
                                                checked={selectedDataList.indexOf(data) > -1}
                                                onChange={(event) => handleCheckboxClick(event, data)}
                                            />
                                        </div>
                                    </div>);
                                })
                            }
                        </div>
                        <div className="button-container">
                            <button
                                className={selectedDataList.length <= 0 ? "submit-button button-disabled" : "submit-button"}
                                onClick={() => handleNextClick()}
                            >Next</button>
                        </div>
                    </div>
                </>
            }
            {
                currentState === SELL_MACHINE_CONST.CUSTOMER_DETAIL &&
                <CustomerDetail
                    type={FLOW.SELL_MACHINE}
                    machineList={selectedDataList}
                    removeSelectedMachine={(machine) => handleCheckboxClick(null, machine, SELL_MACHINE_CONST.REMOVE)}
                    activeGroup={props.activeGroup}
                    handleSubmit={(data) => handleSubmit(data)}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)}
                />
            }
            {
                currentState === SELL_MACHINE_CONST.SUCCESS_PAGE &&
                <SuccessPage
                    isCtaVisible={true}
                    homeClickHandler={() => successHomeClickHandler()}
                    shareClickHandler={() => successShareClickHandler()}
                    message={getSuccessMsg()}
                />
            }
        </div >
    )
}

const mapStateToProps = state => {

    let data = state.myMachineDataReducer.machineGroupData &&
        state.myMachineDataReducer.machineGroupData.filter((machine) => machine.status === MACHINE_GROUP_CONST.IN_STOCK)
    return {
        machineGroupData: data || [],
        activeGroup: state.activeDataReducer.activeGroup || {}
    };
};

const mapDispatchToProps = dispatch => {
    return {
        // machineGroupAction: (payload) => dispatch(machineGroupAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SellMachine);