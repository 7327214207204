import { combineReducers } from 'redux';
import { myMachineDataReducer } from "./my-machine-reducer";
import { activeDataReducer } from "./active-data-reducer";
import { activeOrderReducer } from './active-order-reducer';
import { userDataReducer } from './user-data-reducer';

export const reducer = combineReducers({
    myMachineDataReducer: myMachineDataReducer,
    activeDataReducer: activeDataReducer,
    activeOrderReducer: activeOrderReducer,
    userDataReducer: userDataReducer
});