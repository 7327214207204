import {
    addBillOfMaterialAPI,
    addFieldReturnAPI,
    addPMBillOfMaterialAPI,
    addPMRemarkAPI,
    addPreventiveFieldReturnAPI,
    addPreventiveMaintenanceCustomerDetailAPI,
    addRemarkAPI,
    createObservationsAPI,
    createPreventiveMaintenanceOrderAPI,
    getBillOfMaterialsAPI,
    getModeTestingStageDetailsAPI,
    getObservationIssuesAPI,
    getPMBillOfMaterialsAPI,
    getPreventiveMaintenanceChecklistStageDetailsAPI,
    getPreventiveMaintenanceOrderAPI,
    updateMachineStatusAPI,
    updateModeTestingStageDetailsAPI,
    updatePreventiveMaintenanceChecklistStageDetailsAPI,
    uploadFileAPI
} from "../adapter/service-adapter";
import { PREVENTIVE_MAINTENANCE_CONST } from "../common/constant/page-constants";

export const createPreventiveMaintenanceOrder = (data) => {

    let reqData = {
        machine_serial_name: data.machineSerialNumber,
        product_id: data.productId,
        contact_person_details: {
            name: data.contactName,
            number: data.contactNumber,
            email: data.email
        }
    }

    let promise = new Promise(function (resolve, reject) {
        createPreventiveMaintenanceOrderAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200 && res.data.data.id) {
                resolve(res.data.data.id);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const getPreventiveMaintenanceOrder = (data) => {

    let reqData = {
        preventive_maintenance_order_id: data.id
    }

    let promise = new Promise(function (resolve, reject) {
        getPreventiveMaintenanceOrderAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200 && res.data.data) {
                // res.data.data.stage = "MACHINE_STATUS";
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const addPreventiveMaintenanceCustomerDetail = (data) => {

    let reqData = {
        preventive_maintenance_order_id: data.orderId,
        customer_details: {
            warranty_status: data.customer.warrantyType,
            start_date: data.customer.startDate,
            end_date: data.customer.endDate,
            organization_name: data.customer.organizationName,
            address: data.customer.address,
            pincode: data.customer.pincode,
            city: data.customer.city,
            state: data.customer.state
        }
    }

    let promise = new Promise(function (resolve, reject) {
        addPreventiveMaintenanceCustomerDetailAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const updatePreventiveMaintenaceSubStage = (type, data) => {

    if (type === PREVENTIVE_MAINTENANCE_CONST.STAGES.PREVENTIVE_MAINTENANCE_CHECKLIST) {
        return updatePreventiveMaintenanceChecklistStageDetails(data);
    } else if (type === PREVENTIVE_MAINTENANCE_CONST.STAGES.MODE_TESTING) {
        return updateModeStageDetails(data);
    }
}

export const getPreventiveMaintenaceSubStage = (type, data) => {

    if (type === PREVENTIVE_MAINTENANCE_CONST.STAGES.PREVENTIVE_MAINTENANCE_CHECKLIST) {
        return getPreventiveMaintenanceChecklistStageDetails(data);
    } else if (type === PREVENTIVE_MAINTENANCE_CONST.STAGES.MODE_TESTING) {
        return getModeStageDetails(data);
    }

    getModeStageDetails(data);
}

function getModeStageDetails(data) {

    let reqData = {
        preventive_maintenance_order_id: data.orderId,
        stage_id: data.subStageId
    }

    let promise = new Promise(function (resolve, reject) {
        getModeTestingStageDetailsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

function updateModeStageDetails(data) {

    let reqData = {
        preventive_maintenance_order_id: data.orderId,
        stage_id: data.subStageId,
        stage_detail_data: data.stageData
    }

    let promise = new Promise(function (resolve, reject) {
        updateModeTestingStageDetailsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

function getPreventiveMaintenanceChecklistStageDetails(data) {

    let reqData = {
        preventive_maintenance_order_id: data.orderId,
        stage_id: data.subStageId
    }

    let promise = new Promise(function (resolve, reject) {
        getPreventiveMaintenanceChecklistStageDetailsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

function updatePreventiveMaintenanceChecklistStageDetails(data) {

    let reqData = {
        preventive_maintenance_order_id: data.orderId,
        stage_id: data.subStageId,
        stage_detail_data: data.stageData
    }

    let promise = new Promise(function (resolve, reject) {
        updatePreventiveMaintenanceChecklistStageDetailsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export function addPMBillOfMaterial(data) {
    let reqData = {
        "preventive_maintenance_order_id": data.orderId,
        "bill_type": data.billType,
        "spare_part_action": data.spareType,
        "spare_part_component_type_id": data.sparePartName,
        "job_type": data.jobType,
        "new_serial_part_name": data.newPartSerialNumber,
        "part_mrp": data.partMrp,
        "discount": data.discount,
        "labour_type": data.labourType,
        "labour_charges": data.labourCharges
    }

    let promise = new Promise(function (resolve, reject) {
        addPMBillOfMaterialAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export function getPMBillOfMaterials(data) {
    let reqData = {
        preventive_maintenance_order_id: data.orderId
    }

    let promise = new Promise(function (resolve, reject) {
        getPMBillOfMaterialsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const uploadFile = (data) => {
    let formData = new FormData();

    let file = data.pictures && data.pictures[0];
    formData.append("file", file);

    let promise = new Promise(function (resolve, reject) {
        uploadFileAPI(formData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data.url);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const createObservations = (data) => {

    let reqData = {
        breakdown_order_id: data.orderId,
        ui_version: data.uiVersion,
        firmware_version: data.firmwareVersion,
        is_physically_damaged: data.physicalDamage,
        issue_ids: data.issueObserved,
        issue_details: data.otherIssue,
        machine_image_url: data.url
    }

    let promise = new Promise(function (resolve, reject) {
        createObservationsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const addFieldReturn = (data) => {


    let reqData = {
        "preventive_maintenance_order_id": data.orderId,
        "is_pickup_requested": data.requestPickup,
        "remark": data.remark,
        "organization_address": data.address,
        "pincode": data.pincode,
        "city": data.city,
        "state": data.state
    }

    let promise = new Promise(function (resolve, reject) {
        addPreventiveFieldReturnAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const addRemark = (data) => {

    let reqData = {
        "preventive_maintenance_order_id": data.orderId,
        "is_training_given": data.trainingGiven,
        "number_of_people_trained": data.numberOfPerson,
        "solution_provided": data.solutionProvided,
        "closing_remarks": data.closingRemark
    }

    let promise = new Promise(function (resolve, reject) {
        addPMRemarkAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const updateMachineStatus = (data) => {

    let reqData = {
        "preventive_maintenance_order_id": data.orderId,
        "is_machine_working": data.machineStatus,
        "issue_ids": data.issueObserved,
        "issue_details": data.issueDetail
    }

    let promise = new Promise(function (resolve, reject) {
        updateMachineStatusAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}
