import { getJobCardAPI } from "../adapter/service-adapter";

export const getJobCards = (data) => {
    let reqData = {
        // mobile_no: data.phoneNumber
    }

    let promise = new Promise(function (resolve, reject) {
        getJobCardAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}