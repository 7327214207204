export const PAGE_CONST = {
  LOGIN: "LOGIN",
  MACHINE_GROUP: "MACHINE_GROUP",
  MY_MACHINE: "MY_MACHINE",
  MACHINE_DETAIL: "MACHINE_DETAIL",
  SELL_MACHINE: "SELL_MACHINE",
  INSTALL_MACHINE: "INSTALL_MACHINE",
  BREAKDOWN_ORDER: "BREAKDOWN_ORDER",
  BREAKDOWN_CALL: "BREAKDOWN_CALL", // TODO: from backend in job card
  PREVENTIVE_MAINTENANCE: "PREVENTIVE_MAINTENANCE",
  USER_PROFILE: "USER_PROFILE",
  MY_TEAM: "MY_TEAM",
  ADD_TEAM_MEMBER: "ADD_TEAM_MEMBER",
  COMPLAINT_ISSUE_FORM: "COMPLAINT_ISSUE_FORM",
  RCA_FORM: "RCA_FORM",
  PREVENTIVE_MAINTENANCE_CHECK_FORM: "PREVENTIVE_MAINTENANCE_CHECK_FORM",
  SERVICE_MANAGEMENT: "SERVICE_MANAGEMENT",
};

export const FLOW = {
  SELL_MACHINE: "SELL_MACHINE",
  BREAKDOWN: "BREAKDOWN_ORDER",
  INSTALL_ORDER: "INSTALL_ORDER",
  INSTALLATION: "INSTALLATION",
  PREVENTIVE_MAINTENANCE: "PREVENTIVE_MAINTENANCE_ORDER",
};

export const LOGIN_CONST = {
  SEND_OTP: "Send OTP",
  VERIFY_OTP: "Verify OTP",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
};

export const MY_MACHINE_CONST = {
  MACHINE: "Machine",
  SPARE: "Spare",
};

export const MACHINE_GROUP_CONST = {
  IN_STOCK: "IN_STOCK",
  INSTALLED: "INSTALLED",
  PENDING: "INSTALLATION_PENDING",
  DATE_FORMAT: "DD-MM-YYYY",
};

export const MACHINE_DETAIL_CONST = {
  GENERAL: "GENERAL",
  REPORTS: "REPORTS",
  PRODUCT: "PRODUCT",
  CUSTOMER: "CUSTOMER",
  DEALER: "DEALER",
};

export const PROGRESS_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  COMPLETED: "COMPLETED",
  IN_DEFECT: "IN_DEFECT",
  IN_PROGRESS: "IN_PROGRESS",
};

export const SELL_MACHINE_CONST = {
  REMOVE: "REMOVE",
  ADD: "ADD",
  SELECT_MACHINE: "SELECT_MACHINE",
  CUSTOMER_DETAIL: "CUSTOMER_DETAIL",
  SUCCESS_PAGE: "SUCCESS_PAGE",
};

export const INSTALL_MACHINE_CONST = {
  STATE: {
    INSTALL_STAGES: "INSTALL_STAGES",
    INSTALL_SUB_STAGES: "INSTALL_SUB_STAGES",
    SUCCESS_PAGE: "SUCCESS_PAGE",
  },
  STAGES: {
    CUSTOMER_DETAIL: "CUSTOMER_DETAIL",
    INSTALLATION_REPORT: "INSTALLATION_REPORT",
    OTP_CONFIRMATION: "OTP_CONFIRMATION",
  },
  SUB_STAGES: {
    GENERAL_CHECKS: "GENERAL CHECKS",
    CHECKS: "CHECKS",
    CUSTOMER_INFORMATION: "CUSTOMER INFORMATION",
    SCOPE_OF_SUPPLY: "SCOPE OF SUPPLY",
    PEG: "PEG",
    REMARKS: "REMARKS",
  },

  SUBSTATE: {
    STAGE_DETAILS: "STAGE_DETAIL",
    SUBSTAGE_DETAIL: "SUBSTAGE",
  },
};

export const BREAKDOWN_CONST = {
  STATE: {
    BREAKDOWN_FORM: "BREAKDOWN_FORM",
    BREAKDOWN_STAGES: "BREAKDOWN_STAGES",
    BREAKDOWN_SUB_STAGES: "BREAKDOWN_SUB_STAGES",
    SUCCESS_PAGE: "SUCCESS_PAGE",
  },
  STAGES: {
    CUSTOMER_DETAIL: "CUSTOMER_DETAILS",
    OBSERVATION: "OBSERVATIONS",
    MACHINE_STATUS: "MACHINE_STATUS",
    REMARK: "REMARK",
    OTP_CONFIRMATION: "OTP_CONFIRMATION",
  },
  SUB_STAGES: {
    BILL_MATERIAL: "BILL_MATERIAL",
    FIELD_RETURN: "FIELD_RETURN",
  },
  STATUS: {
    NOT_STARTED: "NOT_STARTED",
    COMPLETED: "COMPLETED",
    IN_DEFECT: "IN_DEFECT",
  },
};

export const PREVENTIVE_MAINTENANCE_CONST = {
  STATE: {
    PREVENTIVE_MAINTENANCE_FORM: "PREVENTIVE_MAINTENANCE_FORM",
    PREVENTIVE_MAINTENANCE_STAGES: "PREVENTIVE_MAINTENANCE_STAGES",
    PREVENTIVE_MAINTENANCE_SUB_STAGES: "PREVENTIVE_MAINTENANCE_SUB_STAGES",
    SUCCESS_PAGE: "SUCCESS_PAGE",
  },
  STAGES: {
    CUSTOMER_DETAIL: "CUSTOMER_DETAILS",
    PREVENTIVE_MAINTENANCE_CHECKLIST: "PREVENTIVE_MAINTENANCE_CHECKLIST",
    MODE_TESTING: "MODE_TESTING",
    MACHINE_STATUS: "MACHINE_STATUS",
    REMARK: "REMARK",
    OTP_CONFIRMATION: "OTP_CONFIRMATION",
  },
  SUB_STAGES: {
    VISUAL_INSPECTION: "VISUAL INSPECTION",
    CHECKS: "CHECKS",
    BILL_MATERIAL: "BILL_MATERIAL",
    FIELD_RETURN: "FIELD_RETURN",
    MACHINE_STATUS: "MACHINE_STATUS",
    "VC-CMV": "VC-CMV",
    "PC-AC": "PC-AC",
    "PRVC-SIMV": "PRVC-SIMV",
    "NIV-APRV": "NIV-APRV",
  },
  STATUS: {
    NOT_STARTED: "NOT_STARTED",
    COMPLETED: "COMPLETED",
    IN_DEFECT: "IN_DEFECT",
  },
};

export const BILL_MATERIAL_CONST = {
  STATE: {
    BILL_SUMMARY: "BILL_SUMMARY",
    ADD_BILL: "ADD_BILL",
  },
};

export const JOB_CARD_COMPLAINT = {
  GROUP: {
    JOB_CARD: "JOB_CARD",
    COMPLAINTS: "COMPLAINTS",
  },
};

export const MY_TEAM_CONST = {
  GROUP: {
    ALL_MEMBERS: "ALL_MEMBERS",
    ADMIN: "ADMIN",
    TEAM: "TEAM_MEMBER",
  },
  SLIDER_ACTIVATE: "SLIDER_ACTIVATE",
  SLIDER_DEACTIVATE: "SLIDER_DEACTIVATE",
  EDIT_MEMBER: "EDIT_MEMBER",
};

export const FOOTER = {
  HOME: "HOME",
  SERVICE: "SERVICE",
  USER: "USER",
};
export const UPLOAD = {
  TYPE: {
    IMAGE: "IMAGE",
    DOCUMENT: "DOCUMENT",
  },
};
