import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BREAKDOWN_CONST, FLOW, LOGIN_CONST, PAGE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";
import { BreakDownForm } from "../../components/BreakDownForm/BreakDownForm";
import { CustomerDetail } from "../../components/CustomerDetail/CustomerDetail";
import { FieldReturn } from "../../components/FieldReturn/FieldReturn";
import { Header } from "../../components/Header/Header";
import { Observation } from "../../components/Observation/Observation";
import { Remark } from "../../components/Remark/Remark";
import { SuccessPage } from "../../components/SuccessPage/SuccessPage";
import { BillMaterial } from "../../container/BillMaterial/BillMaterial";
import { BreakDownCallStages } from "../../container/BreakDownCallStages/BreakDownCallStages";
import {
    createBreakdownOrder,
    getBreakdownOrder,
    addBreakDownCustomerDetail,
    getObservationIssues,
    uploadFile,
    createObservations,
    addFieldReturn,
    addRemark
}
    from "../../helper/BreakdownHelper";
import { getMachineDetailsByName, sendFlowOtp, verifyFlowOtp } from "../../helper/CommonHelper";
import { activeBreakDownOrder } from "../../modules/actions/action";

import "./BreakDownCall.scss";

function BreakDownCall(props) {
    const navigate = useNavigate();
    const [activeState, setActiveState] = useState(BREAKDOWN_CONST.STATE.BREAKDOWN_FORM);
    const [activeStage, setActiveStage] = useState(BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL);
    const [activeSubStage, setActiveSubStage] = useState(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN);
    const [orderData, setOrderData] = useState(null);
    const [observationIssues, setObservationIssues] = useState([]);
    const [otpText, setOtpText] = useState(LOGIN_CONST.SEND_OTP);
    const [machineDetail, setMachineDetail] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    // useEffect(() => {

    //     // get breadown order data
    //     console.log(" use effect breakdown order", props.orderId);
    //     if (props.orderId) {
    //         getOrderData();
    //     }

    // }, []);

    useEffect(() => {

        if (((activeState === BREAKDOWN_CONST.STATE.STAGES) || !orderData) && props.orderId) {
            getOrderData();
        }
    }, [props.orderId, activeState]);

    const getOrderData = (id) => {

        let data = {
            id: props.orderId || id
        }

        getBreakdownOrder(data)
            .then((res) => {
                console.log(res);
                setOrderData(res);
                getNextState(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getNextState = (data) => {

        if (data) {
            if (data.stage === BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL) {

                setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
                setActiveStage(BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL);
            } else if (data.stage === BREAKDOWN_CONST.STAGES.OBSERVATION) {

                setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
                setActiveStage(BREAKDOWN_CONST.STAGES.OBSERVATION);
                getObservationIssueOptions(data.product_id);
            } else if (data.stage === BREAKDOWN_CONST.STAGES.MACHINE_STATUS) {

                setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
                setActiveStage(BREAKDOWN_CONST.STAGES.MACHINE_STATUS);

                if (data.machine_status) {
                    if (data.machine_status && data.machine_status.bill_of_materials.status === "NOT_STARTED") {
                        setActiveSubStage(BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL);
                    } else if (data.field_return.status === "NOT_STARTED") {
                        setActiveSubStage(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN);
                    }
                }
                setActiveSubStage(BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL);
            } else if (data.stage === BREAKDOWN_CONST.STAGES.REMARK) {

                setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
                setActiveStage(BREAKDOWN_CONST.STAGES.REMARK);
            } else if (data.stage === BREAKDOWN_CONST.STAGES.OTP_CONFIRMATION) {

                if (data.status === BREAKDOWN_CONST.STATUS.COMPLETED) {
                    setActiveState(BREAKDOWN_CONST.STATE.SUCCESS_PAGE);
                } else {
                    setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
                    setActiveStage(BREAKDOWN_CONST.STAGES.OTP_CONFIRMATION);
                }
            }
        }
    }

    const handleStageClick = (type) => {

        if (type === BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL) {
            setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES);
            setActiveStage(BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL);

        } else if (type === BREAKDOWN_CONST.STAGES.OBSERVATION) {
            setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES);
            setActiveStage(BREAKDOWN_CONST.STAGES.OBSERVATION);

        } else if (type === BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL) {
            setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES);
            setActiveStage(BREAKDOWN_CONST.STAGES.MACHINE_STATUS);
            setActiveSubStage(BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL);

        } else if (type === BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN) {
            setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES);
            setActiveStage(BREAKDOWN_CONST.STAGES.MACHINE_STATUS);
            setActiveSubStage(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN);

        } else if (type === BREAKDOWN_CONST.STAGES.REMARK) {

            setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES);
            setActiveStage(BREAKDOWN_CONST.STAGES.REMARK);

        }
    }

    const handleHeaderBackClick = () => {

        if (activeState === BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES) {
            setActiveState(BREAKDOWN_CONST.STATE.STAGES);
        } else {
            redirect(navigate, PAGE_CONST.SERVICE_MANAGEMENT);
        }
    }

    const onBillMaterialSubmit = () => {
        setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
        setActiveSubStage(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN);
        getOrderData();
    }

    const onFieldReturnSubmit = (data) => {

        if (data) data.orderId = props.orderId;
        addFieldReturn(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            }).catch((err) => {
                console.log(err);
            })
    }

    const onFieldReturnCancel = () => {

    }

    const handleNextState = () => {

        if (activeState === BREAKDOWN_CONST.STATE.BREAKDOWN_FORM) {
            setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
        }

    }

    const getMachineDetailsBySerialName = (serialName) => {

        let data = {
            serialName: serialName
        }

        getMachineDetailsByName(data)
            .then((res) => {
                if (res && res.machine_details) setMachineDetail(res.machine_details);
            }).catch(() => {

            })
    }

    const onBreakDownFormSubmit = (data) => {

        console.log(data);
        createBreakdownOrder(data)
            .then((id) => {
                let data = {
                    id: id
                }

                props.activeBreakDownOrder(data);
                setActiveState(BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES);
                getOrderData(id);
            })
            .catch((err) => {
                setErrorMsg(err);
                console.log(err);
            })
    }

    const onBreakDownCancel = () => {
        redirect(navigate, PAGE_CONST.MY_MACHINE);
        console.log("on cancel");
    }

    const handleCustomerDetailsSubmit = (data) => {

        if (!data) return;

        data.orderId = props.orderId;

        addBreakDownCustomerDetail(data)
            .then((res) => {
                console.log(res);
                getOrderData();

            })
            .catch((err) => {
                setErrorMsg(err);
                console.log(err);
            })
    }

    const getObservationIssueOptions = (productId) => {

        // TODO: 
        let data = {
            productId: productId
        }

        getObservationIssues(data)
            .then((res) => {
                console.log(res);
                setObservationIssues(res);
            }).catch((err) => {
                console.log(err);
            })
    }

    const handleObservationSubmit = async (data) => {

        if (!data) return;

        let url = "";

        if (data && data.pictures && data.pictures.length > 0) {
            url = await uploadFile(data);
            data.url = url;
        }

        data.orderId = props.orderId;

        console.log(data);

        createObservations(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            }).catch((err) => {
                console.log(err)
            })
    }

    const handleRemarkSubmit = (data) => {

        if (data) data.orderId = props.orderId;

        addRemark(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            }).catch((err) => {
                console.log(err);
            })
    }

    const handleOtpSubmit = (otp) => {

        console.log(otpText);


        setErrorMsg(null);
        let data = {
            flow: PAGE_CONST.BREAKDOWN_CALL,
            orderId: props.orderId,
            mobileNo: (orderData && orderData.otp_confirmation && orderData.otp_confirmation.mobile_no)
        }

        if (otpText === LOGIN_CONST.SEND_OTP) {

            sendFlowOtp(data)
                .then((res) => {
                    console.log(res);

                    setOtpText(LOGIN_CONST.VERIFY_OTP);
                }).catch((err) => {
                    console.log(err);
                })
        } else if (otpText === LOGIN_CONST.VERIFY_OTP) {

            data.otp = otp;

            verifyFlowOtp(data)
                .then((res) => {
                    console.log(res);
                    setActiveState(BREAKDOWN_CONST.STATE.SUCCESS_PAGE);
                }).catch((err) => {
                    console.log(err);
                    setErrorMsg(err);
                })
        }
    }

    const getSuccessMsg = () => {
        let message = `Service request for machine ${orderData.machine_serial_name} has been successfully closed!`;

        return message;
    }

    const successHomeClickHandler = () => {
        console.log("success home clicked !!");

        props.activeBreakDownOrder({});
        redirect(navigate, PAGE_CONST.MACHINE_GROUP);
    }

    const successShareClickHandler = () => {
        console.log("success share clicked !!");
    }

    const getSubStageComponent = () => {
        if (activeState === BREAKDOWN_CONST.STATE.BREAKDOWN_SUB_STAGES) {

            if (activeStage === BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL) {
                return <CustomerDetail
                    type={FLOW.BREAKDOWN}
                    handleSubmit={(data) => handleCustomerDetailsSubmit(data)}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)}
                />
            } else if (activeStage === BREAKDOWN_CONST.STAGES.OBSERVATION) {
                return <Observation
                    observationIssuesOptions={observationIssues}
                    handleSubmit={(data) => handleObservationSubmit(data)}
                />
            } else if (activeStage === BREAKDOWN_CONST.STAGES.MACHINE_STATUS) {

                if (activeSubStage === BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL) {
                    return <BillMaterial
                        onSubmit={() => onBillMaterialSubmit()}
                        flow={FLOW.BREAKDOWN}
                        orderId={props.orderId}
                        data={orderData}
                    />
                } else if (activeSubStage === BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN) {
                    return <FieldReturn
                        onSubmit={(data) => onFieldReturnSubmit(data)}
                        onCancel={() => onFieldReturnCancel()}
                    />
                }
            } else if (activeStage === BREAKDOWN_CONST.STAGES.REMARK) {
                return <Remark
                    onSubmit={(data) => handleRemarkSubmit(data)} />
            }
        }
    }

    return (
        <div className="breakdown-call-container">
            <Header handleBackClick={() => handleHeaderBackClick()} />
            {activeState === BREAKDOWN_CONST.STATE.BREAKDOWN_FORM &&
                <BreakDownForm
                    onSubmit={(data) => onBreakDownFormSubmit(data)}
                    onCancel={() => onBreakDownCancel()}
                    machineDetail={machineDetail}
                    flow={FLOW.BREAKDOWN}
                    getMachineDetailsBySerialName={(serialName) => getMachineDetailsBySerialName(serialName)}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)}
                />
            }
            {activeState === BREAKDOWN_CONST.STATE.BREAKDOWN_STAGES &&
                <BreakDownCallStages
                    activeStage={activeStage}
                    activeSubStage={activeSubStage}
                    data={orderData}
                    handleStageClick={(type) => handleStageClick(type)}
                    handleOtpSubmit={(otp) => handleOtpSubmit(otp)}
                    otpText={otpText}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)} />}
            {activeState === BREAKDOWN_CONST.STATE.SUCCESS_PAGE &&
                <SuccessPage
                    message={getSuccessMsg()}
                    isCtaVisible={true}
                    homeClickHandler={() => successHomeClickHandler()}
                    shareClickHandler={() => successShareClickHandler()}
                />}
            {getSubStageComponent()}
        </div>
    )
}

const mapStateToProps = state => {

    return {
        orderId: state.activeOrderReducer && state.activeOrderReducer.breakDownOrder && state.activeOrderReducer.breakDownOrder.id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activeBreakDownOrder: (payload) => dispatch(activeBreakDownOrder(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BreakDownCall);