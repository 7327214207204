import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";

import { MachineCard } from "../../components/MachineCard/MachineCard";

import { MACHINE_GROUP_CONST, PAGE_CONST } from "../../common/constant/page-constants";

import "./MachineGroup.scss";

import { redirect } from "../../common/utils/RedirectionUtils";
import { connect } from "react-redux";
import { getMachineGroupAPI } from "../../helper/machineHelper";
import { activeMachineAction, machineGroupAction } from "../../modules/actions/action";

function MachineGroup(props) {
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState(MACHINE_GROUP_CONST.IN_STOCK);

    useEffect(() => {

        let data = {
            productId: props.activeGroup.product_id
        }

        getMachineGroupAPI(data)
            .then((res) => {
                props.machineGroupAction(res.machines);
            })
            .catch((err) => {
                console.log("machine group err: ", err);
            })
    }, [])

    const handleHeaderClick = (item) => {
        setActiveItem(item);
    }

    const handleCardClick = (data) => {
        props.activeMachineAction(data);
        redirect(navigate, PAGE_CONST.MACHINE_DETAIL);
    }

    const getGroupData = (status) => {
        return props.machineGroupData.filter((data) => data.status === status)
    }

    const handleHeaderBackClick = () => {
        redirect(navigate, PAGE_CONST.MY_MACHINE);
    }

    const handleSellMachine = () => {

        redirect(navigate, PAGE_CONST.SELL_MACHINE);
    }

    const handleMachineInstallClick = (data) => {
        // console.log(data);
        props.activeMachineAction(data);
        redirect(navigate, PAGE_CONST.INSTALL_MACHINE);
    }

    return (
        <div className="machine-group-container">
            <Header handleBackClick={() => handleHeaderBackClick()} />
            <div className="machine-group-name">{props.activeGroup.product_name}</div>
            <div className="machine-group-content-container">
                <div className="machine-group-content-header">
                    <div
                        className={activeItem === MACHINE_GROUP_CONST.IN_STOCK ?
                            "machine-group-header machine active-header" : "machine-group-header machine"}
                        onClick={() => handleHeaderClick(MACHINE_GROUP_CONST.IN_STOCK)}>
                        In Stock {`(${getGroupData(MACHINE_GROUP_CONST.IN_STOCK).length})`}
                    </div>
                    <div
                        className={activeItem === MACHINE_GROUP_CONST.PENDING ?
                            "machine-group-header spare active-header" : "machine-group-header spare"}
                        onClick={() => handleHeaderClick(MACHINE_GROUP_CONST.PENDING)}>
                        Pending {`(${getGroupData(MACHINE_GROUP_CONST.PENDING).length})`}
                    </div>
                    <div
                        className={activeItem === MACHINE_GROUP_CONST.INSTALLED ?
                            "machine-group-header spare active-header" : "machine-group-header spare"}
                        onClick={() => handleHeaderClick(MACHINE_GROUP_CONST.INSTALLED)}>
                        Installed {`(${getGroupData(MACHINE_GROUP_CONST.INSTALLED).length})`}
                    </div>
                </div>
                <div className="machine-group-content-data">
                    {
                        getGroupData(activeItem).map((data) => {
                            return (
                                <div key={data.id}>
                                    <MachineCard
                                        data={data}
                                        id={data.id}
                                        onCardClick={(data) => handleCardClick(data)}
                                        onInstallClick={(data) => handleMachineInstallClick(data)}
                                    />
                                </ div>);
                        })
                    }
                </div>
                {activeItem === MACHINE_GROUP_CONST.IN_STOCK &&
                    <div className="sell-button-container" onClick={() => handleSellMachine()}>
                        <button className="submit-button">Sell Machine</button>
                    </div>}
            </div>
        </div>
    );
}

const mapStateToProps = state => {

    return {
        activeGroup: state.activeDataReducer.activeGroup || {},
        machineGroupData: state.myMachineDataReducer.machineGroupData || []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        machineGroupAction: (payload) => dispatch(machineGroupAction(payload)),
        activeMachineAction: (payload) => dispatch(activeMachineAction(payload))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineGroup);