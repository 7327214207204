import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { reducer } from "../reducers/reducer";
import initialState from "./initial-state";

const persistConfig = {
  key: 'service-app-root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = createStore(persistedReducer, initialState);
export const persistor = persistStore(store);