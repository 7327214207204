import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BREAKDOWN_CONST, FLOW, LOGIN_CONST, PAGE_CONST, PREVENTIVE_MAINTENANCE_CONST, PROGRESS_STATUS } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";
import { BreakDownForm } from "../../components/BreakDownForm/BreakDownForm";
import { CustomerDetail } from "../../components/CustomerDetail/CustomerDetail";
import { FieldReturn } from "../../components/FieldReturn/FieldReturn";
import { Header } from "../../components/Header/Header";
import { MachineStatusForm } from "../../components/MachineStatusForm/MachineStatusForm";
import { Observation } from "../../components/Observation/Observation";
import { Remark } from "../../components/Remark/Remark";
import { SuccessPage } from "../../components/SuccessPage/SuccessPage";
import { BillMaterial } from "../../container/BillMaterial/BillMaterial";
import { BreakDownCallStages } from "../../container/BreakDownCallStages/BreakDownCallStages";
import { InstallMachineSubStage } from "../../container/InstallMachineSubStage/InstallMachineSubStage";
import { PreventiveMaintenanceStages } from "../../container/PreventiveMaintenanceStages/PreventiveMaintenanceStages";
import { getObservationIssues } from "../../helper/BreakdownHelper";
import { getMachineDetailsByName, sendFlowOtp, verifyFlowOtp } from "../../helper/CommonHelper";
import { addFieldReturn, addPreventiveMaintenanceCustomerDetail, addRemark, createPreventiveMaintenanceOrder, getPreventiveMaintenaceSubStage, getPreventiveMaintenanceChecklistStageDetails, getPreventiveMaintenanceOrder, updateMachineStatus, updatePreventiveMaintenaceSubStage, updatePreventiveMaintenanceChecklistStageDetails } from "../../helper/PreventiveMaintenanceHelper";
import { activePreventiveMaintainanceOrder } from "../../modules/actions/action";
import "./PreventiveMaintenance.scss";

function PreventiveMaintenance(props) {
    const navigate = useNavigate();
    const [activeState, setActiveState] = useState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_FORM);
    const [activeStage, setActiveStage] = useState(BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL);
    const [activeSubStage, setActiveSubStage] = useState(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN);
    const [orderData, setOrderData] = useState(null);
    const [observationIssues, setObservationIssues] = useState([]);
    const [otpText, setOtpText] = useState(LOGIN_CONST.SEND_OTP);
    const [machineDetail, setMachineDetail] = useState(null);
    const [subStageData, setSubStageData] = useState(null);
    const [subStageMetaData, setSubStageMetaData] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {

        if (((activeState === BREAKDOWN_CONST.STATE.STAGES) || !orderData) && props.orderId) {
            getOrderData();
        }
    }, [props.orderId, activeState]);

    const getOrderData = (id) => {

        let data = {
            id: props.orderId || id
        }

        getPreventiveMaintenanceOrder(data)
            .then((res) => {
                console.log(res);
                setOrderData(res);
                getNextState(res);
            })
            .catch((err) => {
                setErrorMsg(err);
                console.log(err);
            })
    }

    const getNextState = (data) => {

        if (data) {

            let stage = data.stage.toLowerCase();
            if (stage === PREVENTIVE_MAINTENANCE_CONST.STAGES.CUSTOMER_DETAIL.toLowerCase()) {

                setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
                setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.CUSTOMER_DETAIL);

                return;
            } else if (data.stage === PREVENTIVE_MAINTENANCE_CONST.STAGES.REMARK) {

                setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
                setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.REMARK);
                return;
            } else if (data.stage === PREVENTIVE_MAINTENANCE_CONST.STAGES.OTP_CONFIRMATION) {

                if (data.status === PREVENTIVE_MAINTENANCE_CONST.STATUS.COMPLETED) {
                    setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.SUCCESS_PAGE);
                } else {
                    setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
                    setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.OTP_CONFIRMATION);
                }

                return;
            } else if (data.stage === PREVENTIVE_MAINTENANCE_CONST.STAGES.MACHINE_STATUS) {

                getObservationIssueOptions(data.product_id);
                setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
                setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.MACHINE_STATUS);

                if (data.machine_status) {
                    if (data.machine_status.bill_of_materials.status === "NOT_STARTED") {
                        setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.BILL_MATERIAL);
                    } else if (data.machine_status.field_return.status === "NOT_STARTED") {
                        setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.FIELD_RETURN);
                    } else if (data.machine_status.machine_status.status === "NOT_STARTED") {
                        setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.MACHINE_STATUS);
                    }

                    return;
                }
            }

            /** preventive maintainance */
            let stageData = data[stage];
            let inCompleteSubStage = stageData
                .filter((item) => !item.status || item.status != PROGRESS_STATUS.COMPLETED)[0];

            if (inCompleteSubStage) {
                setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
                setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES[stage.toUpperCase()]);
                setActiveSubStage(inCompleteSubStage.name);
                return;
            }

            /** mode testing */
            // let modeTesting = data.mode_testing;
            // inCompleteSubStage = modeTesting
            //     .filter((item) => !item.status || item.status != PROGRESS_STATUS.COMPLETED)[0];

            // if (inCompleteSubStage) {
            //     setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
            //     setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.MODE_TESTING);
            //     setActiveSubStage(inCompleteSubStage);
            // }

            /**
             * 
             */
        }
    }

    const updateSubStageDetail = (stageData, type) => {

        let data = {
            orderId: orderData.id,
            subStageId: stageData.id
        }

        console.log(stageData);

        getPreventiveMaintenaceSubStage(activeStage, data)
            .then((res) => {
                console.log(res);
                setSubStageData({
                    id: stageData.id,
                    data: res && res.stage_details
                });

                if (res.stage_meta_data) {
                    setSubStageMetaData(res.stage_meta_data);
                }

                setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES);
                setActiveStage(activeStage);
                setActiveSubStage(stageData && stageData.name.toUpperCase());
            }).catch((err) => {
                console.log(err);
            })
    }

    const getObservationIssueOptions = (productId) => {

        // TODO: 
        let data = {
            productId: productId
        }

        getObservationIssues(data)
            .then((res) => {
                console.log(res);
                setObservationIssues(res);
            }).catch((err) => {
                console.log(err);
            })
    }

    const handleStageClick = (type, data) => {

        if (!type) return;

        if (type === PREVENTIVE_MAINTENANCE_CONST.STAGES.CUSTOMER_DETAIL) {
            setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES);
            setActiveStage(BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.VISUAL_INSPECTION) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.CHECKS) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES["VC-CMV"]) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES["PC-AC"]) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES["PRVC-SIMV"]) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES["NIV-APRV"]) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.CHECKS) {
            updateSubStageDetail(data);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.MACHINE_STATUS) {
            setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES);
            setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.MACHINE_STATUS);
            setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.MACHINE_STATUS);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.BILL_MATERIAL) {
            setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES);
            setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.MACHINE_STATUS);
            setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.BILL_MATERIAL);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.FIELD_RETURN) {
            setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES);
            setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.MACHINE_STATUS);
            setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.FIELD_RETURN);

        } else if (type === PREVENTIVE_MAINTENANCE_CONST.STAGES.REMARK) {

            setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES);
            setActiveStage(PREVENTIVE_MAINTENANCE_CONST.STAGES.REMARK);
        }
    }

    const handleHeaderBackClick = () => {

        if (activeState === PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES) {
            setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
        } else {
            redirect(navigate, PAGE_CONST.SERVICE_MANAGEMENT);
        }
    }

    const onBillMaterialSubmit = () => {
        getOrderData();
        setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
        setActiveSubStage(PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.FIELD_RETURN);
    }

    const onFieldReturnSubmit = (data) => {

        if (data) data.orderId = props.orderId;
        addFieldReturn(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            }).catch((err) => {
                console.log(err);
            })
    }

    const onMachineStatusSubmit = (data) => {

        if (data) data.orderId = props.orderId;
        updateMachineStatus(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            }).catch((err) => {
                setErrorMsg(errorMsg)
                console.log(err);
            })
    }



    const getMachineDetailsBySerialName = (serialName) => {

        let data = {
            serialName: serialName
        }

        getMachineDetailsByName(data)
            .then((res) => {
                if (res && res.machine_details) setMachineDetail(res.machine_details);
            }).catch(() => {

            })
    }

    const onBreakDownFormSubmit = (data) => {

        console.log("preventive submit:: " + data);
        createPreventiveMaintenanceOrder(data)
            .then((id) => {
                let data = {
                    id: id
                }

                props.activePreventiveMaintainanceOrder(data);
                setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES);
                getOrderData(id);
            })
            .catch((err) => {
                setErrorMsg(err);
                console.log(err);
            })
    }

    const onBreakDownCancel = () => {
        redirect(navigate, PAGE_CONST.MY_MACHINE);
        console.log("on cancel");
    }

    const handleCustomerDetailsSubmit = (data) => {

        if (!data) return;

        data.orderId = props.orderId;

        addPreventiveMaintenanceCustomerDetail(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            })
            .catch((err) => {
                setErrorMsg(err);
                console.log(err);
            })
    }

    const handleRemarkSubmit = (data) => {

        if (data) data.orderId = props.orderId;

        addRemark(data)
            .then((res) => {
                console.log(res);
                getOrderData();
            }).catch((err) => {
                console.log(err);
            })
    }

    const handleOtpSubmit = (otp) => {

        console.log(otpText);

        let data = {
            flow: PAGE_CONST.PREVENTIVE_MAINTENANCE,
            orderId: props.orderId,
            mobileNo: (orderData && orderData.otp_confirmation && orderData.otp_confirmation.mobile_no)
        }

        if (otpText === LOGIN_CONST.SEND_OTP) {

            sendFlowOtp(data)
                .then((res) => {
                    console.log(res);

                    setOtpText(LOGIN_CONST.VERIFY_OTP);
                }).catch((err) => {
                    console.log(err);
                })
        } else if (otpText === LOGIN_CONST.VERIFY_OTP) {

            data.otp = otp;

            verifyFlowOtp(data)
                .then((res) => {
                    console.log(res);
                    setActiveState(PREVENTIVE_MAINTENANCE_CONST.STATE.SUCCESS_PAGE);
                }).catch((err) => {
                    console.log(err);
                    setErrorMsg(err);
                })
        }
    }

    const getSuccessMsg = () => {
        let message = `Service request for machine ${orderData.machine_serial_name} has been successfully closed!`;

        return message;
    }

    const successHomeClickHandler = () => {
        console.log("success home clicked !!");

        props.activePreventiveMaintainanceOrder({});
        redirect(navigate, PAGE_CONST.MACHINE_GROUP);
    }

    const successShareClickHandler = () => {
        console.log("success share clicked !!");
    }

    const onSubStageSubmitHandler = (stageResponseData) => {
        console.log("in onSubStageSubmitHandler");

        let data = {
            orderId: orderData.id,
            subStageId: subStageData.id,
            stageData: stageResponseData
        }

        updatePreventiveMaintenaceSubStage(activeStage, data)
            .then((res) => {
                console.log(res);
                getOrderData();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getSubStageComponent = () => {
        if (activeState === PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_SUB_STAGES) {

            if (activeStage === PREVENTIVE_MAINTENANCE_CONST.STAGES.CUSTOMER_DETAIL) {
                return <CustomerDetail
                    type={FLOW.PREVENTIVE_MAINTENANCE}
                    handleSubmit={(data) => handleCustomerDetailsSubmit(data)}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)}
                />
            } else if (
                [PREVENTIVE_MAINTENANCE_CONST.STAGES.PREVENTIVE_MAINTENANCE_CHECKLIST,
                PREVENTIVE_MAINTENANCE_CONST.STAGES.MODE_TESTING]
                    .indexOf(activeStage) > -1
            ) {
                return (<InstallMachineSubStage
                    onSubmitHandler={(data) => onSubStageSubmitHandler(data)}
                    onNextHandler={() => console.log("next clicked")}
                    data={subStageData && subStageData.data}
                    activeSubStage={activeSubStage}
                    metaData={subStageMetaData}
                />)
            } else if (activeStage === PREVENTIVE_MAINTENANCE_CONST.STAGES.MACHINE_STATUS) {
                if (activeSubStage === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.MACHINE_STATUS) {
                    return <MachineStatusForm
                        onSubmit={(data) => onMachineStatusSubmit(data)}
                        issueOptions={observationIssues}
                        flow={FLOW.PREVENTIVE_MAINTENANCE}
                        orderId={props.orderId}
                        data={orderData}
                        errorMsg={errorMsg}
                        setEmptyErrMsg={() => setErrorMsg(null)}
                    />
                } else if (activeSubStage === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.BILL_MATERIAL) {
                    return <BillMaterial
                        onSubmit={() => onBillMaterialSubmit()}
                        flow={FLOW.PREVENTIVE_MAINTENANCE}
                        orderId={props.orderId}
                        data={orderData}
                    />
                } else if (activeSubStage === PREVENTIVE_MAINTENANCE_CONST.SUB_STAGES.FIELD_RETURN) {
                    return <FieldReturn
                        onSubmit={(data) => onFieldReturnSubmit(data)}
                        onCancel={() => getOrderData()}
                    />
                }
            } else if (activeStage === PREVENTIVE_MAINTENANCE_CONST.STAGES.REMARK) {
                return <Remark
                    onSubmit={(data) => handleRemarkSubmit(data)}
                    onCancel={() => getOrderData()}
                />
            }
        }
    }

    return (
        <div className="preventive-maintenance-container">
            <Header handleBackClick={() => handleHeaderBackClick()} />
            {activeState === PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_FORM &&
                <BreakDownForm
                    onSubmit={(data) => onBreakDownFormSubmit(data)}
                    onCancel={() => onBreakDownCancel()}
                    machineDetail={machineDetail}
                    flow={FLOW.PREVENTIVE_MAINTENANCE}
                    getMachineDetailsBySerialName={(serialName) => getMachineDetailsBySerialName(serialName)}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)}
                />
            }
            {activeState === PREVENTIVE_MAINTENANCE_CONST.STATE.PREVENTIVE_MAINTENANCE_STAGES &&
                <PreventiveMaintenanceStages
                    activeStage={activeStage}
                    activeSubStage={activeSubStage}
                    data={orderData}
                    handleStageClick={(type, data) => handleStageClick(type, data)}
                    handleOtpSubmit={(otp) => handleOtpSubmit(otp)}
                    otpText={otpText}
                    errorMsg={errorMsg}
                    setEmptyErrMsg={() => setErrorMsg(null)} />}
            {activeState === PREVENTIVE_MAINTENANCE_CONST.STATE.SUCCESS_PAGE &&
                <SuccessPage
                    message={getSuccessMsg()}
                    isCtaVisible={true}
                    homeClickHandler={() => successHomeClickHandler()}
                    shareClickHandler={() => successShareClickHandler()}
                />}
            {getSubStageComponent()}
        </div>
    )
}

const mapStateToProps = state => {

    return {
        orderId: state.activeOrderReducer && state.activeOrderReducer.preventiveMaintainanceOrder
            && state.activeOrderReducer.preventiveMaintainanceOrder.id
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activePreventiveMaintainanceOrder: (payload) => dispatch(activePreventiveMaintainanceOrder(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PreventiveMaintenance);