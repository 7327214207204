import { logoutAPI, sendOtpAPI, verifyOtpAPI } from "../adapter/service-adapter";
import { LOGIN_CONST } from "../common/constant/page-constants";
import { deleteCookieCustom, getCookieCustom } from "../common/utils/StorageUItils";

export const sendOtp = (data) => {
    let reqData = {
        mobile_no: data.phoneNumber
    }

    let promise = new Promise(function (resolve, reject) {
        sendOtpAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.status === 200) {
                resolve(res);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}


export const verifyOtp = (data) => {
    let reqData = {
        mobile_no: data.phoneNumber,
        otp: data.otp
    }

    let promise = new Promise(function (resolve, reject) {
        verifyOtpAPI(reqData).then((res) => {
            // console.log("verify otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const logout = () => {

    let reqData = {
        refresh_token: getCookieCustom(LOGIN_CONST.REFRESH_TOKEN)
    }

    let promise = new Promise(function (resolve, reject) {
        logoutAPI(reqData).then((res) => {
            // console.log("verify otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                deleteCookieCustom(LOGIN_CONST.REFRESH_TOKEN);
                deleteCookieCustom(LOGIN_CONST.ACCESS_TOKEN);
                resolve(res.data.data)
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}