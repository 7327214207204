import { getMachineDetailsBySerialIdAPI, getMachinesAPI, getMachineStatisticsAPI, sellMachinesAPI } from "../adapter/service-adapter";

export const getMyMachines = () => {

    let promise = new Promise(function (resolve, reject) {
        getMachineStatisticsAPI().then((res) => {

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}
export const getMachineGroupAPI = (data) => {

    let reqData = {
        product_id: data.productId
    }

    let promise = new Promise(function (resolve, reject) {
        getMachinesAPI(reqData).then((res) => {

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const sellMachine = (data) => {

    let requestData = {
        machine_serial_ids: data.machineSerialIds,
        customer: {
            type: data.customer.type,
            contact_number: data.customer.contactNumber,
            contact_email: data.customer.contactMail,
            organization_name: data.customer.organizationName,
            contact_person: data.customer.contactPerson,
            address: data.customer.address,
            pincode: data.customer.pincode,
            city: data.customer.city,
            state: data.customer.state
        }
    }

    let promise = new Promise(function (resolve, reject) {
        sellMachinesAPI(requestData).then((res) => {

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const getMachineDetailsBySerialId = (id) => {
    let requestData = {
        machine_serial_id: id,
    }

    let promise = new Promise(function (resolve, reject) {
        getMachineDetailsBySerialIdAPI(requestData).then((res) => {

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

