import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FLOW,
  INSTALL_MACHINE_CONST,
  LOGIN_CONST,
  PAGE_CONST,
  PROGRESS_STATUS,
} from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";
import { Header } from "../../components/Header/Header";

import checkFilledSvg from "../../common/icons/svg/check-filled.svg";
import dangerFilledSvg from "../../common/icons/svg/danger-filled.svg";

import "./InstallMachine.scss";
import { InstallMachineSubStage } from "../../container/InstallMachineSubStage/InstallMachineSubStage";
import { SuccessPage } from "../../components/SuccessPage/SuccessPage";
import {
  addCustomer,
  getOrCreateInstallOrder,
  getSubStageDetails,
  updateSubStageDetails,
} from "../../helper/IntstallHelper";
import { CustomerDetail } from "../../components/CustomerDetail/CustomerDetail";
import { sendFlowOtp, verifyFlowOtp } from "../../helper/CommonHelper";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

function InstallMachine(props) {
  const navigate = useNavigate();

  /* which substage, stage is active */

  const [otpText, setOtpText] = useState(LOGIN_CONST.SEND_OTP);
  const [activeState, setActiveState] = useState(
    INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES
  );
  const [activeStage, setActiveStage] = useState(
    INSTALL_MACHINE_CONST.STAGES.OTP_CONFIRMATION
  );
  const [activeSubStage, setactiveSubStage] = useState(
    INSTALL_MACHINE_CONST.SUB_STAGES.SCOPE_OF_SUPPLY
  );
  const [orderData, setOrderData] = useState(null);
  const [subStageData, setSubStageData] = useState(null);
  const [otp, setOtp] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    if (
      activeState === INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES ||
      !orderData
    ) {
      getOrderData();
    }
  }, [props.activeMachine, activeState]);

  const getOrderData = () => {
    let data = {
      id: props.activeMachine.id,
    };
    getOrCreateInstallOrder(data)
      .then((res) => {
        console.log(res);
        setOrderData(res);
        getNextState(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNextState = (data) => {
    if (!data.customer) {
      //   setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES);
      setActiveStage(INSTALL_MACHINE_CONST.STAGES.CUSTOMER_DETAIL);

      return;
    }

    let installationReport = data.installation_report;
    let inCompleteSubStage = installationReport.filter(
      (item) => !item.status || item.status != PROGRESS_STATUS.COMPLETED
    )[0];

    if (inCompleteSubStage) {
      setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES);
      setActiveStage(INSTALL_MACHINE_CONST.STAGES.INSTALLATION_REPORT);
      setactiveSubStage(inCompleteSubStage.name);
      return;
    }

    let otpStage =
      !data.otp_confirmation || !data.otp_confirmation.is_otp_verified;

    if (otpStage) {
      setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES);
      setActiveStage(INSTALL_MACHINE_CONST.STAGES.OTP_CONFIRMATION);
      return;
    }

    setActiveState(INSTALL_MACHINE_CONST.STATE.SUCCESS_PAGE);
  };

  const updateSubStageDetail = (stageData) => {
    let data = {
      orderId: orderData.id,
      subStageId: stageData.id,
    };

    console.log("inside updateSubStage:", data);

    getSubStageDetails(data)
      .then((res) => {
        console.log("after promise resolve:", res);
        setSubStageData({
          id: stageData.id,
          data: res,
        });
        setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES);
        setActiveStage(INSTALL_MACHINE_CONST.STAGES.INSTALLATION_REPORT);
        setactiveSubStage(stageData && stageData.name.toUpperCase());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleStageClick = (type, data) => {
    if (!type) return;

    // customer details
    if (type.toUpperCase() === INSTALL_MACHINE_CONST.STAGES.CUSTOMER_DETAIL) {
      setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES);
      setActiveStage(INSTALL_MACHINE_CONST.STAGES.CUSTOMER_DETAIL);
    }

    // general check
    else if (
      type.toUpperCase() === INSTALL_MACHINE_CONST.SUB_STAGES.GENERAL_CHECKS
    ) {
      updateSubStageDetail(data);
    }

    // checks
    else if (type.toUpperCase() === INSTALL_MACHINE_CONST.SUB_STAGES.CHECKS) {
      updateSubStageDetail(data);
    }

    // Customer Information
    else if (
      type.toUpperCase() ===
      INSTALL_MACHINE_CONST.SUB_STAGES.CUSTOMER_INFORMATION
    ) {
      updateSubStageDetail(data);
    }
    // scope of supply
    else if (
      type.toUpperCase() === INSTALL_MACHINE_CONST.SUB_STAGES.SCOPE_OF_SUPPLY
    ) {
      updateSubStageDetail(data);
    }

    // PEG
    else if (type.toUpperCase() === INSTALL_MACHINE_CONST.SUB_STAGES.PEG) {
      updateSubStageDetail(data);
      // setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES);
      // setActiveStage(INSTALL_MACHINE_CONST.STAGES.INSTALLATION_REPORT);
      // setactiveSubStage(INSTALL_MACHINE_CONST.SUB_STAGES.PEG);
    }

    // REMARK
    else if (type.toUpperCase() === INSTALL_MACHINE_CONST.SUB_STAGES.REMARKS) {
      updateSubStageDetail(data);
      // setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES);
      // setActiveStage(INSTALL_MACHINE_CONST.STAGES.INSTALLATION_REPORT);
      // setactiveSubStage(INSTALL_MACHINE_CONST.SUB_STAGES.REMARKS);
    }
  };

  const handleHeaderBackClick = () => {
    if (activeState === INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES) {
      setActiveState(INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES);
    } else {
      redirect(navigate, PAGE_CONST.MACHINE_GROUP);
    }
  };

  const successHomeClickHandler = () => {
    console.log("home clicked");
    redirect(navigate, PAGE_CONST.MY_MACHINE);
  };

  const successShareClickHandler = () => {
    console.log("share clicked");
  };

  const getSuccessMsg = () => {
    let message = `Machine ${props.activeMachine.serial_name} has been successfully Installed`;

    return message;
  };

  const onSubStageSubmitHandler = (stageResponseData) => {
    console.log("on submit handler");

    let data = {
      orderId: orderData.id,
      subStageId: subStageData.id,
      stageData: stageResponseData,
    };

    updateSubStageDetails(data)
      .then((res) => {
        console.log(res);
        getOrderData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onNextHandler = () => {
    console.log("on next handler");
  };

  const handleCustomerDetailsSubmit = (data) => {
    if (!data) return;

    data.orderId = orderData && orderData.id;
    addCustomer(data)
      .then((res) => {
        console.log(res);
        getOrderData();
      })
      .catch((err) => {
        setErrorMsg(err);
        console.log(err);
      });
  };

  const getSubStageComponent = () => {
    console.log("AcitveStage :", activeStage);
    if (activeState === INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES) {
      if (activeStage === INSTALL_MACHINE_CONST.STAGES.CUSTOMER_DETAIL) {
        return (
          <CustomerDetail
            type={FLOW.INSTALL_ORDER}
            handleSubmit={(data) => handleCustomerDetailsSubmit(data)}
            errorMsg={errorMsg}
            setEmptyErrMsg={() => setErrorMsg(null)}
          />
        );
      } else if (
        activeStage === INSTALL_MACHINE_CONST.STAGES.INSTALLATION_REPORT
      ) {
        console.log("Inside getSubStageComponent Installation Report");
        if (
          [
            INSTALL_MACHINE_CONST.SUB_STAGES.GENERAL_CHECKS,
            INSTALL_MACHINE_CONST.SUB_STAGES.CHECKS,
            INSTALL_MACHINE_CONST.SUB_STAGES.CUSTOMER_INFORMATION,
            INSTALL_MACHINE_CONST.SUB_STAGES.SCOPE_OF_SUPPLY,
            INSTALL_MACHINE_CONST.SUB_STAGES.PEG,
            INSTALL_MACHINE_CONST.SUB_STAGES.REMARKS,
          ].indexOf(activeSubStage) > -1
        ) {
          console.log(
            "Inside getSubStageComponent Installation Report when >-1"
          );
          return (
            <InstallMachineSubStage
              onSubmitHandler={(data) => onSubStageSubmitHandler(data)}
              onNextHandler={() => onNextHandler()}
              data={subStageData && subStageData.data}
              activeSubStage={activeSubStage}
            />
          );
        }
      }
    }
  };

  const getInstallationSubStage = () => {
    let html;
    if (
      orderData &&
      orderData.installation_report &&
      orderData.installation_report.length > 0
    ) {
      html = orderData.installation_report.map((item) => {
        return (
          <div
            className={`sub-stage ${item.status}`}
            onClick={() => {
              handleStageClick(item.name, item);
            }}
            key={item.id}
          >
            <span className="sub-stage-name">{item.name}</span>
            {item.status === PROGRESS_STATUS.COMPLETED && (
              <div className="image-container">
                <img className="check-filled svg" src={checkFilledSvg} />
              </div>
            )}
          </div>
        );
      });
    }

    return html;
  };

  const handleOTPSubmit = () => {
    let data = {
      orderId: orderData.id,
      flow: FLOW.INSTALLATION,
      mobileNo:
        orderData.customer &&
        (orderData.customer.customer_representative_number ||
          orderData.customer.contact_number),
    };
    if (otpText === LOGIN_CONST.SEND_OTP) {
      sendFlowOtp(data)
        .then((res) => {
          console.log(res);
          setOtpText(LOGIN_CONST.VERIFY_OTP);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (otpText === LOGIN_CONST.VERIFY_OTP) {
      data.otp = otp;
      verifyFlowOtp(data)
        .then((res) => {
          console.log(res);
          setActiveState(INSTALL_MACHINE_CONST.STATE.SUCCESS_PAGE);
        })
        .catch((err) => {
          console.log(err);
          setErrorMsg(err);
        });
    }
  };

  console.log("orderData ", orderData);
  return (
    <>
      <div className="install-machine-container">
        <Header handleBackClick={() => handleHeaderBackClick()} />
        {activeState === INSTALL_MACHINE_CONST.STATE.INSTALL_STAGES && (
          <>
            <div className="machine-name">
              {props.activeMachine.serial_name}
            </div>
            <div className="install-machine-content-container">
              <div className="stage-details">
                <div
                  onClick={() =>
                    handleStageClick(
                      INSTALL_MACHINE_CONST.STAGES.CUSTOMER_DETAIL
                    )
                  }
                  className={
                    activeStage === INSTALL_MACHINE_CONST.STAGES.CUSTOMER_DETAIL
                      ? "customer-detail-container stage"
                      : "customer-detail-container stage stage-blur"
                  }
                >
                  <div className="heading">Customer Details</div>
                  {!(orderData && orderData.customer) && (
                    <div className="heading-content">
                      Click here to enter the end customer details
                    </div>
                  )}
                  {orderData && orderData.customer && (
                    <div className="customer-details">
                      <div className="hospital-details">
                        {`${orderData.customer.organization_name}, ${orderData.customer.city}, ${orderData.customer.state}`}
                      </div>
                      <div className="poc-details">
                        {`${orderData.customer.contact_person}, ${orderData.customer.contact_number}`}
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    activeStage ===
                    INSTALL_MACHINE_CONST.STAGES.INSTALLATION_REPORT
                      ? "installation-report stage"
                      : "installation-report stage stage-blur"
                  }
                >
                  <div className="heading">Installation Report</div>
                  <div className="sub-stage-container">
                    {getInstallationSubStage()}
                  </div>
                </div>
                <div
                  className={
                    activeStage ===
                    INSTALL_MACHINE_CONST.STAGES.OTP_CONFIRMATION
                      ? "otp-confirmation-container stage"
                      : "otp-confirmation-container stage stage-blur"
                  }
                >
                  <div className="heading">OTP Confirmation</div>
                  <div className="heading-content">
                    {`The OTP will be sent to customer’s Mobile Number: 
                                    ${
                                      (orderData &&
                                        orderData.customer &&
                                        (orderData.customer
                                          .customer_representative_number ||
                                          orderData.customer.contact_number)) ||
                                      ""
                                    }`}
                  </div>
                  {otpText === LOGIN_CONST.VERIFY_OTP && (
                    <div className="otp-container">
                      <input
                        className="otp input"
                        value={otp}
                        onChange={(event) => setOtp(event.target.value)}
                        type="number"
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                      />
                    </div>
                  )}
                  {errorMsg && <div className="error-msg">{errorMsg}</div>}
                  <div
                    className="button-container"
                    onClick={() => handleOTPSubmit()}
                  >
                    {otpText}
                  </div>
                </div>
              </div>
              {/* {activeState === INSTALL_MACHINE_CONST.STATE.INSTALL_SUB_STAGES &&
                                <InstallMachineSubStage
                                    onSubmitHandler={() => onSubmitHandler()}
                                    onNextHandler={() => onNextHandler()}
                                />} */}
            </div>
          </>
        )}
        {activeState === INSTALL_MACHINE_CONST.STATE.SUCCESS_PAGE && (
          <SuccessPage
            message={getSuccessMsg()}
            isCtaVisible={true}
            homeClickHandler={() => successHomeClickHandler()}
            shareClickHandler={() => successShareClickHandler()}
          />
        )}
        {getSubStageComponent()}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    activeMachine: state.activeDataReducer.activeMachine || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // machineGroupAction: (payload) => dispatch(machineGroupAction(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InstallMachine);
