import {
    addBillOfMaterialAPI,
    addBreakDownCustomerDetailAPI,
    addBreakdownOrderBillOfMaterialAPI,
    addFieldReturnAPI,
    addRemarkAPI,
    createBreakdownOrderAPI,
    createObservationsAPI,
    getBillOfMaterialsAPI,
    getBreakdownOrderAPI,
    getBreakdownOrderBillOfMaterialsAPI,
    getObservationIssuesAPI,
    uploadFileAPI
} from "../adapter/service-adapter";

export const createBreakdownOrder = (data) => {

    let reqData = {
        machine_serial_name: data.machineSerialNumber,
        product_id: data.productId,
        contact_person_details: {
            name: data.contactName,
            number: data.contactNumber,
            email: data.email
        },
        issue_reported: data.issueReported
    }

    let promise = new Promise(function (resolve, reject) {
        createBreakdownOrderAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200 && res.data.data.id) {
                resolve(res.data.data.id);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const getBreakdownOrder = (data) => {

    let reqData = {
        breakdown_order_id: data.id
    }

    let promise = new Promise(function (resolve, reject) {
        getBreakdownOrderAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const addBreakDownCustomerDetail = (data) => {

    let reqData = {
        breakdown_order_id: data.orderId,
        customer_details: {
            warranty_status: data.customer.warrantyType,
            start_date: data.customer.startDate,
            end_date: data.customer.endDate,
            organization_name: data.customer.organizationName,
            address: data.customer.address,
            pincode: data.customer.pincode,
            city: data.customer.city,
            state: data.customer.state
        }
    }

    let promise = new Promise(function (resolve, reject) {
        addBreakDownCustomerDetailAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const getObservationIssues = (data) => {

    let reqData = {
        product_id: data.productId
    }

    let promise = new Promise(function (resolve, reject) {
        getObservationIssuesAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const uploadFile = (data) => {
    let formData = new FormData();

    let file = data.pictures && data.pictures[0];
    formData.append("file", file);

    let promise = new Promise(function (resolve, reject) {
        uploadFileAPI(formData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data.url);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const createObservations = (data) => {

    let reqData = {
        breakdown_order_id: data.orderId,
        ui_version: data.uiVersion,
        firmware_version: data.firmwareVersion,
        is_physically_damaged: data.physicalDamage,
        issue_ids: data.issueObserved,
        issue_details: data.otherIssue,
        machine_image_url: data.url
    }

    let promise = new Promise(function (resolve, reject) {
        createObservationsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export function addBreakDownBillOfMaterial(data) {
    let reqData = {
        "breakdown_order_id": data.orderId,
        "bill_type": data.billType,
        "spare_part_action": data.spareType,
        "spare_part_component_type_id": data.sparePartName,
        "job_type": data.jobType,
        "new_serial_part_name": data.newPartSerialNumber,
        "part_mrp": data.partMrp,
        "discount": data.discount,
        "labour_type": data.labourType,
        "labour_charges": data.labourCharges
    }

    let promise = new Promise(function (resolve, reject) {
        addBreakdownOrderBillOfMaterialAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export function getBreakDownBillOfMaterials(data) {
    let reqData = {
        breakdown_order_id: data.orderId
    }

    let promise = new Promise(function (resolve, reject) {
        getBreakdownOrderBillOfMaterialsAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const addFieldReturn = (data) => {


    let reqData = {
        "breakdown_order_id": data.orderId,
        "is_pickup_requested": data.requestPickup,
        "remark": data.remark,
        "organization_address": data.address,
        "pincode": data.pincode,
        "city": data.city,
        "state": data.state
    }

    let promise = new Promise(function (resolve, reject) {
        addFieldReturnAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const addRemark = (data) => {

    let reqData = {
        "breakdown_order_id": data.orderId,
        "is_training_given": data.trainingGiven,
        "number_of_people_trained": data.numberOfPerson,
        "solution_provided": data.solutionProvided,
        "closing_remarks": data.closingRemark
    }

    let promise = new Promise(function (resolve, reject) {
        addRemarkAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}
