import React from "react";
import successSvg from "../../common/icons/svg/success-icon.svg";

import "./SuccessPage.scss";

export function SuccessPage(props) {

    return (
        <div className="success-page-container">
            <div className="success-data">
                <div className="icon-container">
                    <img className="success-icon" src={successSvg} alt="success-icon" />
                </div>
                <div className="success-msg">
                    {props.message}
                </div>
            </div>
            {props.isCtaVisible && <div className="cta-container">
                <div className="home-cta cta" onClick={() => props.homeClickHandler()}>
                    Home
                </div>
                <div className="share-report-cta cta" onClick={() => props.shareClickHandler()}>
                    Share Report
                </div>
            </div>}
        </div>
    );
}