import { Types } from "./actionTypes";
import axios from "axios";
import { LOGIN_SUCCESS, LOGIN_FAIL } from "./actionTypes";

export const myMachinesAction = (task) => {

    return {
        type: Types.SAVE_MY_MACHINES,
        payload: task
    }
}

export const machineGroupAction = (task) => {

    return {
        type: Types.SAVE_MACHINE_GROUP,
        payload: task
    }
}

export const activeGroupAction = (task) => {

    return {
        type: Types.ACTIVE_GROUP,
        payload: task
    }
}

export const activeMachineAction = (task) => {

    return {
        type: Types.ACTIVE_MACHINE,
        payload: task
    }
}

export const activeBreakDownOrder = (task) => {

    return {
        type: Types.BREAKDOWN_ORDER,
        payload: task
    }
}

export const activePreventiveMaintainanceOrder = (task) => {

    return {
        type: Types.PREVENTIVE_MAINTAINNCE_ORDER,
        payload: task
    }
}

export const activeComplainOrder = (task) => {

    return {
        type: Types.COMPLAINT_FLOW,
        payload: task
    }
}

export const userDataAction = (task) => {

    return {
        type: Types.USER_DATA,
        payload: task
    }
}