import React, { useEffect, useState } from "react";
import { BREAKDOWN_CONST, LOGIN_CONST } from "../../common/constant/page-constants";

import infoSvg from "../../common/icons/svg/info.svg";
import checkFilledSvg from "../../common/icons/svg/check-filled.svg";
import dangerFilledSvg from "../../common/icons/svg/danger-filled.svg";

import "./BreakDownCallStages.scss";

export function BreakDownCallStages(props) {

    const [activeStage, setActiveStage] = useState(props.activeStage);
    const [otpText, setOtpText] = useState(props.otpText);
    const [otp, setOtp] = useState("");
    const [machineInfoActive, setMachineInfoActive] = useState(false);

    useEffect(() => {

        setOtpText(props.otpText);
    }, [props.otpText]);

    const getSubStageStatus = (type) => {


        let machineStatus = props.data && props.data.machine_status;

        if (!machineStatus) return BREAKDOWN_CONST.STATUS.NOT_STARTED;

        if (type === BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL) {
            return machineStatus.bill_of_materials.status;
        } else if (type === BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN) {
            return machineStatus.field_return.status;
        }
    }

    const handleOtpSubmitClick = () => {

        props.handleOtpSubmit(otp);
    }

    const getOTPHeadingContent = () => {

        if (otpText === LOGIN_CONST.SEND_OTP) {
            return (`The OTP will be sent to customer’s Mobile Number: 
                        ${props.data && props.data.otp_confirmation && props.data.otp_confirmation.mobile_no}`)
        } else if (otpText === LOGIN_CONST.VERIFY_OTP) {
            return (`The OTP has been sent to customer’s Mobile Number:
                        ${props.data && props.data.otp_confirmation && props.data.otp_confirmation.mobile_no}`)
        }
    }

    const handleInfoClick = () => {
        setMachineInfoActive(!machineInfoActive);
    }


    return (
        <div className="breakdown-stages-container">
            <div className="breakdown-stages-heading">
                <div className="heading-text">{props.data && props.data.machine_serial_name}</div>
                <div className="info-container">
                    <img src={infoSvg} className="info svg" onClick={handleInfoClick} />
                    {machineInfoActive &&
                        <div className="machine-info-container">
                            <div className="common-info">
                                <span className="info-heading">Warranty Status</span>
                                <span>In warranty</span>
                            </div>
                            <div className="common-info">
                                <span className="info-heading">Channel Partner</span>
                                <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className="breakdown-issue-details">
                <div className="issue-heading">Issue Reported</div>
                <div className="issue-body">
                    {props.data && props.data.issue_reported}
                </div>
            </div>
            <div className="breakdown-stage-details">
                <div onClick={() => props.handleStageClick(BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL)}
                    className={props.activeStage === BREAKDOWN_CONST.STAGES.CUSTOMER_DETAIL ?
                        "customer-detail-container stage" : "customer-detail-container stage stage-blur"}>
                    <div className="heading">
                        Customer Details
                    </div>
                    {!(props.data && props.data.customer_details) && <div className="heading-content">
                        Click here to enter the end customer details
                    </div>}
                    {(props.data && props.data.customer_details) && <div className="customer-details">
                        <div className="hospital-details">
                            {`${props.data.customer_details.organization_name}, 
                            ${props.data.customer_details.city},
                            ${props.data.customer_details.state}`}
                        </div>
                        <div className="poc-details">
                            {`${props.data.customer_details.contact_person}: ${props.data.customer_details.contact_number}`}
                        </div>
                    </div>}
                </div>
                <div
                    onClick={() => props.handleStageClick(BREAKDOWN_CONST.STAGES.OBSERVATION)}
                    className={props.activeStage === BREAKDOWN_CONST.STAGES.OBSERVATION ?
                        "observation-container stage" : "observation-container stage stage-blur"}>
                    <div className="heading">
                        Observation
                    </div>
                </div>
                <div
                    className={props.activeStage === BREAKDOWN_CONST.STAGES.MACHINE_STATUS ?
                        "machine-status stage" : "machine-status stage stage-blur"}>
                    <div className="heading">
                        Machine Status
                    </div>
                    <div className="sub-stage-container">
                        <div
                            className={`sub-stage ${getSubStageStatus(BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL)}`}
                            onClick={() => props.handleStageClick(BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL)}
                        >
                            <span className="sub-stage-name">Bill of Materials</span>
                            {getSubStageStatus(BREAKDOWN_CONST.SUB_STAGES.BILL_MATERIAL) === BREAKDOWN_CONST.STATUS.COMPLETED
                                && <div className="image-container">
                                    <img className="check-filled svg" src={checkFilledSvg} />
                                </div>}
                        </div>
                        <div
                            className={`sub-stage ${getSubStageStatus(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN)}`}
                            onClick={() => props.handleStageClick(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN)}
                        >
                            <span className="sub-stage-name">Field Return</span>
                            {getSubStageStatus(BREAKDOWN_CONST.SUB_STAGES.FIELD_RETURN) === BREAKDOWN_CONST.STATUS.COMPLETED
                                && <div className="image-container">
                                    <img className="danger-filled svg" src={checkFilledSvg} />
                                </div>}
                        </div>
                    </div>
                </div>
                <div
                    onClick={() => props.handleStageClick(BREAKDOWN_CONST.STAGES.REMARK)}
                    className={props.activeStage === BREAKDOWN_CONST.STAGES.REMARK ?
                        "remark-container stage" : "remak-container stage stage-blur"}>
                    <div className="heading">
                        Remark
                    </div>
                </div>
                <div
                    className={props.activeStage === BREAKDOWN_CONST.STAGES.OTP_CONFIRMATION ?
                        "otp-confirmation-container stage" : "otp-confirmation-container stage stage-blur"}>
                    <div className="heading">
                        OTP Confirmation
                    </div>
                    <div className="heading-content">
                        {`The OTP will be sent to customer’s Mobile Number: 
                        ${props.data && props.data.otp_confirmation && props.data.otp_confirmation.mobile_no}`}
                    </div>
                    {otpText === LOGIN_CONST.VERIFY_OTP && <div className="otp-container">
                        <input
                            className="otp input"
                            value={otp}
                            onChange={(event) => { props.setEmptyErrMsg(null); setOtp(event.target && event.target.value) }} />
                    </div>}
                    {props.errorMsg && <div className="error-msg">{props.errorMsg}</div>}
                    <div className="button-container" onClick={() => handleOtpSubmitClick()}>
                        {otpText}
                    </div>
                </div>
            </div>

        </div >
    )
}