import moment from "moment";
import React, { useState } from "react";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

import "./FieldReturn.scss";

export function FieldReturn(props) {

    const yes = "yes";
    const no = "no";

    const [requestPickup, setRequestPickup] = useState(false);
    const [remark, setRemark] = useState("");
    const [address, setAddress] = useState("");
    const [pincode, setPincode] = useState(null);
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [isPopUpVisible, setIsPopUpVisible] = useState(false)

    const handleSubmit = () => {

        if (!isPopUpVisible) {
            setIsPopUpVisible(true);
            return;
        };

        let data = {
            requestPickup: requestPickup,
            remark: remark,
            address: address,
            pincode: pincode,
            city: city,
            state: state
        }

        props.onSubmit(data);
    }

    return (
        <>
            {<div className={isPopUpVisible ? "field-return-container blur" : "field-return-container"}>
                <div className="field-return-heading">
                    Field Return
                </div>
                <div className="field-return-form">
                    <div className="request-pickup-container">
                        <div className="request-pickup-text">
                            Request pickup
                        </div>
                        <div className="request-pickup-checkbox">
                            <div className="yes-request-container radio-button">
                                <input
                                    className="radio"
                                    type="radio"
                                    name="request-pickup"
                                    checked={requestPickup}
                                    onChange={() => setRequestPickup(true)} />
                                <label className="label">Yes</label>
                            </div>
                            <div className="yes-request-container radio-button">
                                <input
                                    className="radio"
                                    type="radio"
                                    name="request-pickup"
                                    checked={!requestPickup}
                                    onChange={() => setRequestPickup(false)} />
                                <label className="label">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="remark-container">
                        <textarea
                            className="remark textarea"
                            rows="4"
                            placeholder="Remark"
                            onChange={(event) => setRemark(event.target.value)}
                        />
                    </div>
                    {
                        requestPickup &&
                        <div className="organization-details">
                            <div className="field-container">
                                <div className="label">Organization Address</div>
                                <textarea
                                    className="address"
                                    placeholder="Organization Address"
                                    rows="4"
                                    onChange={(event) => setAddress(event.target.value)}
                                />
                            </div>
                            <div className="field-container pincode">
                                <div className="label">Pincode</div>
                                <input
                                    className="input"
                                    id="pincode"
                                    type="number"
                                    placeholder="Enter Pincode"
                                    onChange={(event) => setPincode(event.target.value)}
                                    minLength={6}
                                    onFocus={(event) => handleOnFocusById(event.target.id)}
                                />
                            </div>
                            <div className="field-container city">
                                <div className="label">City</div>
                                <input
                                    className="input"
                                    id="city"
                                    type="text"
                                    placeholder="Enter City"
                                    onChange={(event) => setCity(event.target.value)}
                                    onFocus={(event) => handleOnFocusById(event.target.id)}
                                />
                            </div>
                            <div className="field-container state">
                                <div className="label">State</div>
                                <input
                                    className="input"
                                    type="text"
                                    id="state"
                                    placeholder="Enter State"
                                    onChange={(event) => setState(event.target.value)}
                                    onFocus={(event) => handleOnFocusById(event.target.id)}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className="cta-container">
                    <div className="submit-container" onClick={() => props.onCancel()}>
                        Cancel
                    </div>
                    <div className="add-container" onClick={() => handleSubmit()}>
                        Submit
                    </div>
                </div>
            </div>}
            {isPopUpVisible &&
                <div className="confirmation-modal-container">
                    <div className="confirmation-modal">
                        <div className="modal-heading">
                            Field Return Request
                        </div>
                        <div className="modal-body">
                            <div className="modal-date">
                                Date: {moment(new Date()).format("DD-MM-YYYY")}
                            </div>
                            <div className="modal-content">
                                Are you sure you want to request pickup for this machine?
                            </div>
                        </div>
                        <div className="modal-cta-container">
                            <div className="cancel-container cta" onClick={() => setIsPopUpVisible(false)}>
                                Cancel
                            </div>
                            <div className="confirm-container cta" onClick={() => handleSubmit()}>
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )


}