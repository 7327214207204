import {
  UpdateSellerExperienceTerritoryAPI,
  fetchSellerExperienceTerritoryAPI,
} from "../adapter/service-adapter";

export const UpdateSellerExperienceTerritory = (data) => {
  let promise = new Promise(function (resolve, reject) {
    UpdateSellerExperienceTerritoryAPI(data).then((res) => {
      console.log("UPDATESeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};

export const fetchSellerExperienceTerritory = (data) => {
  let promise = new Promise(function (resolve, reject) {
    fetchSellerExperienceTerritoryAPI(data).then((res) => {
      console.log("fetchSeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};