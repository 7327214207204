import inStockSvg from "../../common/icons/svg/in-stock.svg";
import pendingSvg from "../../common/icons/svg/pending.svg";
import installedSvg from "../../common/icons/svg/installed.svg";

import "./MachineGroupCard.scss";
export function MachineGroupCard(props) {

    return (
        <div className="machine-group-card-container" onClick={() => props.onClick(props.data)}>
            <div className="machine-name">
                {
                    props.data.product_name
                }
            </div>
            <div className="machine-type">
                {
                    props.data.product_type
                }
            </div>
            <div className="machine-details-container">
                <div className="machine-details in-stock">
                    <div className="machine-detail machine-type">
                        <img src={inStockSvg} className="machine-icon in-stock-icon" alt="in-stock icon" />
                        <div className="type-text">In Stock</div>
                    </div>
                    <div className="machine-detail machine-number">
                        {props.data.in_stock_count}
                    </div>
                </div>
                <div className="machine-details installed">
                    <div className="machine-detail machine-type">
                        <img src={installedSvg} className="machine-icon pending-icon " alt="pending icon" />
                        <div className="type-text">Pending</div>
                    </div>
                    <div className="machine-detail machine-number">
                        {props.data.installation_pending_count}
                    </div>
                </div>
                <div className="machine-details pending">
                    <div className="machine-detail machine-type">
                        <img src={pendingSvg} className="machine-icon installed-icon" alt="installed icon" />
                        <div className="type-text">Installed</div>
                    </div>
                    <div className="machine-detail machine-number">
                        {props.data.installed_count}
                    </div>
                </div>
            </div>
        </div>
    )

}