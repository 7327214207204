import React, { useState } from "react";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

import "./Remark.scss";

export function Remark(props) {

    const yes = "yes";
    const no = "no";

    const [trainingGiven, setTrainingGiven] = useState(false);
    const [solutionProvided, setSolutionProvided] = useState("");
    const [closingRemark, setClosingRemark] = useState("");
    const [numberOfPerson, setNumberOfPerson] = useState("");

    const handleSubmit = () => {
        console.log("handle submit clicked !!!");

        let data = {
            trainingGiven: trainingGiven,
            numberOfPerson: numberOfPerson,
            solutionProvided: solutionProvided,
            closingRemark: closingRemark
        }

        props.onSubmit(data);
    }

    const onCancel = () => {
        console.log("handle cancel clicked !!!");

        props.onCancel()
    }

    return (
        <div className="remark-container">
            <div className="remark-heading">
                {/* PNQ3546787654 */}
            </div>
            <div className="remark-form">
                <div className="remark-training-container">
                    <div className="remark-training-text">
                        Training given to Biomedical/ICU staff/Doctors
                    </div>
                    <div className="remark-training-checkbox">
                        <div className="yes-request-container radio-button">
                            <input
                                className="radio"
                                type="radio"
                                name="request-pickup"
                                checked={trainingGiven}
                                onChange={() => setTrainingGiven(true)} />
                            <label className="label">Yes</label>
                        </div>
                        <div className="no-request-container radio-button">
                            <input
                                className="radio"
                                type="radio"
                                name="request-pickup"
                                checked={!trainingGiven}
                                onChange={() => setTrainingGiven(false)} />
                            <label className="label">No</label>
                        </div>
                    </div>
                </div>
                <div className="person-trained-container item">
                    <input
                        className="number-of-person input"
                        value={numberOfPerson}
                        placeholder="Number of Person Trainied"
                        id="trained-person"
                        type="number"
                        onChange={(event) => setNumberOfPerson(event.target.value)}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                </div>
                <div className="solution-provided-container item">
                    <textarea
                        className="solution-provided textarea"
                        rows="4"
                        id="solution-provided"
                        placeholder="Solution Provided"
                        onChange={(event) => setSolutionProvided(event.target.value)}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    // onKeyPress={(event) => {
                    //     if (event && (event.key === 'Enter' && !event.shiftKey)) {
                    //         console.log(event.target.value);
                    //         setSolutionProvided(event.target.value);
                    //     }
                    // }}
                    />
                </div>
                <div className="closing-remark-container item">
                    <textarea
                        className="closing-remark textarea"
                        rows="4"
                        placeholder="Closing Remark"
                        onChange={(event) => setClosingRemark(event.target.value)}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    // onKeyPress={(event) => {
                    //     if (event && (event.key === 'Enter' && !event.shiftKey)) {
                    //         console.log(event.target.value);
                    //         setClosingRemark(event.target.value);
                    //     }
                    // }}
                    />
                </div>
            </div>
            <div className="cta-container">
                <div className="cancel-container" onClick={() => onCancel()}>
                    Cancel
                </div>
                <div className="submit-container" onClick={() => handleSubmit()}>
                    Submit
                </div>
            </div>
        </div>
    )
}