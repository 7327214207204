import "./PreventiveMaintenanceCheckForm.scss";
import { Header } from "../Header/Header";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

function PreventiveMaintenanceCheckForm() {
    const handleSubmitBtn = () => {
        console.log("submit button was clicked!!!");
    }

    const headerConf = {
        heading: "Service Management"
    }

    const handleCancelBtn = () => {
        console.log("cancel btn was clicked");
    }

    return (
        <div className="preventive-check-form-container">
            <Header headerConf={headerConf} />
            <div className="machine-name">PNQ3546787654</div>
            <div className="form-container">
                <form className="form-detail">
                    <div className="software-check check-method">
                        <label>Software Checks</label>
                        <div className="common-text-input">
                            <label htmlFor="ui-version">UI Version</label>
                            <input type="text" placeholder="UI Version" id="ui-version"
                                onFocus={(event) => handleOnFocusById(event.target.id)}
                            />
                        </div>
                        <div className="common-text-input">
                            <label htmlFor="firmware-version">Firmware Version</label>
                            <input type="text" placeholder="Firmware Version" id="firmware-version"
                                onFocus={(event) => handleOnFocusById(event.target.id)}
                            />
                        </div>
                    </div>
                    <div className="usb-check check-method">
                        <label>USB Checks</label>
                        <div className="common-text-input">
                            <label htmlFor="charge-before">Charge % Before Disconnection</label>
                            <input type="number" placeholder="Charge % Before Disconnection" id="charge-before"
                                onFocus={(event) => handleOnFocusById(event.target.id)}
                            />
                        </div>
                        <div className="common-text-input">
                            <label htmlFor="charge-after">Charge % after 10 min of disconnection</label>
                            <input type="number" placeholder="Charge % after 10 min of disconnection" id="charge-after"
                                onFocus={(event) => handleOnFocusById(event.target.id)}
                            />
                        </div>
                    </div>
                    <div className="electrical-check check-method">
                        <label>Electrical Checks</label>
                        <div className="common-text-input">
                            <label htmlFor="smps-volt-ac">SMPS Voltage with AC supply</label>
                            <input type="number" placeholder="SMPS Voltage with AC supply" id="smps-volt-ac"
                                onFocus={(event) => handleOnFocusById(event.target.id)} />
                        </div>
                        <div className="common-text-input">
                            <label htmlFor="battery-volt-ac">Battery Voltage with AC supply</label>
                            <input type="number" placeholder="Battery Voltage with AC supply" id="battery-volt-ac"
                                onFocus={(event) => handleOnFocusById(event.target.id)} />
                        </div>
                        <div className="common-text-input">
                            <label htmlFor="battery-volt">Battery Voltage without AC supply</label>
                            <input type="number" placeholder="Battery Voltage without AC supply" id="battery-volt"
                                onFocus={(event) => handleOnFocusById(event.target.id)} />
                        </div>
                        <div className="common-text-input">
                            <label htmlFor="battery-volt-ten-min">Battery Voltage 10 min after connecting AC Supply</label>
                            <input type="number" placeholder="Battery Voltage 10 min after connecting AC Supply" id="battery-volt-ten-min"
                                onFocus={(event) => handleOnFocusById(event.target.id)} />
                        </div>
                    </div>
                    <div className="common-check-container">
                        <label>Pre Use Check</label>
                        <div className="common-check">
                            <div>
                                <input type="checkbox" id="pre-use-yes" name="yes-no" />
                                <label htmlFor="pre-use-yes">Yes</label>
                            </div>
                            <div>
                                <input type="checkbox" id="pre-use-no" name="yes-no" />
                                <label htmlFor="pre-use-no">No</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-check-container">
                        <label>SMPS Voltage Settings (27.3V)</label>
                        <div className="common-check">
                            <div>
                                <input type="checkbox" id="pre-use-yes" name="yes-no" />
                                <label htmlFor="pre-use-yes">OK</label>
                            </div>
                            <div>
                                <input type="checkbox" id="pre-use-no" name="yes-no" />
                                <label htmlFor="pre-use-no">NOK</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-check-container">
                        <label>All Connectors</label>
                        <div className="common-check">
                            <div>
                                <input type="checkbox" id="pre-use-yes" name="yes-no" />
                                <label htmlFor="pre-use-yes">OK</label>
                            </div>
                            <div>
                                <input type="checkbox" id="pre-use-no" name="yes-no" />
                                <label htmlFor="pre-use-no">NOK</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-check-container">
                        <label>PCB and Driver Board</label>
                        <div className="common-check">
                            <div>
                                <input type="checkbox" id="pre-use-yes" name="yes-no" />
                                <label htmlFor="pre-use-yes">OK</label>
                            </div>
                            <div>
                                <input type="checkbox" id="pre-use-no" name="yes-no" />
                                <label htmlFor="pre-use-no">NOK</label>
                            </div>
                        </div>
                    </div>
                    <div className="common-check-container">
                        <label>Cooling Fan Cleaning</label>
                        <div className="common-check">
                            <div>
                                <input type="checkbox" id="pre-use-yes" name="yes-no" />
                                <label htmlFor="pre-use-yes">OK</label>
                            </div>
                            <div>
                                <input type="checkbox" id="pre-use-no" name="yes-no" />
                                <label htmlFor="pre-use-no">NOK</label>
                            </div>
                        </div>
                    </div>


                </form>
                <div className="common-btn-container">
                    <div className="cancel-btn" onClick={handleCancelBtn}>Cancel</div>
                    <div className="submit-btn" onClick={handleSubmitBtn}>Submit</div>
                </div>
            </div >
        </div >
    )
}
export default PreventiveMaintenanceCheckForm;