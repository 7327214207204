import React, { useState, useRef, useEffect } from "react";
import "./KYC.scss";
import { kyc } from "../../helper/KycHelper.js";
import {
  UpdateKYC,
  RetrieveKYC,
} from "../../helper/UpdateandRetrieveKYCHelper.js";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { getCookieCustom } from "../../common/utils/StorageUItils";
import { LOGIN_CONST } from "../../common/constant/page-constants";
import { userStatus } from "../../helper/user-helper.js";

function KYC(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [filePreviews, setFilePreviews] = useState({});
  const [errors, setErrors] = useState({});
  const alert = useAlert();
  const fileInputRefs = useRef({});
  const [userStatusState, setUserStatusState] = useState("");

  const handleDivClick = (inputName) => {
    fileInputRefs.current[inputName].click();
  };

  const [documents, setDocuments] = useState({
    mobile_no: props.userData.mobile_no,
    team: props.userData.team_id,
    firms_pan_card_number: "",
    gst_number: "",
    owners_aadhar_card_number: "",
    owners_pan: "",
  });

  useEffect(() => {
    if (props.userData.mobile_no) {
      setIsLoggedIn(true);
    }
  }, []);

  const fetchKYCDetails = async () => {
    try {
      const data = await RetrieveKYC({
        mobile_no: props.userData.mobile_no,
      });
      if (data) {
        setDocuments({
          mobile_no: props.userData.mobile_no,
          team: props.userData.team_id,
          firms_pan_card_number: data.firms_pan_card_number,
          gst_number: data.gst_number,
          owners_aadhar_card_number: data.owners_aadhar_card_number,
          owners_pan: data.owners_pan,
        });

        setFilePreviews({
          firms_pan_card: data.firms_pan_card,
          gst_certificate: data.gst_certificate,
          incorporation_certificate: data.incorporation_certificate,
          msme_certificate: data.msme_certificate,
          audit_financials: data.audit_financials,
          owners_aadhar_card: data.owners_aadhar_card,
          owners_pan_card: data.owners_pan_card,
        });
      }
    } catch (error) {
      console.error("Error retrieving KYC details:", error);
      alert.error("Error retrieving KYC details");
    }
  };

  const fetchUserStatus = async () => {
    try {
      const res = await userStatus({
        mobile_no: props.userData.mobile_no,
      });
      if (res) {
        console.log("Fetching user status", res);
        setUserStatusState(res);
        if (res.status.kyc == "filled") {
          fetchKYCDetails();
        }
      }
    } catch (err) {
      console.error("Error checking user status:", err);
    }
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocuments({
      ...documents,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    if (file) {
      setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        [name]: file,
      }));
      setFilePreviews((prevPreviews) => ({
        ...prevPreviews,
        [name]: URL.createObjectURL(file),
      }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!documents.firms_pan_card_number) {
      newErrors.firms_pan_card_number = "PAN number is required";
    }
    if (!selectedFiles.firms_pan_card && !isEditMode) {
      newErrors.firms_pan_card = "PAN card is required";
    }
    if (!documents.gst_number) {
      newErrors.gst_number = "GST number is required";
    }
    if (!selectedFiles.gst_certificate && !isEditMode) {
      newErrors.gst_certificate = "GST certificate is required";
    }
    if (!selectedFiles.incorporation_certificate && !isEditMode) {
      newErrors.incorporation_certificate =
        "Incorporation certificate is required";
    }
    if (!selectedFiles.msme_certificate && !isEditMode) {
      newErrors.msme_certificate = "MSME certificate is required";
    }
    if (!selectedFiles.audit_financials && !isEditMode) {
      newErrors.audit_financials = "Audited financials are required";
    }
    if (!documents.owners_aadhar_card_number) {
      newErrors.owners_aadhar_card_number = "Owner Aadhar number is required";
    }
    if (!selectedFiles.owners_aadhar_card && !isEditMode) {
      newErrors.owners_aadhar_card = "Owner Aadhar card is required";
    }
    if (!documents.owners_pan) {
      newErrors.owners_pan = "Owner PAN number is required";
    }
    if (!selectedFiles.owners_pan_card && !isEditMode) {
      newErrors.owners_pan_card = "Owner PAN card is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submit>", documents, selectedFiles);
    if (!validateForm()) {
      return;
    }

    const formData = new FormData();
    formData.append("mobile_no", props.userData.mobile_no);
    formData.append("team", props.userData.team_id);
    formData.append("firms_pan_card_number", documents.firms_pan_card_number);
    if (selectedFiles.firms_pan_card)
      formData.append("firms_pan_card", selectedFiles.firms_pan_card);
    formData.append("gst_number", documents.gst_number);
    if (selectedFiles.gst_certificate)
      formData.append("gst_certificate", selectedFiles.gst_certificate);
    if (selectedFiles.incorporation_certificate)
      formData.append(
        "incorporation_certificate",
        selectedFiles.incorporation_certificate
      );
    if (selectedFiles.msme_certificate)
      formData.append("msme_certificate", selectedFiles.msme_certificate);
    if (selectedFiles.audit_financials)
      formData.append("audit_financials", selectedFiles.audit_financials);
    formData.append(
      "owners_aadhar_card_number",
      documents.owners_aadhar_card_number
    );
    if (selectedFiles.owners_aadhar_card)
      formData.append("owners_aadhar_card", selectedFiles.owners_aadhar_card);
    if (selectedFiles.owners_pan_card)
      formData.append("owners_pan_card", selectedFiles.owners_pan_card);
    formData.append("owners_pan", documents.owners_pan);

    try {
      if (isEditMode) {
        UpdateKYC(formData);
      } else if (isLoggedIn) {
        kyc(formData);
      }
      alert.success("Saved Successfully!");
      setIsEditMode(false);
    } catch (err) {
      console.error("Error occurred! :", err);
      alert.error("Error saving KYC details");
    }
  };

 const toggleEditMode = () => {
   if (
     isLoggedIn &&
     (userStatusState.stage === "KYC_STAGE" ||
       userStatusState.stage === "KYC_UNDER_REVIEW") &&
     userStatusState.status.kyc === "filled"
   ) {
     setIsEditMode(!isEditMode);
   }
 };

 const isInputDisabled =
   isLoggedIn &&
   (userStatusState.stage === "KYC_STAGE" ||
     userStatusState.stage === "KYC_UNDER_REVIEW") &&
   userStatusState.status.kyc === "filled" &&
   !isEditMode;

  const isFormValid = () => {
    return (
      documents.firms_pan_card_number &&
      documents.gst_number &&
      documents.owners_aadhar_card_number &&
      documents.owners_pan &&
      filePreviews.firms_pan_card &&
      filePreviews.gst_certificate &&
      filePreviews.owners_aadhar_card &&
      filePreviews.owners_pan_card &&
      filePreviews.incorporation_certificate &&
      filePreviews.msme_certificate &&
      filePreviews.audit_financials
    );
  };

  return (
    <>
      <h2 className="title">Registration</h2>
      <div className="container4">
        <h1 className="info">
          KYC
          {!isEditMode && <span className="page-indicator-4">4/4</span>}
          {isLoggedIn &&
            (userStatusState.stage === "KYC_STAGE" ||
              userStatusState.stage === "KYC_UNDER_REVIEW") &&
            userStatusState.status.kyc === "filled" && (
              <button className="edit-button" onClick={toggleEditMode}>
                {isEditMode ? "Cancel" : "Edit"}
              </button>
            )}
        </h1>
        <form>
          <div className="form-group">
            <label htmlFor="firms_pan_card_number">PAN Number (firm)*</label>
            <input
              type="text"
              id="firms_pan_card_number"
              name="firms_pan_card_number"
              placeholder="PAN Number (firm)*"
              value={documents.firms_pan_card_number}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
            {errors.firms_pan_card_number && (
              <span className="error">{errors.firms_pan_card_number}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("firms_pan_card")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload PAN (firm)*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="firms_pan_card"
              ref={(el) => (fileInputRefs.current["firms_pan_card"] = el)}
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.firms_pan_card && (
              <div className="file-preview">
                <a
                  href={filePreviews.firms_pan_card}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.firms_pan_card && (
              <span className="error">{errors.firms_pan_card}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="gst_number">GST Number*</label>
            <input
              type="text"
              id="gst_number"
              name="gst_number"
              placeholder="GST Number*"
              value={documents.gst_number}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
            {errors.gst_number && (
              <span className="error">{errors.gst_number}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("gst_certificate")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload GST certificate*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="gst_certificate"
              ref={(el) => (fileInputRefs.current["gst_certificate"] = el)}
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.gst_certificate && (
              <div className="file-preview">
                <a
                  href={filePreviews.gst_certificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.gst_certificate && (
              <span className="error">{errors.gst_certificate}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("incorporation_certificate")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload incorporation certificate*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="incorporation_certificate"
              ref={(el) =>
                (fileInputRefs.current["incorporation_certificate"] = el)
              }
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.incorporation_certificate && (
              <div className="file-preview">
                <a
                  href={filePreviews.incorporation_certificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.incorporation_certificate && (
              <span className="error">{errors.incorporation_certificate}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("msme_certificate")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload MSME certificate*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="msme_certificate"
              ref={(el) => (fileInputRefs.current["msme_certificate"] = el)}
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.msme_certificate && (
              <div className="file-preview">
                <a
                  href={filePreviews.msme_certificate}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.msme_certificate && (
              <span className="error">{errors.msme_certificate}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("audit_financials")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload Audited financials*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="audit_financials"
              ref={(el) => (fileInputRefs.current["audit_financials"] = el)}
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.audit_financials && (
              <div className="file-preview">
                <a
                  href={filePreviews.audit_financials}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.audit_financials && (
              <span className="error">{errors.audit_financials}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="owners_aadhar_card_number">
              Aadhar Number (owner)*
            </label>
            <input
              type="text"
              id="owners_aadhar_card_number"
              name="owners_aadhar_card_number"
              placeholder="Aadhar Number (owner)*"
              value={documents.owners_aadhar_card_number}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
            {errors.owners_aadhar_card_number && (
              <span className="error">{errors.owners_aadhar_card_number}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("owners_aadhar_card")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload Aadhar (owner)*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="owners_aadhar_card"
              ref={(el) => (fileInputRefs.current["owners_aadhar_card"] = el)}
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.owners_aadhar_card && (
              <div className="file-preview">
                <a
                  href={filePreviews.owners_aadhar_card}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.owners_aadhar_card && (
              <span className="error">{errors.owners_aadhar_card}</span>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="owners_pan">PAN Number (owner)*</label>
            <input
              type="text"
              id="owners_pan"
              name="owners_pan"
              placeholder="PAN Number (owner)*"
              value={documents.owners_pan}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
            {errors.owners_pan && (
              <span className="error">{errors.owners_pan}</span>
            )}
          </div>
          <div
            onClick={() => handleDivClick("owners_pan_card")}
            className="form-group"
          >
            <img src="/pdf_logo.png" alt="PDF Logo" />
            <div className="placeholder">Upload PAN (owner)*</div>
            <input
              type="file"
              accept=".jpeg,.png,.jpg,.pdf"
              name="owners_pan_card"
              ref={(el) => (fileInputRefs.current["owners_pan_card"] = el)}
              style={{ display: "none" }}
              onChange={handleFileChange}
              disabled={isInputDisabled}
            />
            {filePreviews.owners_pan_card && (
              <div className="file-preview">
                <a
                  href={filePreviews.owners_pan_card}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  View Uploaded File
                </a>
              </div>
            )}
            {errors.owners_pan_card && (
              <span className="error">{errors.owners_pan_card}</span>
            )}
          </div>
          {((userStatusState?.status?.kyc !== "filled" &&
            isFormValid()) ||
            (userStatusState?.status?.kyc === "filled" &&
              isEditMode &&
              isFormValid())) && (
            <button
              type="save"
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              Save
            </button>
          )}
        </form>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userDataReducer.userData,
  };
};

export default connect(mapStateToProps, null)(KYC);
