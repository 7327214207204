import React, { useEffect, useState } from "react";
import homeFilledSvg from "../../common/icons/svg/home-filled.svg";
import homeSvg from "../../common/icons/svg/home.svg";
import serviceFilledSvg from "../../common/icons/svg/service-filled.svg";
import serviceSvg from "../../common/icons/svg/service.svg";
import userFilledSvg from "../../common/icons/svg/user-filled.svg";
import userSvg from "../../common/icons/svg/user.svg";
import { useNavigate, useParams } from "react-router-dom";
import "./Footer.scss";
import { FOOTER, PAGE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";


export function Footer(props) {
    const navigate = useNavigate();

    const handleClick = (item) => {
        handleRedirection(item);
    }

    const handleRedirection = (item) => {
        if (item === FOOTER.USER) {
            console.log("redirect to user page");
            redirect(navigate, PAGE_CONST.USER_PROFILE);
        } else if (item === FOOTER.SERVICE) {
            console.log("redirect to service page");
            redirect(navigate, PAGE_CONST.SERVICE_MANAGEMENT);
        } else {
            redirect(navigate, PAGE_CONST.MY_MACHINE)
            console.log("redirect to my machine page");
        }
    }

    return (
        <div className="footer-container">
            <div className="home-icon-container" onClick={() => { handleClick(FOOTER.HOME) }}>
                {props.activeStage === FOOTER.HOME ?
                    <img className="home-icon" src={homeFilledSvg} alt="home icon" /> :
                    <img className="home-icon" src={homeSvg} alt="home icon" />
                }
            </div>
            <div className="service-icon-container" onClick={() => handleClick(FOOTER.SERVICE)}>
                {props.activeStage === FOOTER.SERVICE ?
                    <img className="service-icon" src={serviceFilledSvg} alt="service icon" /> :
                    <img className="service-icon" src={serviceSvg} alt="service icon" />
                }
            </div>
            <div className="user-icon-container" onClick={() => handleClick(FOOTER.USER)}>
                {props.activeStage === FOOTER.USER ?
                    <img className="user-icon" src={userFilledSvg} alt="user icon" /> :
                    <img className="user-icon" src={userSvg} alt="user icon" />
                }
            </div>
        </div>
    );
}