import React, { useState, useEffect } from "react";
import "./ProductandComapny.scss";
import { ProductsAndCompany } from "../../helper/ProductsAndCompanyHelper";
import {
  UpdateProductsAndCompany,
  RetrieveProductsAndCompany,
} from "../../helper/UpdateandRetrieveProductsandCompanyHelper";
import { useAlert } from "react-alert";
import { connect } from "react-redux";
import { getCookieCustom } from "../../common/utils/StorageUItils";
import { LOGIN_CONST } from "../../common/constant/page-constants";
import { userStatus } from "../../helper/user-helper.js";

function ProductandCompany(props) {
  const alert = useAlert();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userStatusState, setUserStatusState] = useState({});

  const [productDetails, setProductDetails] = useState({
    team: props.userData.team_id,
    name_of_companies_working_with: "",
    medical_device_segment: "",
    name_of_products_dealing_in: "",
  });

  useEffect(() => {
    if (props.userData.mobile_no) {
      setIsLoggedIn(true);
    }
  }, [props.userData.mobile_no]);

  const fetchProductDetails = async () => {
    try {
      const data = await RetrieveProductsAndCompany({
        mobile_no: props.userData.mobile_no,
      });
      if (data) {
        setProductDetails({
          mobile_no: props.userData.mobile_no,
          name_of_companies_working_with: data.name_of_companies_working_with,
          medical_device_segment: data.medical_device_segment,
          name_of_products_dealing_in: data.name_of_products_dealing_in,
        });
      }
    } catch (error) {
      console.error("Error retrieving product details:", error);
      alert.error("Error retrieving product details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({
      ...productDetails,
      [name]: value,
    });
  };
  const fetchUserStatus = async () => {
    try {
      const res = await userStatus({
        mobile_no: props.userData.mobile_no,
      });
      if (res) {
        setUserStatusState(res);
        if (res.status.products_and_company == "filled") {
          fetchProductDetails();
        }
      }
    } catch (err) {
      console.error("Error checking user status:", err);
    }
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (isEditMode) {
        UpdateProductsAndCompany(productDetails);
      } else if (isLoggedIn) {
        ProductsAndCompany(productDetails);
      }
      alert.success("Saved Successfully!");
      setIsEditMode(false);
    } catch (err) {
      console.error("Error occurred:", err);
      alert.error("Error saving product details");
    }
  };

  const toggleEditMode = () => {
    if (
      isLoggedIn &&
      (userStatusState?.stage === "KYC_STAGE" ||
        userStatusState?.stage === "KYC_UNDER_REVIEW") &&
      userStatusState?.status.products_and_company === "filled"
    ) {
      setIsEditMode(!isEditMode);
    }
  };

  const isInputDisabled =
    isLoggedIn &&
    (userStatusState?.stage === "KYC_STAGE" ||
      userStatusState?.stage === "KYC_UNDER_REVIEW") &&
    userStatusState?.status.products_and_company === "filled" &&
    !isEditMode;

  const isFormValid = () => {
    return (
      productDetails.name_of_companies_working_with &&
      productDetails.medical_device_segment &&
      productDetails.name_of_products_dealing_in
    );
  };

  return (
    <div>
      <h2 className="title">Registration</h2>
      <div className="container1">
        <h1 className="info2">
          Product & Company
          {!isEditMode && <span className="page-indicator-3">3/4</span>}
          {isLoggedIn &&
            (userStatusState?.stage === "KYC_STAGE" ||
              userStatusState?.stage === "KYC_UNDER_REVIEW") &&
            userStatusState?.status.products_and_company === "filled" && (
              <button className="edit-button" onClick={toggleEditMode}>
                {isEditMode ? "Cancel" : "Edit"}
              </button>
            )}
        </h1>
        <form>
          <div className="form-group">
            <label htmlFor="name"></label>
            <input
              type="text"
              id="name"
              name="name_of_companies_working_with"
              placeholder="Names of companies you are working with*"
              className="placeholder"
              value={productDetails.name_of_companies_working_with}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="companies"></label>
            <input
              type="text"
              id="companies"
              name="medical_device_segment"
              placeholder="Medical device segment that you are currently working in*"
              className="placeholder"
              value={productDetails.medical_device_segment}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
          </div>
          <div className="form-group">
            <label htmlFor="device"></label>
            <input
              type="text"
              id="device"
              name="name_of_products_dealing_in"
              placeholder="Names of products that you are dealing in*"
              className="placeholder"
              value={productDetails.name_of_products_dealing_in}
              onChange={handleChange}
              required
              disabled={isInputDisabled}
            />
          </div>
          {((userStatusState?.status?.products_and_company !== "filled" &&
            isFormValid()) ||
            (userStatusState?.status?.products_and_company === "filled" &&
              isEditMode &&
              isFormValid())) && (
            <button
              type="save"
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              Save
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userDataReducer.userData,
  };
};

export default connect(mapStateToProps, null)(ProductandCompany);
