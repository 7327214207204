import React from "react";

import searchSvg from "../../common/icons/svg/search.svg";
import notificationSvg from "../../common/icons/svg/notification.svg";
import backArrowSvg from "../../common/icons/svg/back-arrow.svg";
import "./Header.scss";

export function Header(props) {

    const handleBackClick = () => {
        if (props.handleBackClick) props.handleBackClick()
    }

    return (
        <div className="header-container">
            {(!props.headerConf || !props.headerConf.hideBackIcon) && <div className="back-arrow-container" onClick={() => handleBackClick()}>
                <img className="back-arrow" src={backArrowSvg} alt="back-arrow" />
            </div>}
            <div className="header-text">
                {(props.headerConf && props.headerConf.heading) || 'Stock Management'}
            </div>
            {/* <div className="icon-container">
                {(!props.headerConf || !props.headerConf.hideSearchIcon) && <div className="search-icon-container">
                    <img className="search-icon" src={searchSvg} alt="search icon" />
                </div>}
                {(!props.headerConf || !props.headerConf.hideNotificationIcon) && <div className="notification-icon-container">
                    <img className="notification-icon" src={notificationSvg} alt="notification icon" />
                </div>}
            </div> */}
        </div>
    );
}