import "./ComplaintAttachment.scss";
import { Header } from "../Header/Header";
import { useState } from "react";
import { UPLOAD } from "../../common/constant/page-constants";
import notUploadedImg1 from "../../common/icons/svg/not-uploaded-img1.svg";
import notUploadedImg2 from "../../common/icons/svg/not-uploaded-img2.svg";
import BackDrop from "../BackDrop/BackDrop";
import Modal from "../Modal/Modal";
import { FileUploader } from "react-drag-drop-files";
// import Dropzone from 'react-dropzone-uploader'
// import 'react-dropzone-uploader/dist/styles.css'

function ComplaintAttachment() {

    const [uploadActive, setUploadActive] = useState(false);
    const [uploadType, setUploadType] = useState(UPLOAD.TYPE.IMAGE);
    const [notUploadedImg, setNotUploadedImg] = useState(true);
    const [uploadModal, setUploadModal] = useState(false);
    const [file, setFile] = useState(null);

    const headerConf = {
        heading: "Service Management"
    }

    const handleUploadClick = () => {
        setUploadActive(!uploadActive);
    }

    const handleChange = (file) => {
        console.log(file);
        setFile(file);
    };

    const handleUploadType = (type) => {
        setUploadType(type);
        setNotUploadedImg(false);
        setTimeout(() => {
            setUploadActive(false);
        }, 70)
        setUploadModal(true);
        console.log("upload type set to", type);
    }

    const handleSubmitClick = () => {
        console.log("submit button was clicked");
    }

    const handleModalCancelClick = () => {
        setTimeout(() => {
            setUploadModal(false);
        }, 70);
    }

    const handleFunctionBtnClick = () => {
        setTimeout(() => {
            setUploadModal(false);
        }, 70);
    }

    const fileTypes = ["JPG", "PNG", "GIF"];

    // const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }

    // // called every time a file's `status` changes
    // const handleChangeStatus = ({ meta, file }, status) => { console.log(status, meta, file) }

    // // receives array of files that are done uploading when submit button is clicked
    // const handleSubmit = (files) => { console.log(files.map(f => f.meta)) }

    const addDiv = () => {
        let tempDiv = (
            <div className="upload-container">
                <div className="modal-heading">Upload File</div>
                <div className="uploader-area" onClick={() => console.log("drag drop div was clicked")}>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                    // onClick={() => console.log("drag drop was clicked")}
                    />
                    {/* <Dropzone
                        getUploadParams={getUploadParams}
                        onChangeStatus={handleChangeStatus}
                        onSubmit={handleSubmit}
                    /> */}
                </div>
            </div >
        )
        return tempDiv;
    }

    return (
        <div className="complaint-attachment-container">
            <Header headerConf={headerConf} />
            <div className="machine-name">PNQ3546787654</div>
            <div className="attachment-upload-container">
                {notUploadedImg &&
                    <div className="not-uploaded-img">
                        <img src={notUploadedImg1} id="img-1" />
                        <img src={notUploadedImg2} id="img-2" />
                        <span className="attach-valid-doc-text"> Attach Valid Documents</span>
                    </div>
                }
                <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                <div className="btn-container">
                    {uploadActive &&
                        <div className="upload-type">
                            <div className="image-upload-btn common-upload" onClick={() => handleUploadType(UPLOAD.TYPE.IMAGE)}>Image</div>
                            <div className="document-upload-btn common-upload" onClick={() => handleUploadType(UPLOAD.TYPE.DOCUMENT)}>Document</div>
                        </div>
                    }
                    <div className="common-btn" onClick={handleUploadClick}>Upload</div>
                    <div className="common-btn default-fill" onClick={handleSubmitClick}>Submit</div>

                </div>
            </div>
            {uploadModal && <BackDrop />}
            {uploadModal &&
                <Modal
                    addDiv={addDiv}
                    functionBtnText="Upload"
                    handleModalCancelClick={handleModalCancelClick}
                    handleFunctionBtnClick={handleFunctionBtnClick}
                />
            }
        </div>
    )
}
export default ComplaintAttachment;