import { sellerExperienceTerritoryAPI } from "../adapter/service-adapter";

export const sellerExperienceTerritory = (data) => {
  let promise = new Promise(function (resolve, reject) {
    sellerExperienceTerritoryAPI(data).then((res) => {
      console.log("SellerExperienceTerritory>>", res.data);
      if (
        (res && res.status === 200) ||
        res.status === 201 ||
        res.status === 202
      ) {
        resolve(res);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};