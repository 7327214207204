import "./ComplaintCard.scss";

import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { PROGRESS_STATUS } from "../../common/constant/page-constants";
import pdfIconSvg from "../../common/icons/svg/pdf-icon.svg";

function ComplaintCard(props) {
    const navigate = useNavigate();

    const handleClick = (event) => {
        if (!props.data || props.data.status === PROGRESS_STATUS.COMPLETED) return;

        props.handleClick(event);
    }


    return (
        <div className="complaint-card-container" onClick={(event) => handleClick(event)}>
            <div className="complaint-number">#{props.data.id}: {props.data.type} </div>
            <div className="card-header">
                <div className="machine-serial-name">{props.data.machine_serial_name}</div>
                {props.data.status === PROGRESS_STATUS.COMPLETED && props.data && props.data.report_url &&
                    <div className="machine-redirect">
                        <a
                            download={`${props.data.type ? props.data.type.toLowerCase() + ".pdf" : "report.pdf"}`}
                            href={props.data.report_url}
                            className="machine-redirect-link"
                        >
                            <img src={pdfIconSvg} className="md-redir-icon" />
                        </a>
                    </div>}
                {<div className={`status ${props.data.status}`}>{props.data.status}</div>}
            </div>
            <div className="hospital-name">{props.data.organization_name}</div>
            <div className="date-of-reg">{`Date: ${moment(props.data.created_at).format("DD-MM-YYYY")}`}</div>
            {
                true && <div className="complaint-description">
                    {"ckcghkcghkckhc"}{props.data.issueReported}
                </div>
            }

            {props.data.created_by && <div className="created-by"> Created by: {props.data.created_by}</div>}
        </div >
    )
}

export default ComplaintCard;