import "./RCAForm.scss";
import { Header } from "../Header/Header";
import CustomSelect from "../CustomSelect/CustomSelect";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

function RCAForm() {
    const handleSubmitBtn = () => {
        console.log("submit button was clicked!!!");
    }

    const headerConf = {
        heading: "RCA"
    }

    const handleSelectChange = (item) => {
        console.log(item, "this item is selected");
    }

    const handleCancelBtn = () => {
        console.log("cancel btn was clicked");
    }

    return (
        <div className="rca-form-container">
            <Header headerConf={headerConf} />
            <div className="machine-name">PNQ3546787654</div>
            <div className="form-container">
                <form className="form-detail">
                    <div className="common-select-input">
                        <label>Complaint Category</label>
                        <div className="custom-select-container">
                            <CustomSelect
                                options={[{ name: 'test1', label: 'test1' }, { name: 'test2', label: 'test2' }]}
                                id="select-complaint-category"
                                isClearable={false}
                                label='complaint-category'
                                placeholder='Complaint Category'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                handleChange={(item) => handleSelectChange(item)}
                                borderStyle="none"
                                onFocus={(event) => handleOnFocusById(event.target.id)}
                            />
                        </div>
                    </div>
                    <div className="common-select-input">
                        <label>Complaint Attribute</label>
                        <div className="custom-select-container">
                            <CustomSelect
                                options={[{ name: 'test1', label: 'test1' }, { name: 'test2', label: 'test2' }]}
                                id="select-complaint-attribute"
                                isClearable={false}
                                label='complaint-attribute'
                                placeholder='Complaint Attribute'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                handleChange={(item) => handleSelectChange(item)}
                                borderStyle="none"
                                onFocus={(event) => handleOnFocusById(event.target.id)}
                            />
                        </div>
                    </div>
                    <div className="common-text-input">
                        <label>Root Cause</label>
                        <input type="text" placeholder="Root Cause" id="text-root-cause"
                            onFocus={(event) => handleOnFocusById(event.target.id)} />
                    </div>
                    <div className="common-text-input">
                        <label>Preventive Action</label>
                        <textarea placeholder="Preventive Action" id="text-preventive-measure"
                            onFocus={(event) => handleOnFocusById(event.target.id)} />
                    </div>

                    <div className="common-btn">
                        <div className="cancel-btn" onClick={handleCancelBtn}>Cancel</div>
                        <div className="submit-btn" onClick={handleSubmitBtn}>Submit</div>
                    </div>

                </form>
            </div >
        </div >
    )
}
export default RCAForm;