import {
  UpdateProductsAndCompanyAPI,
  RetrieveProductsAndCompanyAPI,
} from "../adapter/service-adapter";

export const UpdateProductsAndCompany = (data) => {
  let promise = new Promise(function (resolve, reject) {
    UpdateProductsAndCompanyAPI(data).then((res) => {
      console.log("UPDATESeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};

export const RetrieveProductsAndCompany = (data) => {
  let promise = new Promise(function (resolve, reject) {
    RetrieveProductsAndCompanyAPI(data).then((res) => {
      console.log("fetchSeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};
