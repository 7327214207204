import "./MyTeam.scss";
import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { redirect } from "../../common/utils/RedirectionUtils";
import { MY_TEAM_CONST, PAGE_CONST } from "../../common/constant/page-constants";
import teamMemberIcon from "../../common/icons/svg/team-member-icon.svg";
import BackDrop from "../../components/BackDrop/BackDrop";
import Modal from "../../components/Modal/Modal";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import editMemberIcon from "../../common/icons/svg/edit-member-icon.svg";
import { getUserTeamMembers, updateTeamMember } from "../../helper/user-helper";
import moment from "moment";


function MyTeam() {
    const navigate = useNavigate();
    const [activeGroup, setActiveGroup] = useState(MY_TEAM_CONST.GROUP.ALL_MEMBERS);
    const [showModal, setShowModal] = useState(false);
    const [typeOfModal, setTypeOfModal] = useState("");
    const [dataContainer, setDataContainer] = useState([]);
    const [modalData, setModalData] = useState();

    const headerConf = {
        heading: "Profile",
    }

    const handleHeaderBackClick = () => {
        redirect(navigate, PAGE_CONST.USER_PROFILE);
    }

    const handleHeaderClick = (item) => {
        setActiveGroup(item);
    }

    const handleAddTeamMemberClick = () => {
        redirect(navigate, PAGE_CONST.ADD_TEAM_MEMBER);
    }

    const handleSliderClick = (data) => {
        setModalData(data);

        if (data.status === "IN_ACTIVE") setTypeOfModal(MY_TEAM_CONST.SLIDER_ACTIVATE);
        else setTypeOfModal(MY_TEAM_CONST.SLIDER_DEACTIVATE);

        setTimeout(() => {
            setShowModal(true);
        }, 70);
    }

    const handleFunctionBtnClick = () => {
        let getStatus;
        if (typeOfModal === "EDIT_MEMBER") {
            getStatus = modalData.status;
        } else {
            if (modalData.status === "ACTIVE") getStatus = "IN_ACTIVE";
            else getStatus = "ACTIVE";
        }
        let dataObj = {
            user_id: modalData.id,
            role: modalData.role,
            status: getStatus
        }

        updateTeamMember(dataObj).then((res) => {
            getUserData();
            console.log("new data container", dataContainer)
        }).catch((err) => {
            console.log("updation err ", err);
        })

        setTimeout(() => {
            redirect(navigate, PAGE_CONST.MY_TEAM);
            setShowModal(false);
        }, 70);
    }

    const handleModalCancelClick = () => {
        setTimeout(() => {
            setShowModal(false);
        }, 70);
    }

    const handleEditMemberClick = (data) => {
        setModalData(data);
        console.log("present data", data);
        setTypeOfModal(MY_TEAM_CONST.EDIT_MEMBER);
        setShowModal(true);
    }

    const handleRadioClick = (event) => {
        console.log("event", event);
        let getRole = event.target.id;
        if (getRole !== modalData.status) {
            setModalData((prevState) => ({
                ...prevState,
                role: getRole
            }));
        }
    }

    useEffect(() => {
        getUserData();
    }, [])

    const getUserData = () => {
        getUserTeamMembers().then((res) => {
            setDataContainer(res);
        }).catch((err) => {
            console.log("machine err: ", err);
        });
    }

    const addDiv = () => {
        let tempDiv;
        if (typeOfModal === MY_TEAM_CONST.SLIDER_DEACTIVATE) {
            tempDiv = (
                <div className="modal-content">
                    <div className="modal-heading">Deactivate User</div>
                    <div className="modal-date">Date: {moment(new Date()).format("DD-MM-YYYY")}</div>
                    <div className="modal-declaration">
                        Are you sure you want to deactivate {modalData.name}.
                        This user will not be able to access the app unless
                        it has been activated.
                    </div>
                </div>
            )
        } else if (typeOfModal === MY_TEAM_CONST.SLIDER_ACTIVATE) {
            tempDiv = (
                <div className="modal-content">
                    <div className="modal-heading">Activate User</div>
                    <div className="modal-date">Date: {moment(new Date()).format("DD-MM-YYYY")}</div>
                    <div className="modal-declaration">
                        Are you sure you want to activate {modalData.name}.
                        This user will be able to access the app unless
                        it has been deactivated.
                    </div >
                </div >
            )
        } else if (typeOfModal === MY_TEAM_CONST.EDIT_MEMBER) {
            tempDiv = (
                <div className="modal-content">
                    <div className="modal-heading">Edit User</div>
                    <div className="edit-member-body">
                        <div className="edit-icon">
                            <img src={editMemberIcon} />
                        </div>
                        <div className="edit-member-info-body">
                            <div className="edit-member-name">{modalData.name}</div>
                            {/* <div className="member-since-cls">Member Since: 12-3-2022</div> */}
                            {modalData.mobile_no &&
                                <div className="mobile-number-cls">{modalData.mobile_no}</div>}
                            <div className="role-cls">
                                <div>Role:</div>
                                <div className="role-radio-container">
                                    <div className="common-radio-cls">
                                        <div className="common-label-cls">
                                            <input
                                                type="radio"
                                                name="role-select"
                                                id="ADMIN"
                                                defaultChecked={modalData.role === "ADMIN" ? true : false}
                                                onChange={(event) => handleRadioClick(event)}
                                            />
                                            <label className="" htmlFor="ADMIN">Admin</label>
                                        </div>
                                        <span>This user will become the ADMIN</span>
                                    </div>
                                    <div className="common-radio-cls">
                                        <div className="common-label-cls">
                                            <input
                                                type="radio"
                                                name="role-select"
                                                id="TEAM_MEMBER"
                                                defaultChecked={modalData.role === "TEAM_MEMBER" ? true : false}
                                                onChange={(event) => handleRadioClick(event)}
                                            />
                                            <label className="" htmlFor="TEAM_MEMBER">Team Member</label>
                                        </div>
                                        <span>This user will become a TEAM MEMBER</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            )
        }
        return tempDiv;
    }

    const getInitials = (name) => {

        try {
            let initial = name.split(" ").map((n) => n[0]).join("");
            return initial && initial.toUpperCase();

        } catch (err) {
            console.log(err);
            return "LI";
        }
    }

    const addMembers = () => {
        let teamMembersHtml;
        let updatedContainer = dataContainer;

        if (dataContainer) {
            if (activeGroup === MY_TEAM_CONST.GROUP.ADMIN) {
                updatedContainer = dataContainer.filter((ele) => {
                    return ele.role === activeGroup;
                })
            } else if (activeGroup === MY_TEAM_CONST.GROUP.TEAM) {
                updatedContainer = dataContainer.filter((ele) => {
                    return ele.role === activeGroup;
                })
            }
            if (updatedContainer) {
                teamMembersHtml = updatedContainer.map(function (data) {
                    return (
                        <div className="member-entry" key={data.id}>
                            <div className="member-initials">{getInitials(data.name)}</div>
                            {/* <img className="member-img" src={teamMemberIcon} /> */}
                            <div className="member-detail" onClick={() => handleEditMemberClick(data)}>
                                <div className="member-name"> {data.name}</div>
                                <div className="member-role"> {data.role}</div>
                            </div>
                            <ToggleSwitch
                                id={data.id}
                                handleSliderClick={() => handleSliderClick(data)}
                                checked={data.status === "ACTIVE" ? true : false}
                            />
                        </div>
                    )
                })
            }
        }

        return teamMembersHtml;
    }

    return (
        <div className="myteam-container">
            <Header headerConf={headerConf} handleBackClick={() => handleHeaderBackClick()} />
            <div className="myteam-header">My Team</div>
            <div className="myteam-group-content-container">
                <div className="myteam-group-content-header">
                    <div
                        className={activeGroup === MY_TEAM_CONST.GROUP.ALL_MEMBERS ?
                            "myteam-group-header active-header" : "myteam-group-header"}
                        onClick={() => handleHeaderClick(MY_TEAM_CONST.GROUP.ALL_MEMBERS)}>
                        All
                    </div>
                    <div
                        className={activeGroup === MY_TEAM_CONST.GROUP.ADMIN ?
                            "myteam-group-header active-header" : "myteam-group-header"}
                        onClick={() => handleHeaderClick(MY_TEAM_CONST.GROUP.ADMIN)}>
                        Admin
                    </div>
                    <div
                        className={activeGroup === MY_TEAM_CONST.GROUP.TEAM ?
                            "myteam-group-header active-header" : "myteam-group-header"}
                        onClick={() => handleHeaderClick(MY_TEAM_CONST.GROUP.TEAM)}>
                        Team
                    </div>
                </div>
                <div className="team-data-container">
                    {addMembers()}
                </div>
                <div className="add-member-btn" onClick={handleAddTeamMemberClick}>
                    Add Team Member
                </div>
            </div>
            {showModal && <BackDrop />}
            {showModal &&
                <Modal
                    html={addDiv()}
                    functionBtnText={typeOfModal === MY_TEAM_CONST.EDIT_MEMBER ? "Save" : "Confirm"}
                    handleModalCancelClick={handleModalCancelClick}
                    handleFunctionBtnClick={handleFunctionBtnClick}
                />
            }
        </div >
    )
}
export default MyTeam;
