import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Navigate, Route,Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './modules/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { routes } from './common/constant/routes-constants';
import "./common/scss/common.scss";



function App() {

    return (
      <div className="App" id="App">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
              <BrowserRouter>
                  <Routes>
                    {routes.map((route) => {
                      return (
                        <Route
                          key={route.key}
                          path={route.path}
                          authNeeded={route.authNeeded}
                          element={route.element}
                        />
                      );
                    })}
                    <Route 
                      path="*" 
                      element={<Navigate to="/login" />} />
                  </Routes>
              </BrowserRouter>
          </PersistGate>
        </Provider>
      </div>
    );
}
export default App;