import { SubmitForReviewAPI } from "../adapter/service-adapter";
export const SubmitForReview = (data) => {
  let promise = new Promise(function (resolve, reject) {
    SubmitForReviewAPI(data).then((res) => {
      console.log("reviewStatus>>>", res.data);
      if ((res && res.status === 200)) {
        resolve(res);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};