import {
    getComponentTypesAPI,
    getMachineDetailsBySerialNameAPI,
    sendFlowOtpAPI,
    verifyFlowOtpAPI
} from "../adapter/service-adapter";
import { FLOW } from "../common/constant/page-constants";
import { addBillOfMaterial, addBreakDownBillOfMaterial, getBillOfMaterials, getBreakDownBillOfMaterials } from "./BreakdownHelper";
import { addPMBillOfMaterial, getPMBillOfMaterials } from "./PreventiveMaintenanceHelper";

export const getMachineDetailsByName = (data) => {

    let reqData = {
        machine_serial_name: data.serialName
    }

    let promise = new Promise(function (resolve, reject) {
        getMachineDetailsBySerialNameAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const getComponentTypes = (data) => {
    let reqData = {
        product_id: data.productId
    }

    let promise = new Promise(function (resolve, reject) {
        getComponentTypesAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const sendFlowOtp = (data) => {

    let reqData = {
        mobile_no: data.mobileNo,
        type: data.flow,
        order_id: data.orderId,
    }

    let promise = new Promise(function (resolve, reject) {
        sendFlowOtpAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const verifyFlowOtp = (data) => {

    let reqData = {
        mobile_no: data.mobileNo,
        type: data.flow,
        order_id: data.orderId,
        otp: data.otp
    }

    let promise = new Promise(function (resolve, reject) {
        verifyFlowOtpAPI(reqData).then((res) => {
            // console.log("send otp then:: " + JSON.stringify(res));

            if (res && res.data && res.data.status === 200) {
                resolve();
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const getCommonBillOfMaterials = (type, data) => {

    if (type === FLOW.BREAKDOWN) {
        return getBreakDownBillOfMaterials(data);
    } else if (type === FLOW.PREVENTIVE_MAINTENANCE) {
        return getPMBillOfMaterials(data);
    }
}

export const addCommonBillOfMaterial = (type, data) => {

    if (type === FLOW.BREAKDOWN) {
        return addBreakDownBillOfMaterial(data);
    } else if (type === FLOW.PREVENTIVE_MAINTENANCE) {
        return addPMBillOfMaterial(data);
    }
}