import React, { useEffect, useState } from "react";
import { FLOW } from "../../common/constant/page-constants";
import { handleOnFocusById } from "../../common/utils/CommonUtils";
import CustomSelect from "../CustomSelect/CustomSelect";

import "./BreakDownForm.scss";

export function BreakDownForm(props) {

    const [machineSerialNumber, setMachineSerialNumber] = useState("");
    const [machineType, setMachineType] = useState("");
    const [contactName, setContactName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [email, setEmail] = useState("");
    const [issueReported, setIssueReported] = useState("");
    const [machineTypeOption, setMachineTypeOption] = useState(
        [{ name: "2", label: 'V 310' },
        { name: "3", label: 'V310+' }]
    );

    const [machineDetail, setMachineDetail] = useState(null);

    useEffect(() => {

        setMachineDetail(props.machineDetail);

        if (props.machineDetail) {
            setMachineType({ name: props.machineDetail.product_id, label: props.machineDetail.product_type })
        }

    }, [props.machineDetail])

    const onSubmit = () => {

        const data = {
            machineSerialNumber: machineSerialNumber,
            productId: machineType.name,
            contactName: contactName,
            contactNumber: contactNumber,
            email: email,
            issueReported: issueReported
        };

        console.log(data);
        props.onSubmit(data);
    }

    const onCancel = () => {

        props.onCancel();
    }

    const onMachineNameBlur = () => {

        setMachineType(null);
        if (machineSerialNumber) props.getMachineDetailsBySerialName(machineSerialNumber);
    }

    return (
        <div className="breakdown-form-container">
            <div className="breakdown-form-heading">
                Details
            </div>
            <div className="machine-detail-form">
                <div className="machine-detail-form-heading">
                    Machine Details
                </div>
                <div className="machine-detail-form-body">
                    <input
                        className="machine-serial-number input"
                        id="machine-serial-number"
                        placeholder="Machine serial No."
                        onChange={(event) => { props.setEmptyErrMsg(); setMachineSerialNumber(event.target.value) }}
                        onBlur={() => onMachineNameBlur()}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                    <CustomSelect
                        id="machine-type"
                        handleChange={(item) => setMachineType(item)}
                        label='label'
                        options={machineTypeOption}
                        value={machineType}
                        placeholder='Type'
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onChange={(item) => {
                            props.setEmptyErrMsg();
                            setMachineType(item)
                        }}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                </div>
            </div>
            <div className="contact-person-form">
                <div className="contact-person-form-heading">
                    Contact Person Details
                </div>
                <div className="contact-person-form-body">
                    <input
                        className="contact-name input"
                        id="contact-name"
                        placeholder="Contact Person Name"
                        onChange={(event) => {
                            props.setEmptyErrMsg();
                            setContactName(event.target.value)
                        }}
                        type="text"
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                    <input
                        className="contact-number input"
                        id="contact-number"
                        placeholder="Contact Number"
                        maxLength={10}
                        minLength={10}
                        onChange={(event) => {
                            props.setEmptyErrMsg();
                            setContactNumber(event.target.value)
                        }}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                        type="number"
                    />
                    <input
                        className="contact-mail input"
                        id="email-id"
                        placeholder="Contact Email ID"
                        onChange={(event) => {
                            props.setEmptyErrMsg();
                            setEmail(event.target.value)
                        }}
                        type="email"
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                    {props.flow === FLOW.BREAKDOWN && <textarea
                        className="issue-reported textarea"
                        rows="4"
                        value={issueReported}
                        placeholder="Issue Reported"
                        id="isseue-reported"
                        // onKeyPress={(event) => {
                        //     setIssueReported(event.target.value);
                        //     if (event && (event.key === 'Enter' && !event.shiftKey)) {
                        //         console.log(event.target.value);
                        //     }
                        // }}
                        onChange={(event) => {
                            props.setEmptyErrMsg();
                            setIssueReported(event.target.value)
                        }}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />}
                    {props.errorMsg && <div className="error-msg">{props.errorMsg}</div>}
                </div>
            </div>
            <div className="cta-container">
                <div className="cancel-container" onClick={() => onCancel()}>Cancel</div>
                <div className="submit-container" onClick={() => onSubmit()}>Save</div>
            </div>
        </div>
    )
}