import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-date-picker";

import upArrowPurpleSvg from "../../common/icons/svg/up-arrow-purple.svg";
import downArrowPurpleSvg from "../../common/icons/svg/down-arrow-purple.svg";
import machineSvg from "../../common/icons/svg/machine.svg";
import crossSvg from "../../common/icons/svg/cross.svg";

import CustomSelect from "../CustomSelect/CustomSelect";
import { activeGroup } from "../../modules/actions/action";
import { handleOnFocusById } from "../../common/utils/CommonUtils";
import { FLOW } from "../../common/constant/page-constants";

import "./CustomerDetail.scss";
import BackDrop from "../BackDrop/BackDrop";

export function CustomerDetail(props) {
  const [isMachineDetailVisible, setIsMachineDetailVisible] = useState(false);
  const [warrantyType, setWarrantyType] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState(null);
  const [organizationName, setOrganizationName] = useState(null);
  const [contactPerson, setContactPerson] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [email, setEmailId] = useState(null);
  const [address, setAddress] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);

  const typeOptions = [
    { name: "DEALER", label: "Dealer" },
    { name: "END_CUSTOMER", label: "End Customer" },
  ];

  const warrantyOptions = [
    { name: "IN_WARRANTY", label: "IN WARRANTY" },
    { name: "OUT_OF_WARRANTY", label: "OUT OF WARRANTY" },
  ];

  useEffect(() => {
    if (props.errMsg) {
      setIsPopUpVisible(false);
      handleOnFocusById("error-msg");
    }
  }, [props.errMsg]);

  useEffect(() => {
    setIsSubmitEnabled(false);
    if (props.removeErrorMsg) props.removeErrorMsg();

    if (props.type === FLOW.SELL_MACHINE) {
      if (
        type &&
        organizationName &&
        contactPerson &&
        contactNumber &&
        email &&
        address &&
        pincode &&
        city &&
        state &&
        props.machineList.length > 0
      )
        setIsSubmitEnabled(true);
      else setIsSubmitEnabled(false);
    } else if (props.type === FLOW.INSTALL_ORDER) {
      if (
        organizationName &&
        contactPerson &&
        contactNumber &&
        email &&
        address &&
        pincode &&
        city &&
        state
      )
        setIsSubmitEnabled(true);
      else setIsSubmitEnabled(false);
    } else if (
      [FLOW.BREAKDOWN, FLOW.PREVENTIVE_MAINTENANCE].indexOf(props.type) > -1
    ) {
      if (warrantyType && warrantyType.name === "OUT_OF_WARRANTY") {
        /** for setting startdate and end date optional */
        if (organizationName && address && pincode && city && state)
          setIsSubmitEnabled(true);
      } else if (
        warrantyType &&
        startDate &&
        endDate &&
        organizationName &&
        address &&
        pincode &&
        city &&
        state
      )
        setIsSubmitEnabled(true);
      else setIsSubmitEnabled(false);
    }
  }, [
    type,
    organizationName,
    contactPerson,
    contactNumber,
    email,
    address,
    pincode,
    city,
    state,
    warrantyType,
  ]);

  const handleSubmit = () => {
    if (!isPopUpVisible) setIsPopUpVisible(true);

    let selectedMachineId;
    if (props.machineList) {
      selectedMachineId = props.machineList.map((machine) => {
        return machine.id;
      });
    }

    let data = {
      machineSerialIds: selectedMachineId,
      customer: {
        type: type && type.name,
        warrantyType: warrantyType && warrantyType.name,
        startDate: startDate && moment(startDate).format("DD-MM-YYYY"),
        endDate: endDate && moment(endDate).format("DD-MM-YYYY"),
        contactNumber: contactNumber,
        contactMail: email,
        organizationName: organizationName,
        contactPerson: contactPerson,
        address: address,
        pincode: pincode,
        city: city,
        state: state,
      },
    };

    props.handleSubmit(data);
    setIsPopUpVisible(false);
  };

  const handleCrossClick = (machine) => {
    props.removeSelectedMachine(machine);
  };

  return (
    <>
      <div className="customer-detail-container">
        <div
          className={
            isPopUpVisible
              ? "customer-detail no-pointer-events"
              : "customer-detail"
          }
        >
          <div className="heading">Customer Details</div>
          {props.type === FLOW.SELL_MACHINE && (
            <div className="machine-details-container">
              <div className="left-panel">
                <div className="icon-container">
                  <img
                    className="machine-icon"
                    src={machineSvg}
                    alt="machine-icon"
                  />
                </div>
                <div className="machine-number">{`${props.machineList.length} Machines`}</div>
              </div>
              <div className="right-panel">
                <div className="icon-container">
                  {!isMachineDetailVisible && (
                    <img
                      className="down-arrow"
                      src={downArrowPurpleSvg}
                      alt="down-arrow"
                      onClick={() =>
                        setIsMachineDetailVisible(!isMachineDetailVisible)
                      }
                    />
                  )}
                  {isMachineDetailVisible && (
                    <img
                      className="up-arrow"
                      src={upArrowPurpleSvg}
                      alt="up-arrow"
                      onClick={() =>
                        setIsMachineDetailVisible(!isMachineDetailVisible)
                      }
                    />
                  )}
                </div>
              </div>
              {isMachineDetailVisible && (
                <div className="machine-details">
                  {props.machineList.map((machine) => {
                    return (
                      <div className="machine" key={machine.id}>
                        <div className="machine-name">
                          {machine.serial_name}
                        </div>
                        <div className="icon-container">
                          <img
                            className="cross"
                            src={crossSvg}
                            alt="cross"
                            onClick={() => handleCrossClick(machine)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div className="customer-detail-form-container">
            {props.errorMsg && (
              <div className="error-msg" id="error-msg">
                {props.errorMsg}
              </div>
            )}
            {[FLOW.BREAKDOWN, FLOW.PREVENTIVE_MAINTENANCE].indexOf(props.type) >
              -1 && (
              <div className="field-container type">
                <div className="label">Warranty Type</div>
                <CustomSelect
                  id="warranty-type"
                  handleChange={(item) => {
                    props.setEmptyErrMsg();
                    setWarrantyType(item);
                  }}
                  label="label"
                  options={warrantyOptions}
                  placeholder="Warranty Type"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onFocus={(event) => handleOnFocusById(event.target.id)}
                />
              </div>
            )}
            {props.type === FLOW.SELL_MACHINE && (
              <div className="field-container type">
                <div className="label">Type</div>
                <CustomSelect
                  id="customer-type"
                  handleChange={(item) => {
                    props.setEmptyErrMsg();
                    setType(item);
                  }}
                  label="label"
                  options={typeOptions}
                  placeholder="Customer Type"
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                  onFocus={(event) => handleOnFocusById(event.target.id)}
                />
              </div>
            )}
            {[FLOW.BREAKDOWN, FLOW.PREVENTIVE_MAINTENANCE].indexOf(props.type) >
              -1 && (
              <div className="field-container date-container date">
                <div className="label">Start Date</div>
                <DatePicker
                  className="dob-calendar start-date"
                  onChange={(date) => {
                    props.setEmptyErrMsg();
                    setStartDate(date);
                  }}
                  value={startDate}
                  clearIcon={null}
                  openCalendarOnFocus={true}
                  format="dd-MM-yyyy"
                  showLeadingZeros={false}
                />
              </div>
            )}
            {[FLOW.BREAKDOWN, FLOW.PREVENTIVE_MAINTENANCE].indexOf(props.type) >
              -1 && (
              <div className="field-container date-container date">
                <div className="label">End Date</div>
                <DatePicker
                  className="dob-calendar end-date"
                  onChange={(date) => {
                    props.setEmptyErrMsg();
                    setEndDate(date);
                  }}
                  value={endDate}
                  clearIcon={null}
                  openCalendarOnFocus={true}
                  format="dd-MM-yyyy"
                  showLeadingZeros={false}
                />
              </div>
            )}
            <div className="field-container organization-name">
              <div className="label">Name of Organization</div>
              <input
                className="input"
                id="org-name"
                type="text"
                placeholder="Enter Organization Name"
                onChange={(event) => {
                  props.setEmptyErrMsg();
                  setOrganizationName(event.target.value);
                }}
                onFocus={(event) => handleOnFocusById(event.target.id)}
              />
            </div>
            {[FLOW.SELL_MACHINE, FLOW.INSTALL_ORDER].indexOf(props.type) >
              -1 && (
              <div className="field-container contact-person">
                <div className="label">Contact Person</div>
                <input
                  className="input"
                  id="contact-name"
                  type="text"
                  placeholder="Enter Contact Person"
                  onChange={(event) => {
                    props.setEmptyErrMsg();
                    setContactPerson(event.target.value);
                  }}
                  onFocus={(event) => handleOnFocusById(event.target.id)}
                />
              </div>
            )}
            {[FLOW.SELL_MACHINE, FLOW.INSTALL_ORDER].indexOf(props.type) >
              -1 && (
              <div className="field-container contact-number">
                <div className="label">Contact Number</div>
                <input
                  className="input"
                  id="contact-number"
                  type="number"
                  placeholder="Enter Contact Number"
                  maxLength="10"
                  minLength="10"
                  onChange={(event) => {
                    props.setEmptyErrMsg();
                    setContactNumber(event.target.value);
                  }}
                  onFocus={(event) => handleOnFocusById(event.target.id)}
                />
              </div>
            )}
            {[FLOW.SELL_MACHINE, FLOW.INSTALL_ORDER].indexOf(props.type) >
              -1 && (
              <div className="field-container email">
                <div className="label">Contact Email Id</div>
                <input
                  className="input"
                  id="contact-email"
                  type="email"
                  placeholder="Enter Contact Email Id"
                  onChange={(event) => {
                    props.setEmptyErrMsg();
                    setEmailId(event.target.value);
                  }}
                  onFocus={(event) => handleOnFocusById(event.target.id)}
                />
              </div>
            )}
            <div className="field-container address">
              <div className="label">Address</div>
              <textarea
                className="input address"
                id="address"
                placeholder="Enter Address"
                onChange={(event) => {
                  props.setEmptyErrMsg();
                  setAddress(event.target.value);
                }}
                onFocus={(event) => handleOnFocusById(event.target.id)}
              />
            </div>
            <div className="field-container pincode">
              <div className="label">Pincode</div>
              <input
                className="input"
                id="pincode"
                type="number"
                minLength={6}
                placeholder="Enter Pincode"
                onChange={(event) => {
                  props.setEmptyErrMsg();
                  setPincode(event.target.value);
                }}
                onFocus={(event) => handleOnFocusById(event.target.id)}
              />
            </div>
            <div className="field-container city">
              <div className="label">City</div>
              <input
                className="input"
                id="city"
                type="text"
                placeholder="Enter City"
                onChange={(event) => {
                  props.setEmptyErrMsg();
                  setCity(event.target.value);
                }}
                onFocus={(event) => handleOnFocusById(event.target.id)}
              />
            </div>
            <div className="field-container state">
              <div className="label">State</div>
              <input
                className="input"
                id="state"
                type="text"
                placeholder="Enter State"
                onChange={(event) => {
                  props.setEmptyErrMsg();
                  setState(event.target.value);
                }}
                onFocus={(event) => handleOnFocusById(event.target.id)}
              />
            </div>
          </div>
          <div className="button-container">
            <button
              className={
                isSubmitEnabled
                  ? "submit-button"
                  : "button-disabled submit-button"
              }
              onClick={() => setIsPopUpVisible(true)}
            >
              Next
            </button>
          </div>
        </div>
        {isPopUpVisible && <BackDrop />}
        {isPopUpVisible && (
          <div
            className="confirmation-modal-container"
            id="confirmation-modal-container"
          >
            <div className="confirmation-modal">
              <div className="modal-heading">Confirm Order</div>
              <div className="modal-body">
                <div className="modal-date">
                  Date: {moment(new Date()).format("DD-MM-YYYY")}
                </div>
                {props.type === FLOW.SELL_MACHINE && (
                  <div className="modal-content">
                    {`Are you sure you want to sell ${
                      props.machineList.length
                    } machines of ${
                      props.activeGroup && props.activeGroup.product_name
                    } to ${contactPerson}?`}
                  </div>
                )}
                {[
                  FLOW.BREAKDOWN,
                  FLOW.INSTALL_ORDER,
                  FLOW.PREVENTIVE_MAINTENANCE,
                ].indexOf(props.type) > -1 && (
                  <div className="modal-content">
                    {`Are you sure you want submit the customer details ?`}
                  </div>
                )}
              </div>
              <div className="modal-cta-container">
                <div
                  className="cancel-container cta"
                  onClick={() => setIsPopUpVisible(false)}
                >
                  No
                </div>
                <div
                  className="confirm-container cta"
                  onClick={() => handleSubmit()}
                >
                  Yes
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
