import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

export const activeDataReducer = (state = initialState.activeDataReducer, action) => {
    switch (action.type) {
        case Types.ACTIVE_GROUP:
            return { ...state, activeGroup: action.payload };
        case Types.ACTIVE_MACHINE:
            return { ...state, activeMachine: action.payload };
        default:
            return state;
    }
}