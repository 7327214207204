
import "./ToggleSwitch.scss";
import React, { useState } from "react";

function ToggleSwitch(props) {
    return (
        <div className="switch-container" id={props.id}>
            <label className="switch">
                <input type="checkbox" onChange={props.handleSliderClick} checked={props.checked} />
                <span className="slider round"></span>
            </label>
        </div >
    )
}
export default ToggleSwitch;

