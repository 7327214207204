import React, { useState } from "react";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

import "./InstallMachineSubStage.scss";

export function InstallMachineSubStage(props) {
  const [data, setData] = useState(props.data);

  const handleDataUpdate = (id, value) => {
    console.log("Inside handleDataUpdate", id, value);
    let obj = data && data.filter((item) => item.id === id)[0];

    if (obj) {
      if (obj.type === "TEST") {
        obj.test_response = value;
      } else if (obj.type === "INPUT_FIELD") {
        obj.input_field_response = value;
      }
    }

    console.log("Inside Data ==>", data);

    setData([...data]);
  };

  const checkifFieldVisible = (fieldData) => {
    console.log(fieldData);

    if (!fieldData.condition) return true;

    let id = fieldData.condition_type === "ID" && fieldData.condition;

    let dependentField =
      data && data.filter((item) => item.stage_detail_id == id)[0];

    if (!dependentField) return true;

    if (
      dependentField.test_type === "YES_NO" &&
      dependentField.test_response === fieldData.condition_value
    ) {
      return true;
    } else if (
      dependentField.type === "INPUT_FIELD" &&
      dependentField.input_field_response === fieldData.condition_value
    ) {
      return true;
    }

    fieldData.test_response = null;
    fieldData.input_field_response = null;
    return false;
  };

  const getHtml = () => {
    console.log("Inside getHtml :", data);
    return (
      data &&
      data.map((data) => {
        return (
          <div className="query-container" key={data.id}>
            {data.type === "TEST" &&
              data.test_type === "YES_NO" &&
              checkifFieldVisible(data) && (
                <div className="double-checkbox-container">
                  <div className="query-name">{data.test_label}</div>
                  <div className="radio-container">
                    <div className="radio-button">
                      <input
                        className="radio yes"
                        name={data.id}
                        checked={data.test_response === "YES"}
                        type="radio"
                        onChange={() => handleDataUpdate(data.id, "YES")}
                      />
                      <label className="label">Yes</label>
                    </div>
                    <div className="radio-button">
                      <input
                        className="radio no"
                        name={data.id}
                        checked={data.test_response === "NO"}
                        type="radio"
                        onChange={() => handleDataUpdate(data.id, "NO")}
                      />
                      <label className="label">No</label>
                    </div>
                  </div>
                </div>
              )}
            {data.type === "INPUT_FIELD" &&
              data.input_field_type === "NORMAL" &&
              checkifFieldVisible(data) && (
                <div className="input-container">
                  <label className="label">{data.input_field_label}</label>
                  <input
                    className="input"
                    id={data.input_field_placeholder}
                    placeholder={data.input_field_placeholder}
                    onFocus={(event) => handleOnFocusById(event.target.id)}
                    onChange={(event) =>
                      handleDataUpdate(data.id, event.target.value)
                    }
                  />
                </div>
              )}
            {data.type === "INPUT_FIELD" &&
              data.input_field_type === "DROPDOWN" &&
              checkifFieldVisible(data) && (
                <div className="dropdown-container">
                  <div className="label">{data.input_field_label}</div>

                  <CustomSelect
                    id="customer-type"
                    handleChange={(item) =>
                      handleDataUpdate(data.id, item.label)
                    }
                    label="label"
                    options={data.input_field_values}
                    placeholder={data.input_field_placeholder}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                    onFocus={(event) => handleOnFocusById(event.target.id)}
                  />
                </div>
              )}
            {data.type === "INPUT_FIELD" &&
              data.input_field_type === "TEXT_AREA" &&
              checkifFieldVisible(data) && (
                <div className="textarea-container">
                  <textarea
                    className="textarea"
                    type="text"
                    rows="4"
                    id="text-area"
                    placeholder={data.input_field_placeholder}
                    onKeyPress={(event) => {
                      if (event && event.key === "Enter" && !event.shiftKey) {
                        console.log(event.target.value);
                      }
                    }}
                    onFocus={(event) => handleOnFocusById(event.target.id)}
                    onChange={(event) =>
                      handleDataUpdate(data.id, event.target.value)
                    }
                  ></textarea>
                </div>
              )}
          </div>
        );
      })
    );
  };

  return (
    <div className="install-machine-substage-container">
      <div className="install-machine-substage-name">
        {props.activeSubStage}
      </div>
      {props.metaData && (
        <div className="substage-metadata">
          {/* metaData */}
          {props.metaData.map((item) => {
            return (
              <div className="meta-item">
                <div className="value">{item.value}</div>
                <div className="name">{item.key}</div>
              </div>
            );
          })}
        </div>
      )}
      <div className="install-machine-substage-details">{getHtml()}</div>
      <div className="install-machine-substage-footer">
        <div
          className="submit-container"
          onClick={() => props.onSubmitHandler(data)}
        >
          Submit
        </div>
        <div className="next-container" onClick={() => props.onNextHandler()}>
          Next
        </div>
      </div>
    </div>
  );
}
