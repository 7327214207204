import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

export const activeOrderReducer = (state = initialState.activeDataReducer, action) => {
    switch (action.type) {
        case Types.BREAKDOWN_ORDER:
            return { ...state, breakDownOrder: action.payload };
        case Types.PREVENTIVE_MAINTAINNCE_ORDER:
            return { ...state, preventiveMaintainanceOrder: action.payload };
        case Types.COMPLAINT_FLOW:
            return { ...state, complaintFlow: action.payload };
        default:
            return state;
    }
}