import React, { useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";
import ImageUploader from "react-images-upload";
import { handleOnFocusById } from "../../common/utils/CommonUtils";
import "./Observation.scss";

export function Observation(props) {
    const [pictures, setPictures] = useState("");
    const [issueObserved, setIssueObserved] = useState(null);
    const [uiVersion, setUiVersion] = useState("");
    const [firmwareVersion, setFirmwareVersion] = useState("");
    const [otherIssue, setOtherIssue] = useState("");
    const [physicalDamage, setPhysicalDamage] = useState("");

    const onDrop = (pictureFiles, pictureDataURLs) => {

        console.log(pictureFiles);
        setPictures(pictureFiles);
    }

    const handleOnSubmit = () => {
        let data = {
            pictures: pictures,
            issueObserved: issueObserved && issueObserved.map((item) => item.id),
            uiVersion: uiVersion,
            firmwareVersion: firmwareVersion,
            otherIssue: otherIssue,
            physicalDamage: physicalDamage
        }

        console.log(data);

        props.handleSubmit(data);
    }

    return (
        <div className="observation-container">
            <div className="observation-heading">
                Observations
            </div>
            <div className="observation-form">
                <div className="form-heading">
                    Software Checks
                </div>
                <div className="form-body">
                    <input
                        className="input ui-version"
                        id="ui-version"
                        placeholder="UI Version"
                        onChange={(event) => setUiVersion(event.target.value)}
                        type="text"
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                    <input
                        className="input firmware-version"
                        id="firmware-v"
                        placeholder="Firmware Version"
                        onChange={(event) => setFirmwareVersion(event.target.value)}
                        type="text"
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                    <div className="double-checkbox-container">
                        <div className="query-name">
                            Any physical Damage
                        </div>
                        <div className="radio-container">
                            <div className="radio-button">
                                <input
                                    className="radio yes"
                                    name="physical-damage"
                                    type="radio"
                                    checked={physicalDamage === true}
                                    onChange={() => setPhysicalDamage(true)}
                                />
                                <label className="radio-label">Yes</label>
                            </div>
                            <div className="radio-button">
                                <input
                                    className="radio no"
                                    name="physical-damage"
                                    type="radio"
                                    checked={physicalDamage === false}
                                    onChange={() => setPhysicalDamage(false)}
                                />
                                <label className="radio-label">No</label>
                            </div>
                        </div>
                    </div>
                    <CustomSelect
                        id="issue-observed"
                        handleChange={(item) => setIssueObserved(item)}
                        label='description'
                        options={props.observationIssuesOptions}
                        placeholder='Issue Observed'
                        isMulti={true}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        onFocus={(event) => handleOnFocusById(event.target.id)}
                    />
                    {issueObserved && issueObserved.filter((item) => item.name.toLowerCase() === "other").length > 0 &&
                        <input
                            className="input drop-down-other"
                            id="issue-detail"
                            placeholder="Enter Other Issue Detail"
                            onChange={(event) => setOtherIssue(event.target.value)}
                            onFocus={(event) => handleOnFocusById(event.target.id)}
                        />}
                    <ImageUploader
                        withIcon={true}
                        withPreview={true}
                        className="image-uploader"
                        singleImage={true}
                        buttonText="Choose images"
                        onChange={(pictureFiles, pictureDataURLs) => onDrop(pictureFiles, pictureDataURLs)}
                        imgExtension={[".jpg", "jpeg", ".png"]}
                        maxFileSize={5242880}
                    />
                </div>
            </div>
            <div className="cta-container">
                <div className="save-container">
                    Save
                </div>
                <div className="submit-container" onClick={() => handleOnSubmit()}>
                    Submit
                </div>
            </div>
        </div>
    )
}