import "./AddTeamMember.scss";
import React, { useEffect, useState } from "react";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { redirect } from "../../common/utils/RedirectionUtils";
import {
  MY_TEAM_CONST,
  PAGE_CONST,
} from "../../common/constant/page-constants";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import BackDrop from "../../components/BackDrop/BackDrop";
import Modal from "../../components/Modal/Modal";
import { addTeamMember } from "../../helper/user-helper";
import moment from "moment";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

function AddTeamMember() {
  const navigate = useNavigate();
  const [activeInput, setActiveInput] = useState();
  const [showModal, setShowModal] = useState(false);
  const [formName, setFormName] = useState("");
  const [formNumber, setFormNumber] = useState("");
  const [formRole, setFormRole] = useState("");
  const [showformDesgination, setShowFormDesignation] = useState(false); // show Form Desgination
  const [formDesgination, setFormDesignation] = useState("");

  const [dataContainer, setDataContainer] = useState();
  const [errorMsg, setErrorMsg] = useState(null);

  const headerConf = {
    heading: "Add Member",
  };

  const handleHeaderBackClick = () => {
    redirect(navigate, PAGE_CONST.MY_TEAM);
  };

  const handleAddTeamMemberClick = () => {
    setShowModal(true);
    console.log("add team member button was clicked");
  };

  // Role Options Arr
  const optionsArr = [
    { name: "Admin", label: "ADMIN" },
    { name: "Team Member", label: "TEAM_MEMBER" },
  ];

  // Designation Arr
  const designationOptionsArr = [
    { name: "Service Engineer", label: "SERVICE_ENGINEER" },
    { name: "Sales Executive", label: "SALES_EXECUTIVE" },
    { name: "Account Manager", label: "ACCOUNT_MANAGER" },
  ];

  const handleSelectChangeForDesignation = (item) => {
    if (formRole === "TEAM_MEMBER") {
      setFormDesignation(item);
    }
  };

  const handleSelectChange = (item) => {
    console.log(item, "this item is selected");

    const roleItem = optionsArr.find((option) => option.label === item);

    if (roleItem) {
      // set form role
      setFormRole(item);

      // show designatiom if team is Team Member
      setShowFormDesignation(item === "TEAM_MEMBER");

      if (item == "ADMIN") {
        setFormDesignation("");
      }
    }
  };

  const handleFunctionBtnClick = () => {
    // setTimeout(() => {
    //     redirect(navigate, PAGE_CONST.MY_TEAM);
    //     setShowModal(false);
    // }, 70);

    let formObj = {
      name: formName,
      mobile_no: formNumber,
      role: formRole,
      designation: formDesgination,
    };

    console.log("formObj : ", formObj);

    setShowModal(false);
    if (formObj) {
      addTeamMember(formObj)
        .then((res) => {
          setDataContainer(res);
          redirect(navigate, PAGE_CONST.MY_TEAM);
        })
        .catch((err) => {
          setErrorMsg(err);
          console.log("machine err: ", err);
        });
    }
  };

  const handleModalCancelClick = () => {
    // setTimeout(() => {
    //     setShowModal(false);
    // }, 70);
    setShowModal(false);
  };

  const addDiv = () => {
    let tempDiv = (
      <div className="modal-content">
        <div className="modal-heading">Add New Team Member</div>
        <div className="modal-date">
          Date: {moment(new Date()).format("DD-MM-YYYY")}
        </div>
        <div className="modal-declaration">
          Are you sure you want to add {formName} user to the team?
        </div>
      </div>
    );
    return tempDiv;
  };

  return (
    <div className="add-team-member-body">
      <Header
        headerConf={headerConf}
        handleBackClick={() => handleHeaderBackClick()}
      />
      <div className="input-container">
        {/* Name */}
        <div className="common-input-class">
          <label>Name</label>
          <input
            className="input name-input common-input-ele"
            id="name-input"
            placeholder={activeInput === "NAME" ? "" : "Name"}
            onChange={(event) => {
              setErrorMsg(null);
              setFormName(event.target.value);
            }}
            type="text"
            onFocus={(event) => handleOnFocusById(event.target.id)}
          />
        </div>

        {/* Number */}
        <div className="common-input-class">
          <label>Number</label>
          <input
            className="input phone-number-input common-input-ele"
            id="number-input"
            placeholder={activeInput === "NUMBER" ? "" : "Number"}
            onChange={(event) => {
              setErrorMsg(null);
              setFormNumber(event.target.value);
            }}
            type="number"
            maxLength={10}
            minLength={10}
            onFocus={(event) => handleOnFocusById(event.target.id)}
          />
        </div>

        {/* Role */}
        <div className="common-input-class">
          <label>Role</label>
          <div className="role-select-input common-input-ele">
            <CustomSelect
              options={optionsArr}
              id="select-input"
              isClearable={false}
              label="role-select"
              placeholder="Role"
              components={{
                IndicatorSeparator: () => null,
              }}
              handleChange={(item) => {
                setErrorMsg(null);
                handleSelectChange(item.label);
              }}
              onFocus={(event) => handleOnFocusById(event.target.id)}
              // borderStyle="none"
            />
          </div>
        </div>

        {/* Designation */}
        {showformDesgination && (
          <div className="common-input-class">
            <label>DESIGNATION</label>
            <div className="role-select-input common-input-ele">
              <CustomSelect
                options={designationOptionsArr}
                id="select-input"
                isClearable={false}
                label="role-select"
                placeholder="Designation"
                components={{
                  IndicatorSeparator: () => null,
                }}
                handleChange={(item) => {
                  setErrorMsg(null);
                  handleSelectChangeForDesignation(item.label);
                }}
                onFocus={(event) => handleOnFocusById(event.target.id)}
                // borderStyle="none"
              />
            </div>
          </div>
        )}

        {/* Error Message */}
        <div className="error-msg">{errorMsg}</div>
      </div>

      <div className="add-member-btn" onClick={handleAddTeamMemberClick}>
        Add Team Member
      </div>
      {showModal && <BackDrop />}
      {showModal && (
        <Modal
          html={addDiv()}
          functionBtnText="Add"
          handleModalCancelClick={handleModalCancelClick}
          handleFunctionBtnClick={handleFunctionBtnClick}
        />
      )}
    </div>
  );
}
export default AddTeamMember;
