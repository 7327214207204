import "./UserProfile.scss"
import React, { useEffect, useState } from "react";
import userNameSvg from "../../common/icons/svg/user-name-icon.svg";
import logoutSvg from "../../common/icons/svg/logout-arrow.svg";
import myTeamSvg from "../../common/icons/svg/my-team.svg";
import termsAndConditionsSvg from "../../common/icons/svg/terms-and-conditions.svg";
import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { redirect } from "../../common/utils/RedirectionUtils";
import { FOOTER, MY_TEAM_CONST, PAGE_CONST } from "../../common/constant/page-constants";
import { connect } from "react-redux";
import { logout } from "../../helper/loginHelper";

function UserProfile(props) {
    const navigate = useNavigate();
    const headerConf = {
        heading: "User Profile",
        hideBackIcon: true
    }
    const handleMyTeamClick = () => {
        redirect(navigate, PAGE_CONST.MY_TEAM)

    }

    const handleLogoutClick = () => {

        logout()
            .then((res) => {
                console.log(res);
                redirect(navigate, PAGE_CONST.LOGIN);
            }).catch((err) => {
                console.log(err);
            })
    }

    return (
        <div className="user-profile-body">
            <Header headerConf={headerConf} />

            <div className="user-info-container">
                <div className="user-name-icon">
                    <img src={userNameSvg} />
                </div>
                <div className="user-name-class">{props.userData && props.userData.name}</div>
                <div className="org-info-class">{props.userData && props.userData.organization_name}</div>
            </div>

            <div className="actions-container">
                {([MY_TEAM_CONST.GROUP.TEAM].indexOf(props.userData && props.userData.role) < 0) && <div className="user-team-container common-cls" onClick={handleMyTeamClick}>
                    <img src={myTeamSvg}></img>
                    <span>My Team</span>
                </div>}
                <div className="terms-container common-cls">
                    <img src={termsAndConditionsSvg}></img>
                    <span>Terms and Conditions</span>
                </div>
                <div className="logout-container common-cls" onClick={() => handleLogoutClick()}>
                    <img src={logoutSvg}></img>
                    <span> Logout</span>
                </div>
            </div>
            <Footer activeStage={FOOTER.USER} />
        </div>
    )
}

const mapStateToProps = (state) => {

    return {
        userData: state.userDataReducer.userData
    }
}
export default connect(mapStateToProps, null)(UserProfile);
