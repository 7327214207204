import "./ComplaintIssueForm.scss"
import { Header } from "../Header/Header";
import { useState } from "react";

function ComplaintIssueForm() {
    const [textareaActive, setTextareaActive] = useState(false);
    const handleTextareaClick = () => {
        setTextareaActive(true)
    }
    const handleSubmitBtn = () => {
        console.log("submit button was clicked!!!");
    }
    const headerConf = {
        heading: "Service Management"
    }

    return (
        <div className="form-component">
            <Header headerConf={headerConf} />
            <div className="machine-name">PNQ3546787654</div>
            <div className="form-container">
                <form className="form-detail">
                    <div className="issue-textarea">
                        {textareaActive && <label>Issue</label>}
                        <textarea className="issue-desc-text" placeholder="Issue" onClick={handleTextareaClick} />
                    </div>
                    <div className="radio-container">
                        <div className="common-radio">
                            <input type="radio" name="issue-radio" id="product-issue-radio" />
                            <label htmlFor="product-issue-radio">Product Issue</label>
                        </div>
                        <div className="common-radio">
                            <input type="radio" name="issue-radio" id="other-issue-radio" />
                            <label htmlFor="other-issue-radio">Other Issue</label>
                        </div>
                    </div>
                    <div className="submit-btn" onClick={handleSubmitBtn}>Submit</div>
                </form>
            </div>
        </div>
    )
}
export default ComplaintIssueForm;