import {
  addInstallCustomerAPI,
  getInstallationStageDetailsAPI,
  getOrCreateInstallOrderAPI,
  sendInstallOtp,
  updateInstallationStageDetailsAPI,
  verifyInstallOtp,
} from "../adapter/service-adapter";

export const getOrCreateInstallOrder = (data) => {
  let reqData = {
    machine_serial_id: data.id,
  };

  let promise = new Promise(function (resolve, reject) {
    getOrCreateInstallOrderAPI(reqData).then((res) => {
      // console.log("send otp then:: " + JSON.stringify(res));

      if (res && res.data && res.data.status === 200) {
        resolve(res.data.data);
      } else {
        if (res && res.data && res.data.error) reject(res.data.error.message);
        else reject("error occured!!");
      }
    });
  });

  return promise;
};

export const addCustomer = (data) => {
  let reqData = {
    install_order_id: data.orderId,
    customer: {
      contact_number: data.customer.contactNumber,
      contact_email: data.customer.contactMail,
      organization_name: data.customer.organizationName,
      contact_person: data.customer.contactPerson,
      address: data.customer.address,
      pincode: data.customer.pincode,
      city: data.customer.city,
      state: data.customer.state,
    },
  };

  let promise = new Promise(function (resolve, reject) {
    addInstallCustomerAPI(reqData).then((res) => {
      // console.log("send otp then:: " + JSON.stringify(res));

      if (res && res.data && res.data.status === 200) {
        resolve(res.data.data);
      } else {
        if (res && res.data && res.data.error) reject(res.data.error.message);
        else reject("error occured!!");
      }
    });
  });

  return promise;
};

export const getSubStageDetails = (data) => {
  let reqData = {
    install_order_id: data.orderId,
    stage_id: data.subStageId,
  };

  let promise = new Promise(function (resolve, reject) {
    getInstallationStageDetailsAPI(reqData).then((res) => {
      if (res && res.data && res.data.status === 200) {
        resolve(res.data.data);
      } else {
        if (res && res.data && res.data.error) reject(res.data.error.message);
        else reject("error occured!!");
      }
    });
  });

  return promise;
};

export const updateSubStageDetails = (data) => {
  let reqData = {
    install_order_id: data.orderId,
    stage_id: data.subStageId,
    stage_detail_data: data.stageData,
  };

  let promise = new Promise(function (resolve, reject) {
    updateInstallationStageDetailsAPI(reqData).then((res) => {
      if (res && res.data && res.data.status === 200) {
        resolve(res.data.data);
      } else {
        if (res && res.data && res.data.error) reject(res.data.error.message);
        else reject("error occured!!");
      }
    });
  });

  return promise;
};
