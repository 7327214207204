export const Types = {
    SAVE_MY_MACHINES: "SAVE_MY_MACHINES",
    SAVE_MACHINE_GROUP: "SAVE_MACHINE_GROUP",
    ACTIVE_GROUP: "ACTIVE_GROUP",
    ACTIVE_MACHINE: "ACTIVE_MACHINE",
    BREAKDOWN_ORDER: "BREAKDOWN_ORDER",
    PREVENTIVE_MAINTAINNCE_ORDER: "PREVENTIVE_MAINTAINNCE_ORDER",
    COMPLAINT_FLOW: "COMPLAINT_FLOW",
    USER_DATA: "USER_DATA",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
};
