import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Footer } from "../../components/Footer/Footer";
import { Header } from "../../components/Header/Header";
import { MachineGroupCard } from "../../components/MachineGroupCard/MachineGroupCard";

import { FOOTER, MY_MACHINE_CONST, PAGE_CONST } from "../../common/constant/page-constants";
import { redirect } from "../../common/utils/RedirectionUtils";

import moreItemSvg from "../../common/icons/svg/more-item.svg";
import crossFilledSvg from "../../common/icons/svg/cross-filled.svg";
import sellMachineSvg from "../../common/icons/svg/sell-machine.svg";
import installMachineSvg from "../../common/icons/svg/install-machine.svg";
import jobCardSvg from "../../common/icons/svg/job-card.svg";
import maintenanceSvg from "../../common/icons/svg/maintenance.svg";

import "./MyMachine.scss";
import { getMyMachines } from "../../helper/machineHelper";
import { connect } from "react-redux";
import { activeGroupAction, myMachinesAction } from "../../modules/actions/action";
import BackDrop from "../../components/BackDrop/BackDrop";


function MyMachine(props) {
    const navigate = useNavigate();
    const headerConf = {
        heading: 'My Machines',
        hideBackIcon: true
    }

    const [activeItem, setActiveItem] = useState(MY_MACHINE_CONST.MACHINE);
    const [isMoreItemsVisible, setIsMoreItemsVisibe] = useState(false);
    const [myMachineList, setMyMachineList] = useState([]);

    useEffect(() => {

        getMyMachines()
            .then((res) => {

                if (res && res.statistics) props.myMachinesAction(res.statistics);
                // console.log("machine res: ", res);
            }).catch((err) => {
                console.log("machine err: ", err);
            })
    }, [])

    const handleHeaderClick = (item) => {

        setActiveItem(item);
    }

    const handleCardClick = (data) => {

        props.activeGroupAction(data)
        redirect(navigate, PAGE_CONST.MACHINE_GROUP);
    }

    const handleMoreIconClick = (event) => {
        if (!isMoreItemsVisible) {
            event.stopPropagation();
            setIsMoreItemsVisibe(true);
            let element = document.getElementById('more-icon-container');

            window.addEventListener('click', function (e) {
                if (element && element.contains(e.target)) {

                } else {
                    setIsMoreItemsVisibe(false);
                }
            });
            return;
        }
    }

    return (
        <>
            <div className={isMoreItemsVisible ? "my-machine-container no-pointer-events" : "my-machine-container"}>
                <Header headerConf={headerConf} />
                <div className="my-machine-content-container">
                    <div className="my-machine-content-header">
                        <div
                            className={activeItem === MY_MACHINE_CONST.MACHINE ?
                                "my-machine-header machine active-header" : "my-machine-header machine"}
                            onClick={() => handleHeaderClick(MY_MACHINE_CONST.MACHINE)}>
                            Machines
                        </div>
                        <div
                            className={activeItem === MY_MACHINE_CONST.SPARE ?
                                "my-machine-header spare active-header" : "my-machine-header spare"}
                            onClick={() => handleHeaderClick(MY_MACHINE_CONST.SPARE)}>
                            Spares
                        </div>
                    </div>
                    {activeItem === MY_MACHINE_CONST.MACHINE && <div className="my-machine-content-data">
                        {
                            props.myMachines.map((data) => {
                                return (
                                    <div key={data.product_id}>
                                        <MachineGroupCard
                                            data={data}
                                            onClick={(data) => handleCardClick(data)}
                                        />
                                    </div>);
                            })
                        }
                    </div>}
                </div>
                <Footer activeStage={FOOTER.HOME} />
            </div>
            {/* {isMoreItemsVisible && <BackDrop />}
            <div className="more-icon-container" onClickCapture={(event) => handleMoreIconClick(event)}>
                {isMoreItemsVisible &&
                    <div className="more-menu-items">
                        <div className="sell-machine more-menu-item">
                            <div className="menu-item-text">Sell Machine</div>
                            <div className="more-item-icon">
                                <img
                                    src={sellMachineSvg}
                                    className="sell-machine-icon"
                                    alt="sell machine icon" />
                            </div>
                        </div>
                        <div className="install-machine more-menu-item">
                            <div className="menu-item-text">Install Machine</div>
                            <div className="more-item-icon">
                                <img
                                    src={installMachineSvg}
                                    className="install-machine-icon"
                                    alt="install machine icon" />
                            </div>
                        </div>
                        <div className="job-card more-menu-item">
                            <div className="menu-item-text">Job Card</div>
                            <div className="more-item-icon">
                                <img
                                    src={jobCardSvg}
                                    className="job-card-icon"
                                    alt="job card icon" />
                            </div>
                        </div>
                        <div className="job-card more-menu-item">
                            <div className="menu-item-text">Preventive Maintenance</div>
                            <div className="more-item-icon">
                                <img
                                    src={maintenanceSvg}
                                    className="maintenance-icon"
                                    alt="maintenance icon" />
                            </div>
                        </div>
                    </div>}
                <div className="more-icon" onClick={() => setIsMoreItemsVisibe(!isMoreItemsVisible)}>
                    {!isMoreItemsVisible && <img src={moreItemSvg} className="more-item-icon" alt="more item icon" />}
                    {isMoreItemsVisible && <img src={crossFilledSvg} className="cross-item-icon" alt="cross item icon" />}
                </div>
            </div> */}
        </>
    )
}

const mapStateToProps = state => {

    return {
        myMachines: state.myMachineDataReducer.myMachines || []
    };
};

const mapDispatchToProps = dispatch => {
    return {
        myMachinesAction: (payload) => dispatch(myMachinesAction(payload)),
        activeGroupAction: (payload) => dispatch(activeGroupAction(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MyMachine);