import { kycAPI } from "../adapter/service-adapter";

// export const kyc = (data) => {
//   console.log(data);
//   let promise = new Promise(function (resolve, reject) {
//     kycAPI(data).then((res) => {
//       console.log("KYC UPDATE>>>", res.data);
//       if (res && res.status === 200) {
//         resolve(res.data);
//       } else {
//         reject("error occured!!");
//       }
//     });
//   });

//   return promise;
// };

export const kyc = (data) => {
  console.log("KYC UPDATE>>>", data);
  let promise = new Promise(function (resolve, reject) {
    kycAPI(data).then((res) => {
      console.log("kycStatus>>>", res.data);
      if (res && res.status === 200 || res.status === 201) {
        resolve(res);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};