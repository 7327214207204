import axios from "axios";
import "../common/axiosInterceptor/axios-interceptor";

const BASE_URL = "https://d3pzwhdbyct6r1.cloudfront.net/api/";
// const BASE_URL = "http://127.0.0.1:8000/api/";
export const sendOtpAPI = async (data) => {
  return axios({
    method: "post",
    url: `${BASE_URL}login/sendOTP`,
    data: data,
  });
};

export const verifyOtpAPI = async (data) => {
  return axios({
    method: "post",
    url: `${BASE_URL}login/verifyOTP`,
    data: data,
  });
};

export const logoutAPI = async (data) => {
  return axios({
    method: "post",
    url: `${BASE_URL}users/logout`,
    data: data,
    authRequired: true,
  });
};

export const getMachineDetailsBySerialIdAPI = (data) => {
  return axios({
    method: "post",
    url: `${BASE_URL}getMachineDetailsBySerialId`,
    data: data,
    authRequired: true,
  });
};

export const getMachineStatisticsAPI = async (data) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}getMachineStatistics`,
    data: data,
    authRequired: true,
  });
};

export const getMachinesAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getMachines`,
    data: data,
    authRequired: true,
  });
};

export const sellMachinesAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}sellMachines`,
    data: data,
    authRequired: true,
  });
};

export const getOrCreateInstallOrderAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getOrCreateInstallOrder`,
    data: data,
    authRequired: true,
  });
};

export const addInstallCustomerAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}installOrder/addCustomer`,
    data: data,
    authRequired: true,
  });
};

export const getInstallationStageDetailsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}installOrder/getInstallationReportStageDetails`,
    data: data,
    authRequired: true,
  });
};

export const updateInstallationStageDetailsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}installOrder/updateInstallationReportStageDetails`,
    data: data,
    authRequired: true,
  });
};

export const createBreakdownOrderAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}createBreakdownOrder`,
    data: data,
    authRequired: true,
  });
};

export const getBreakdownOrderAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getBreakdownOrder`,
    data: data,
    authRequired: true,
  });
};

export const addBreakDownCustomerDetailAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}breakdownOrder/addCustomerDetail`,
    data: data,
    authRequired: true,
  });
};

export const getMachineDetailsBySerialNameAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getMachineDetailsBySerialNameAPI`,
    data: data,
    authRequired: true,
  });
};

export const getObservationIssuesAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getProductBreakdownIssues`,
    data: data,
    authRequired: true,
  });
};

export const uploadFileAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}uploadFile`,
    data: data,
    authRequired: true,
  });
};

export const createObservationsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}breakdownOrder/createObservations`,
    data: data,
    authRequired: true,
  });
};

export const getComponentTypesAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getComponentTypes`,
    data: data,
    authRequired: true,
  });
};

export const addBreakdownOrderBillOfMaterialAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}breakdownOrder/addBillOfMaterial`,
    data: data,
    authRequired: true,
  });
};

export const addPMBillOfMaterialAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addBillOfMaterial`,
    data: data,
    authRequired: true,
  });
};

export const getBreakdownOrderBillOfMaterialsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}breakdownOrder/getBillOfMaterials`,
    data: data,
    authRequired: true,
  });
};

export const getPMBillOfMaterialsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/getBillOfMaterials`,
    data: data,
    authRequired: true,
  });
};

export const addFieldReturnAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}breakdownOrder/addFieldReturn`,
    data: data,
    authRequired: true,
  });
};

export const addPreventiveFieldReturnAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addFieldReturn`,
    data: data,
    authRequired: true,
  });
};

export const addRemarkAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}breakdownOrder/addRemark`,
    data: data,
    authRequired: true,
  });
};

export const addPMRemarkAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addRemark`,
    data: data,
    authRequired: true,
  });
};

export const updateMachineStatusAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/updateMachineStatus`,
    data: data,
    authRequired: true,
  });
};

export const sendFlowOtpAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}sendOTP`,
    data: data,
    authRequired: true,
  });
};

export const verifyFlowOtpAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}verifyOTP`,
    data: data,
    authRequired: true,
  });
};

export const getUserTeamMemberAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}users/getTeamMembers`,
    data: data,
    authRequired: true,
  });
};

export const addTeamMemberAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}users/addTeamMember`,
    data: data,
    authRequired: true,
  });
};

export const updateTeamMemberAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}users/updateTeamMember`,
    data: data,
    authRequired: true,
  });
};

/***********
 *
 */
export const createPreventiveMaintenanceOrderAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}createPreventiveMaintenanceOrder`,
    data: data,
    authRequired: true,
  });
};

export const getPreventiveMaintenanceOrderAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getPreventiveMaintenanceOrder`,
    data: data,
    authRequired: true,
  });
};

export const addPreventiveMaintenanceCustomerDetailAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addCustomerDetail`,
    data: data,
    authRequired: true,
  });
};

export const getPreventiveMaintenanceChecklistStageDetailsAPI = async (
  data
) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/getPreventiveMaintenanceChecklistStageDetails`,
    data: data,
    authRequired: true,
  });
};

export const updatePreventiveMaintenanceChecklistStageDetailsAPI = async (
  data
) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/updatePreventiveMaintenanceChecklistStageDetails`,
    data: data,
    authRequired: true,
  });
};

export const getModeTestingStageDetailsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/getModeTestingStageDetails`,
    data: data,
    authRequired: true,
  });
};

export const updateModeTestingStageDetailsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/updateModeTestingStageDetails`,
    data: data,
    authRequired: true,
  });
};

export const addPreventiveMaintenanceBillOfMaterialAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addBillOfMaterials`,
    data: data,
    authRequired: true,
  });
};

export const getPreventiveMaintenanceBillOfMaterialsAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/getBillOfMaterials`,
    data: data,
    authRequired: true,
  });
};

export const addPreventiveMaintenanceFieldReturnAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addFieldReturn`,
    data: data,
    authRequired: true,
  });
};

export const addPreventiveMaintenanceRemarkAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}preventiveMaintenanceOrder/addRemark`,
    data: data,
    authRequired: true,
  });
};

export const getJobCardAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}getJobCards`,
    data: data,
    authRequired: true,
  });
};

export const registerSellerAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}registerSeller`,
    data: data,
    authRequired: false,
  });
};

export const sellerExperienceTerritoryAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}sellerExperienceTerritory`,
    data: data,
    authRequired: true,
  });
};

export const ProductsAndCompanyAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}productsAndCompany`,
    data: data,
    authRequired: true,
  });
};

export const kycAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}kyc`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
    authRequired: true,
  });
};

export const fetchSellerAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}fetchSeller`,
    data: data,
    authRequired: true,
  });
};

export const UpdateSellerDetailsAPI = async (data) => {
  return axios({
    method: "PUT",
    url: `${BASE_URL}UpdateSellerDetails`,
    data: data,
    authRequired: true,
  });
};

export const userStatusAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}userStatus`,
    data: data,
    authRequired: true,
  });
};

export const UpdateSellerExperienceTerritoryAPI = async (data) => {
  return axios({
    method: "PUT",
    url: `${BASE_URL}UpdateSellerExperienceTerritory`,
    data: data,
    authRequired: true,
  });
};

export const fetchSellerExperienceTerritoryAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}fetchSellerExperienceTerritory`,
    data: data,
    authRequired: true,
  });
};

export const UpdateProductsAndCompanyAPI = async (data) => {
  return axios({
    method: "PUT",
    url: `${BASE_URL}UpdateProductsAndCompany`,
    data: data,
    authRequired: true,
  });
};

export const RetrieveProductsAndCompanyAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}RetrieveProductsAndCompany`,
    data: data,
    authRequired: true,
  });
};

export const UpdateKYCAPI = async (data) => {
  return axios({
    method: "PUT",
    url: `${BASE_URL}UpdateKYC`,
    data: data,
    authRequired: true,
  });
};

export const RetrieveKYCAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}RetrieveKYC`,
    data: data,
    authRequired: true,
  });
};

export const SubmitForReviewAPI = async (data) => {
  return axios({
    method: "POST",
    url: `${BASE_URL}SubmitForReview`,
    data: data,
    authRequired: true,
  });
};
