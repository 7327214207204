import BreakDownCall from "../../pages/BreakDownCall/BreakDownCall";
import InstallMachine from "../../pages/InstallMachine/InstallMachine";
import Login from "../../pages/Login/Login";
import MachineDetail from "../../pages/MachineDetail/MachineDetail";
import MachineGroup from "../../pages/MachineGroup/MachineGroup";
import MyMachine from "../../pages/MyMachine/MyMachine";
import SellMachine from "../../pages/SellMachine/SellMachine";
import UserProfile from "../../pages/UserProfile/UserProfile";
import MyTeam from "../../pages/MyTeam/MyTeam";
import AddTeamMember from "../../pages/AddTeamMember/AddTeamMember";
import ComplaintIssueForm from "../../components/ComplaintIssueForm/ComplaintIssueForm";
import ComplaintAttachment from "../../components/ComplaintAttachment/ComplaintAttachment";
import RCAForm from "../../components/RCAForm/RCAForm";
import PreventiveMaintenanceCheckForm from "../../components/PreventiveMaintenanceCheckForm/PreventiveMaintenanceCheckForm";
import PreventiveMaintenance from "../../pages/PreventiveMaintenance/PreventiveMaintenance";
import ServiceManagement from "../../pages/ServiceManagement/ServiceManagement";
import PersonalDetail from "../../pages/DetailPage/PersonalDetail";
import MyAccount from "../../pages/My Account/MyAccount";
import ExperienceandTerritory from "../../pages/ExperienceAndTerritory/ExperienceandTerritory";
import ProductandCompany from "../../pages/ProductAndCompany/ProductandCompany";
import KYC from "../../pages/KYC/KYC";
import SubmissionPage from "../../pages/Submission Page/SubmissionPage";

export const routes = [
  {
    key: "login",
    path: "/login",
    exact: true,
    authNeeded: false,
    element: <Login />,
  },
  {
    key: "my-machine",
    path: "/my-machine",
    exact: true,
    authNeeded: true,
    element: <MyMachine />,
  },
  {
    key: "machine-group",
    path: "/machine-group",
    exact: true,
    authNeeded: true,
    element: <MachineGroup />,
  },
  {
    key: "machine-detail",
    path: "/machine-detail",
    exact: true,
    authNeeded: true,
    element: <MachineDetail />,
  },
  {
    key: "sell-machine",
    path: "/sell-machine",
    exact: true,
    authNeeded: true,
    element: <SellMachine />,
  },
  {
    key: "install-machine",
    path: "/install-machine",
    exact: true,
    authNeeded: true,
    element: <InstallMachine />,
  },
  {
    key: "breakdown-call",
    path: "/breakdown-call",
    exact: true,
    authNeeded: true,
    element: <BreakDownCall />,
  },
  {
    key: "preventive-maintainance",
    path: "/preventive-maintenance",
    exact: true,
    authNeeded: true,
    element: <PreventiveMaintenance />,
  },
  {
    key: "service-management",
    path: "/service-management",
    exact: true,
    authNeeded: true,
    element: <ServiceManagement />,
  },
  {
    key: "user-profile",
    path: "/user-profile",
    exact: true,
    authNeeded: true,
    element: <UserProfile />,
  },
  {
    key: "user-team",
    path: "/user-team",
    exact: true,
    authNeeded: true,
    element: <MyTeam />,
  },
  {
    key: "add-team-member",
    path: "/add-team-member",
    exact: true,
    authNeeded: true,
    element: <AddTeamMember />,
  },
  {
    key: "complaint-issue-form",
    path: "/complaint-issue-form",
    exact: true,
    authNeeded: true,
    element: <ComplaintIssueForm />,
  },
  {
    key: "complaint-attachment",
    path: "/complaint-attachment",
    exact: true,
    authNeeded: true,
    element: <ComplaintAttachment />,
  },
  {
    key: "rca-form",
    path: "/rca-form",
    exact: true,
    authNeeded: true,
    element: <RCAForm />,
  },
  {
    key: "preventive-maintenance-check-form",
    path: "/preventive-maintenance-check-form",
    exact: true,
    authNeeded: true,
    element: <PreventiveMaintenanceCheckForm />,
  },
  {
    key: "personalDetails",
    path: "/personalDetails",
    exact: true,
    authNeeded: true,
    element: <PersonalDetail />,
  },
  {
    key: "myAccount",
    path: "/myAccount",
    exact: true,
    authNeeded: true,
    element: <MyAccount />,
  },
  {
    key: "ExperienceandTerritory",
    path: "/ExperienceandTerritory",
    exact: true,
    authNeeded: true,
    element: <ExperienceandTerritory />,
  },
  {
    key: "ProductandCompany",
    path: "/ProductandCompany",
    exact: true,
    authNeeded: true,
    element: <ProductandCompany />,
  },
  {
    key: "KYC",
    path: "/KYC",
    exact: true,
    authNeeded: true,
    element: <KYC />,
  },
  {
    key: "SubmissionPage",
    path: "/SubmissionPage",
    exact: true,
    authNeeded: true,
    element: <SubmissionPage />,
  },
];
