import { ProductsAndCompanyAPI } from "../adapter/service-adapter";

export const ProductsAndCompany = (data) => {
  let promise = new Promise(function (resolve, reject) {
    ProductsAndCompanyAPI(data).then((res) => {
      console.log("ProductsAndCompany>>>", res.data);
      if (res && res.status === 200 || res.status === 201) {
        resolve(res);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};