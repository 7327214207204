
const initialState = {
    myMachineDataReducer: {
        myMachines: [],
        machineGroupData: []
    },
    activeDataReducer: {
        activeGroup: {},
        activeMachine: {}
    },
    activeOrderReducer: {
        breakDownOrder: {},
        preventiveMaintainanceOrder: {},
        complaintFlow: {}
    },
    userDataReducer: {
        userData: {}
    }
}

export default initialState;