import "./Modal.scss";
import React, { useState } from "react";

function Modal(props) {

    return (
        <div className="modal-container">
            <div className="modal-heading">{props.heading}</div>
            {props.html}
            <div className="modal-btn-container">
                <div className="btn-common-cls" onClick={props.handleModalCancelClick}>Cancel</div>
                <div className="btn-common-cls default-fill" onClick={props.handleFunctionBtnClick}>{props.functionBtnText}</div>
            </div>
        </div>
    )
}

export default Modal;
