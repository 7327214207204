import React, { useEffect, useState } from "react";
import { BILL_MATERIAL_CONST } from "../../common/constant/page-constants";
import { AddBillMaterial } from "../../components/AddBillMaterial/AddBillMaterial";
import { BillSummary } from "../../components/BillSummary/BillSummary";
import { addBillOfMaterial, getBillOfMaterials } from "../../helper/BreakdownHelper";
import { addCommonBillOfMaterial, getCommonBillOfMaterials, getComponentTypes } from "../../helper/CommonHelper";

import "./BillMaterial.scss";

export function BillMaterial(props) {

    const [activeState, setActiveState] = useState(BILL_MATERIAL_CONST.STATE.BILL_SUMMARY);
    const [billArray, setBillArray] = useState([]);
    const [flow] = useState(props.flow);
    const [sparePartNameOptions, setSparePartNameOptions] = useState([]);

    useEffect(() => {
        getBills();
        getComponents();

    }, [props.flow]);

    const getBills = () => {

        let data = {
            orderId: props.orderId
        }

        getCommonBillOfMaterials(props.flow, data)
            .then((res) => {
                console.log(res);
                setBillArray(res);

            }).catch((err) => {
                console.log(err);
            })
    }

    const getComponents = () => {

        let data = {
            productId: props.data && props.data.product_id
        }

        getComponentTypes(data)
            .then((res) => {
                console.log(res);

                if (res && res.component_types) {
                    setSparePartNameOptions(res.component_types);
                    console.log(res.component_types);
                }
            }).catch((err) => {
                console.log(err);
            })
    }

    const handleAddClick = () => {
        setActiveState(BILL_MATERIAL_CONST.STATE.ADD_BILL);
    }

    const handleAddBill = (bill) => {

        console.log(bill);
        // billArray.push(bill);
        setActiveState(BILL_MATERIAL_CONST.STATE.BILL_SUMMARY);

        let data = {
            orderId: props.orderId,
            billType: bill.billType,
            spareType: bill.spareType,
            sparePartName: bill.sparePartName,
            jobType: bill.jobType,
            newPartSerialNumber: bill.newPartSerialNumber,
            partMrp: bill.partMrp,
            discount: bill.discount,
            labourType: bill.labourType,
            labourCharges: bill.labourCharges
        }

        console.log(data);
        addCommonBillOfMaterial(flow, data)
            .then((res) => {
                console.log(res);

                getBills();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleCancelAddBill = () => {
        setActiveState(BILL_MATERIAL_CONST.STATE.BILL_SUMMARY);
    }

    const onSubmit = () => {

        console.log("submitting billArray", billArray);
        props.onSubmit();
    }

    return (
        <div className="bill-material-container">
            {
                activeState === BILL_MATERIAL_CONST.STATE.BILL_SUMMARY &&
                <BillSummary
                    billArray={billArray}
                    handleAddClick={() => handleAddClick()}
                    onSubmit={() => onSubmit()}
                />
            }
            {
                activeState === BILL_MATERIAL_CONST.STATE.ADD_BILL &&
                <AddBillMaterial
                    handleAddBill={(item) => handleAddBill(item)}
                    handleCancelAddBill={() => handleCancelAddBill()}
                    sparePartNameOptions={sparePartNameOptions}
                    machineSerialName={props.data && props.data.machine_serial_name}
                />
            }
        </div>
    )
}