import "./ServiceManagement.scss";
import { Header } from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { activeBreakDownOrder, activePreventiveMaintainanceOrder } from "../../modules/actions/action";
import { FLOW, FOOTER, JOB_CARD_COMPLAINT, PAGE_CONST, PROGRESS_STATUS } from "../../common/constant/page-constants";
import ComplaintCard from "../../components/ComplaintCard/ComplaintCard";
import downArrowSvg from "../../common/icons/svg/down-arrow.svg";
import moreIconBreakdown from "../../common/icons/svg/more-icon-breakdown.svg";
import moreIconPreventiveMaintenance from "../../common/icons/svg/more-icon-preventive-maintenance.svg"

import moreItemSvg from "../../common/icons/svg/more-item.svg";
import crossFilledSvg from "../../common/icons/svg/cross-filled.svg";
import moreIconPlus from "../../common/icons/svg/more-icon-plus.svg";
import BackDrop from "../../components/BackDrop/BackDrop";
import { getJobCards } from "../../helper/ServiceHelper";
import { redirect } from "../../common/utils/RedirectionUtils";
import { Footer } from "../../components/Footer/Footer";


function ServiceManagement(props) {

    const navigate = useNavigate();
    const [activeGroup, setActiveGroup] = useState(JOB_CARD_COMPLAINT.GROUP.JOB_CARD);
    const [jobCardFilterType, setJobCardFilterType] = useState(PROGRESS_STATUS.IN_PROGRESS);
    const [filterTypeDrop, setFilterTypeDrop] = useState(false);
    const [isMoreItemsVisible, setIsMoreItemsVisibe] = useState(false);
    const [dataList, setDataList] = useState(null);
    const [dataRaw, setDataRaw] = useState(null);
    const [errMsg, setErrMsg] = useState(null);

    useEffect(() => {
        getJobCardData();
    }, []);

    const getJobCardData = () => {

        getJobCards()
            .then((res) => {
                setDataRaw(res);
                setDataList(res);
                handleJobCardFilter(jobCardFilterType, activeGroup, res);
            }).catch((err) => {
                setErrMsg(err);
            })
    }

    const headerConf = {
        heading: "Service Management",
    }
    const handleHeaderBackClick = () => {

        redirect(navigate, PAGE_CONST.MY_MACHINE);
    }
    const handleHeaderClick = (type) => {

        handleJobCardFilter(jobCardFilterType, type)
        setActiveGroup(type);
    }
    const handleFilterDropActive = () => {
        setFilterTypeDrop(!filterTypeDrop);
    }
    const handleJobCardFilter = (status, type, data) => {

        let dataListFiltered = null;
        let unfilteredData = data || dataRaw;
        if (type === JOB_CARD_COMPLAINT.GROUP.COMPLAINTS) {
            // TODO: remove when filter added in API
        } else {
            if (status) dataListFiltered = unfilteredData && unfilteredData.filter((data) => data.status === status);
        }
        setDataList(dataListFiltered);
        setJobCardFilterType(status);
        setFilterTypeDrop(false);
    }

    const handleCardClick = (event, data) => {
        event.stopPropagation();

        let activeData = {
            id: (data && data.id) || null
        }

        switch (data.type) {
            case PAGE_CONST.PREVENTIVE_MAINTENANCE:
                props.activePreventiveMaintainanceOrder(activeData);
                redirect(navigate, PAGE_CONST.PREVENTIVE_MAINTENANCE);
                break;
            case PAGE_CONST.BREAKDOWN_CALL:
                props.activeBreakDownOrder(activeData);
                redirect(navigate, PAGE_CONST.BREAKDOWN_ORDER);
                break;
        }
    }

    const getHtml = () => {

        let html;

        html = dataList && dataList.map((data) => {
            return <ComplaintCard
                key={`${data.type}-${data.id}`}
                data={data}
                handleClick={(event) => handleCardClick(event, data)}
            />
        })

        return html;
    }

    const handleMoreIconClick = (event) => {
        if (!isMoreItemsVisible) {
            event.stopPropagation();
            setIsMoreItemsVisibe(true);
            let element = document.getElementById('more-icon-container');

            window.addEventListener('click', function (e) {
                if (element && element.contains(e.target)) {

                    let crossIconElement = this.document.getElementById("cross-item-icon");
                    if (crossIconElement && crossIconElement.contains(e.target)) {
                        setIsMoreItemsVisibe(false);
                    }
                } else {
                    setIsMoreItemsVisibe(false);
                }
            });
            return;
        }
    }

    return (
        <div className="job-card-container">
            <Header headerConf={headerConf} handleBackClick={() => handleHeaderBackClick()} />
            <div className={isMoreItemsVisible ?
                "complaint-group-content-container no-pointer-events" :
                "complaint-group-content-container"} >
                <div className="complaint-group-content-header">
                    <div
                        className={activeGroup === JOB_CARD_COMPLAINT.GROUP.JOB_CARD ?
                            "complaint-group-header active-header" : "complaint-group-header"}
                        onClick={() => handleHeaderClick(JOB_CARD_COMPLAINT.GROUP.JOB_CARD)}>
                        Job Card
                    </div>
                    <div
                        className={activeGroup === JOB_CARD_COMPLAINT.GROUP.COMPLAINTS ?
                            "complaint-group-header active-header" : "complaint-group-header"}
                        onClick={() => handleHeaderClick(JOB_CARD_COMPLAINT.GROUP.COMPLAINTS)}>
                        Complaints
                    </div>
                </div>

                <div className="complaint-data-container">
                    <div className="filter-jobcard">
                        <span>{jobCardFilterType}</span>
                        <img src={downArrowSvg} onClick={handleFilterDropActive} />
                        {filterTypeDrop &&
                            <div className="filter-type-drop">
                                <div
                                    className="common-fiter-drop"
                                    onClick={
                                        () => handleJobCardFilter(PROGRESS_STATUS.IN_PROGRESS, activeGroup)
                                    }
                                >Inprogress</div>
                                <div
                                    className="common-fiter-drop"
                                    onClick={
                                        () => handleJobCardFilter(PROGRESS_STATUS.COMPLETED, activeGroup)
                                    }
                                >Closed</div>
                            </div>
                        }
                    </div>
                    {
                        getHtml()
                    }
                </div>
            </div>
            {isMoreItemsVisible && <BackDrop />}
            <div className="more-icon-container" id="more-icon-container" onClickCapture={(event) => handleMoreIconClick(event)}>
                {isMoreItemsVisible &&
                    <div className="more-menu-items">
                        <div className=" more-menu-item"
                            onClick={(event) => handleCardClick(event, {
                                type: PAGE_CONST.BREAKDOWN_CALL
                            })}>
                            <div className="menu-item-text">Breakdown Call</div>
                            <div className="more-item-icon">
                                <img
                                    src={moreIconBreakdown}
                                    className="more-icon-breakdown"
                                    alt="more icon breakdown" />
                            </div>
                        </div>
                        <div className="more-menu-item"
                            onClick={(event) => handleCardClick(event, {
                                type: PAGE_CONST.PREVENTIVE_MAINTENANCE
                            })}>
                            <div className="menu-item-text">Preventive Maintenance</div>
                            <div className="more-item-icon">
                                <img
                                    src={moreIconPreventiveMaintenance}
                                    className="more-icon-preventive-maintenance"
                                    alt="more icon preventive maintenance" />
                            </div>
                        </div>
                    </div>}
                <div className="more-icon"
                //  onClick={() => setIsMoreItemsVisibe(!isMoreItemsVisible)}
                >
                    {!isMoreItemsVisible && <img src={moreIconPlus} className="more-item-icon" alt="more item icon" />}
                    {isMoreItemsVisible && <img src={crossFilledSvg} id="cross-item-icon" className="cross-item-icon" alt="cross item icon" />}
                </div>
            </div>
            <Footer activeStage={FOOTER.SERVICE} />
        </div >
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        activePreventiveMaintainanceOrder: (payload) => dispatch(activePreventiveMaintainanceOrder(payload)),
        activeBreakDownOrder: (payload) => dispatch(activeBreakDownOrder(payload))
    }
}

export default connect(null, mapDispatchToProps)(ServiceManagement);