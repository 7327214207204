import {
  UpdateKYCAPI,
  RetrieveKYCAPI,
} from "../adapter/service-adapter";

export const UpdateKYC = (data) => {
  let promise = new Promise(function (resolve, reject) {
    UpdateKYCAPI(data).then((res) => {
      console.log("UPDATESeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};

export const RetrieveKYC = (data) => {
  let promise = new Promise(function (resolve, reject) {
    RetrieveKYCAPI(data).then((res) => {
      console.log("fetchSeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};
