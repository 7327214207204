import { UpdateSellerDetailsAPI } from "../adapter/service-adapter";

export const UpdateSellerDetails = (data) => {
  let promise = new Promise(function (resolve, reject) {
    UpdateSellerDetailsAPI(data).then((res) => {
      console.log("updateSeller>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};
