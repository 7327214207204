import moment from "moment";
import React, { useState } from "react";
import { handleOnFocusById } from "../../common/utils/CommonUtils";
import CustomSelect from "../CustomSelect/CustomSelect";

import "./MachineStatusForm.scss";

export function MachineStatusForm(props) {

    const yes = "yes";
    const no = "no";

    const [machineStatus, setMachineStatus] = useState(true);
    const [issueObserved, setIssueObserved] = useState(null);
    const [issueDetail, setIssueDetail] = useState("");
    // const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    const [issueObservedOptions] = useState(props.issueOptions)

    const handleSubmit = () => {

        // if (!isPopUpVisible) {
        //     setIsPopUpVisible(true);
        //     return;
        // };

        let data = {
            machineStatus: machineStatus,
            issueDetail: issueDetail,
            // issueObserved: issueObserved.id
            issueObserved: issueObserved && issueObserved.map((item) => item.id),
        }

        props.onSubmit(data);
    }

    return (
        <>
            {<div className={"machine-status-container "}>
                <div className="machine-status-heading">
                    Machine Status
                </div>
                <div className="machine-status-form">
                    <div className="machine-status-container">
                        <div className="machine-status-text">
                            Machine Working Satisfactorily?
                        </div>
                        <div className="machine-status-checkbox">
                            <div className="yes-status-container radio-button">
                                <input
                                    className="radio"
                                    type="radio"
                                    name="machine-status"
                                    checked={machineStatus}
                                    onChange={() => {
                                        props.setEmptyErrMsg();
                                        setMachineStatus(true)
                                    }} />
                                <label className="label">Yes</label>
                            </div>
                            <div className="yes-status-container radio-button">
                                <input
                                    className="radio"
                                    type="radio"
                                    name="machine-status"
                                    checked={!machineStatus}
                                    onChange={() => {
                                        props.setEmptyErrMsg();
                                        setMachineStatus(false)
                                    }} />
                                <label className="label">No</label>
                            </div>
                        </div>
                    </div>
                    {!machineStatus && <div className="issue-observed-container">
                        <div className="label">Issue</div>
                        <CustomSelect
                            id="issue-observed"
                            handleChange={(item) => {
                                props.setEmptyErrMsg();
                                setIssueObserved(item)
                            }}
                            isMulti={true}
                            label='label'
                            options={issueObservedOptions}

                            placeholder='Issue Observed'
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            onFocus={(event) => handleOnFocusById(event.target.id)}
                        />
                    </div>}
                    {issueObserved && issueObserved.filter((item) => item.name.toLowerCase() === "other").length > 0 &&
                        <div className="issue-detail-container">
                            <textarea
                                className="issue-detail textarea"
                                rows="4"
                                placeholder="Details"
                                onChange={(event) => setIssueDetail(event.target.value)}
                            />
                        </div>}
                    {props.errorMsg && <div className="error-msg">{props.err}</div>}
                </div>
                <div className="cta-container">
                    <div className="submit-container" onClick={() => props.onCancel()}>
                        Cancel
                    </div>
                    <div className="add-container" onClick={() => handleSubmit()}>
                        Submit
                    </div>
                </div>
            </div>}
            {/* {isPopUpVisible &&
                <div className="confirmation-modal-container">
                    <div className="confirmation-modal">
                        <div className="modal-heading">
                            Field Return Request
                        </div>
                        <div className="modal-body">
                            <div className="modal-date">
                                Date: {moment(new Date()).format("DD-MM-YYYY")}
                            </div>
                            <div className="modal-content">
                                Are you sure you want to for this machine?
                            </div>
                        </div>
                        <div className="modal-cta-container">
                            <div className="cancel-container cta" onClick={() => setIsPopUpVisible(false)}>
                                Cancel
                            </div>
                            <div className="confirm-container cta" onClick={() => handleSubmit()}>
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            } */}
        </>
    )


}