import PreventiveMaintenanceCheckForm from "../../components/PreventiveMaintenanceCheckForm/PreventiveMaintenanceCheckForm";
import { PAGE_CONST, JOB_CARD_COMPLAINT } from "../constant/page-constants";

export const redirect = (navigate, page, data = {}) => {

    switch (page) {

        case PAGE_CONST.LOGIN:
            navigate('/login');
            break;
        case PAGE_CONST.MY_MACHINE:
            navigate('/my-machine');
            break;
        case PAGE_CONST.MACHINE_GROUP:
            navigate('/machine-group');
            break;
        case PAGE_CONST.MACHINE_DETAIL:
            navigate('/machine-detail');
            break;
        case PAGE_CONST.SELL_MACHINE:
            navigate('/sell-machine');
            break;
        case PAGE_CONST.INSTALL_MACHINE:
            navigate('/install-machine');
            break;
        case PAGE_CONST.BREAKDOWN_ORDER:
            navigate('/breakdown-call');
            break;
        case PAGE_CONST.PREVENTIVE_MAINTENANCE:
            navigate('/preventive-maintenance');
            break;
        case PAGE_CONST.USER_PROFILE:
            navigate('/user-profile');
            break;
        case PAGE_CONST.MY_TEAM:
            navigate('/user-team');
            break;
        case PAGE_CONST.ADD_TEAM_MEMBER:
            navigate('/add-team-member');
            break;
        case PAGE_CONST.SERVICE_MANAGEMENT:
            navigate('/service-management');
            break;
        case PAGE_CONST.COMPLAINT_ISSUE_FORM:
            navigate('/complaint-issue-form');
            break;
        case PAGE_CONST.COMPLAINT_ATTACHMENT:
            navigate('/complaint-attachment');
            break;
        case PAGE_CONST.RCA_FORM:
            navigate('/rca-form');
            break;
        case JOB_CARD_COMPLAINT.GROUP.JOB_CARD:
            navigate('/job-card-complaint');
            break;
        case PAGE_CONST.PREVENTIVE_MAINTENANCE_CHECK_FORM:
            navigate('/preventive-maintenance-check-form');
            break;

    }
}