import React, { useState, useEffect, useRef } from "react";
import "./Detail.scss";
import { registerSeller } from "../../helper/RegisterHelper";
import { UpdateSellerDetails } from "../../helper/UpdateSellerHelper";
import { fetchSeller } from "../../helper/fetchSellerHelper";
import { useAlert } from "react-alert";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { userDataAction } from "../../modules/actions/action";
import { getCookieCustom } from "../../common/utils/StorageUItils";
import { LOGIN_CONST } from "../../common/constant/page-constants";

function PersonalDetail(props) {
  const [formData, setFormData] = useState({
    name: "",
    contactPerson: "",
    email: "",
    address: "",
    number: "",
    city: "",
    pincode: "",
    state: "",
    designation: "",
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const numberRef = useRef(null);
  const pinRef = useRef(null);
  const alert = useAlert();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (getCookieCustom(LOGIN_CONST.ACCESS_TOKEN)) {
      setIsLoggedIn(true);
      fetchSellerDetails();
    }
  }, []);

  const fetchSellerDetails = async () => {
    try {
      const sellerData = await fetchSeller({
        number: props.userData.mobile_no,
      });
      console.log(
        "sellerData>>",
        sellerData,
        sellerData["distributor_team"]["address"]
      );
      if (sellerData) {
        setFormData({
          name: sellerData.name,
          contactPerson: sellerData.name,
          email: sellerData.email,
          address: sellerData["distributor_team"]["address"],
          number: sellerData.mobile_no,
          city: sellerData["distributor_team"]["city"],
          pincode: sellerData["distributor_team"]["pincode"],
          state: sellerData["distributor_team"]["state"],
          designation: sellerData.designation,
        });
      }
    } catch (error) {
      console.error("Error fetching seller details:", error);
      alert.error("Error fetching seller details");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      if (isLoggedIn) {
        await UpdateSellerDetails(formData);
        alert.success("Saved Successfully!");
      } else {
        registerSeller(formData)
          .then((res) => {
            console.log("response", res.data);
            if (res.status === 200) {
              props.userDataAction(res.data);
              alert.success("Saved Successfully!");
              navigate("/SubmissionPage");
            } else if (res.status === 202) {
              alert.error(res.data["error"]);
            }
          })
          .catch((err) => {
            console.error("Error in registerSeller:", err);
            alert.error("Error occurred while registering seller");
          });
      }
    } catch (err) {
      console.error("Error saving personal details:", err);
      alert.error("Error saving personal details");
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  return (
    <div>
      <h2 className="title">Registration</h2>

      <div className="form-container">
        <h1 className="detail-1">
          Personal Details
          {!isEditMode && <span className="page-indicator-1">1/4</span>}
          {isLoggedIn && (
            <button className="edit-button" onClick={toggleEditMode}>
              {isEditMode ? "Cancel" : "Edit"}
            </button>
          )}
        </h1>
        <div className="form-group">
          <label htmlFor="name"></label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Name of Organization*"
            value={formData.name}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactPerson"></label>
          <input
            type="text"
            id="contactPerson"
            name="contactPerson"
            placeholder="Contact Person Name*"
            value={formData.contactPerson}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="number"></label>
          <input
            type="number"
            data-maxlength="10"
            ref={numberRef}
            onWheel={() => {
              numberRef.current.blur();
            }}
            id="number"
            name="number"
            placeholder="Contact Number*"
            value={formData.number}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="designation"></label>
          <select
            id="designation"
            name="designation"
            disabled={isLoggedIn && !isEditMode}
            required
          >
            <option
              style={{
                fontsize: "16px",
                fontcolor: "white",
              }}
              value=""
              disabled
              selected
              hidden
            >
              {formData.designation || 'Designation*'}
            </option>
            <option value="OWNER">Owner</option>
            <option value="ADMIN_MANAGER">Admin/Manager</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="email"></label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Official Email*"
            value={formData.email}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="address"></label>
          <textarea
            id="address"
            name="address"
            placeholder="Address*"
            value={formData.address}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            style={{
              resize: "none",
              width: "calc(100% - 22px)",
              padding: "24px",
              fontsize: "16px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              height: "200px",
              marginLeft: "11px",
            }}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="city"></label>
          <input
            type="text"
            id="city"
            name="city"
            placeholder="City*"
            value={formData.city}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="pincode"></label>
          <input
            type="number"
            ref={pinRef}
            onWheel={() => {
              pinRef.current.blur();
            }}
            id="pincode"
            name="pincode"
            placeholder="Pincode*"
            value={formData.pincode}
            onChange={handleChange}
            maxLength={6}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="state"></label>
          <input
            type="text"
            id="state"
            name="state"
            placeholder="State*"
            value={formData.state}
            onChange={handleChange}
            disabled={isLoggedIn && !isEditMode}
            required
          />
        </div>
        {(isEditMode || !isLoggedIn) && (
          <button className="save" onClick={handleSubmit}>
            Save
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log("mapStateToProps sellerDetails", state.userDataReducer);
  return {
    userData: state.userDataReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userDataAction: (data) => dispatch(userDataAction(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetail);
