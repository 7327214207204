import React, { useEffect, useState } from "react";
import "./MyAccount.scss";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { userStatus } from "../../helper/user-helper";
import { SubmitForReview } from "../../helper/submitHelper";
import { FaCheckCircle } from "react-icons/fa";
import { useAlert } from "react-alert";

function MyAccount(props) {
  const [userStatusData, setUserStatusData] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const alert = useAlert();

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        if (props.userData?.mobile_no) {
          const res = await userStatus({
            mobile_no: props.userData.mobile_no,
          });
          setUserStatusData(res.status);
          if (res.stage === 'KYC_UNDER_REVIEW'){
            setIsSubmitted(true);
          }
        }
      } catch (err) {
        console.error("Error checking user status:", err);
      }
    };

    checkUserStatus();
  }, [props.userData?.mobile_no]);

  useEffect(() => {
    console.log("User status data updated:", userStatusData);
  }, [userStatusData]);

  const getClassNames = (statusField) => {
    return statusField === "filled" ? "sub-list filled" : "sub-list";
  };

  const handleSubmit = async () => {
    console.log("Submit button clicked, current stage:", userStatusData);
    if (
      userStatusData["personal_detail"] === "filled" &&
      userStatusData["experience_and_territory"] === "filled" &&
      userStatusData["products_and_company"] === "filled" &&
      userStatusData["kyc"] === "filled"
    ) {
        try {
          const res = await SubmitForReview({
            mobile_no: props.userData.mobile_no,
          });
          console.log("result:", res);
          if (res.status === 200) {
            setIsSubmitted(true);
          } else {
            alert.error("Failed to submit for review. Please try again.");
          }
        } catch (err) {
          alert.error("Error submitting for review: " + err.message);
        }
    } else {
      alert.error("Please complete all sections before submitting.");
    }
  };

  return (
    <div className="container3">
      <h2 className="title">My Account</h2>
      <div className="container">
        <h1 className="detail">Registration</h1>
        <div className="list">
          <dl>
            <Link to={"/PersonalDetails"}>
              <dt className={getClassNames(userStatusData?.personal_detail)}>
                Personal Details
                {userStatusData?.personal_detail === "filled" && (
                  <FaCheckCircle className="icon" />
                )}
              </dt>
            </Link>
            <Link to={"/ExperienceandTerritory"}>
              <dt
                className={getClassNames(
                  userStatusData?.experience_and_territory
                )}
              >
                Experience & Territory
                {userStatusData?.experience_and_territory === "filled" && (
                  <FaCheckCircle className="icon" />
                )}
              </dt>
            </Link>
            <Link to={"/ProductandCompany"}>
              <dt
                className={getClassNames(userStatusData?.products_and_company)}
              >
                Product & Company
                {userStatusData?.products_and_company === "filled" && (
                  <FaCheckCircle className="icon" />
                )}
              </dt>
            </Link>
            <Link to={"/KYC"}>
              <dt className={getClassNames(userStatusData?.kyc)}>
                KYC
                {userStatusData?.kyc === "filled" && (
                  <FaCheckCircle className="icon" />
                )}
              </dt>
            </Link>
          </dl>
        </div>
        {isSubmitted ? (
          <div className="submission-page">
            <img src="/Submit.png" alt="Logo" className="submission-logo" />
            <p>
              Thank you for your submission. We will review your information and
              get back to you shortly.
            </p>
          </div>
        ) : (
          <button className="button" onClick={handleSubmit}>
            Submit
          </button>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    userData: state.userDataReducer.userData,
  };
};

export default connect(mapStateToProps, null)(MyAccount);
