import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

export const userDataReducer = (state = initialState.activeDataReducer, action) => {
    switch (action.type) {
      case Types.USER_DATA:
        return { ...state, userData: action.payload };
      case Types.LOGIN_SUCCESS:
        return { ...state, userData: action.payload };
      default:
        return state;
    }
}