import { Types } from "../actions/actionTypes";
import initialState from "../store/initial-state";

export const myMachineDataReducer = (state = initialState.myMachineDataReducer, action) => {
    switch (action.type) {
        case Types.SAVE_MY_MACHINES:
            return { ...state, myMachines: action.payload };

        case Types.SAVE_MACHINE_GROUP:
            return { ...state, machineGroupData: action.payload };
        default:
            return state;
    }
}