import React, { useState } from "react";
import CustomSelect from "../CustomSelect/CustomSelect";

import "./AddBillMaterial.scss";

export function AddBillMaterial(props) {

    const sparePart = "SPARE_PART";
    const labour = "LABOUR";
    const request = "request";
    const replace = "replace";
    const OutOfWarranty = "OUT_OF_WARRANTY";
    const InWarranty = "IN_WARRANTY";


    const [billType, setBillType] = useState(null);
    const [spareType, setSpareType] = useState(replace);
    const [sparePartName, setSparePartName] = useState(null);
    const [jobType, setJobType] = useState(null);
    const [newPartSerialNumber, setNewPartSerialNumber] = useState("");
    const [partMrp, setPartMrp] = useState(null);
    const [discount, setDiscount] = useState("");
    const [labourType, setLabourType] = useState(null);
    const [labourCharges, setLabourCharges] = useState(null);

    const [billTypeOptions, setBillTypeOptions] = useState([
        { name: sparePart, label: 'Spare Part' },
        { name: labour, label: 'Labour' },
    ]);
    const [sparePartNameOptions, setSparePartNameOptions] = useState(props.sparePartNameOptions || []);
    const [jobTypeOptions, setJobTypeOptions] = useState([
        { name: InWarranty, label: 'In Warranty' },
        { name: OutOfWarranty, label: 'Out Of Warranty' },
    ]);
    const [labourTypeOptions, setLabourTypeOptions] = useState([
        { name: 'VISITING_CHARGE', label: 'Visiting Charge' },
        { name: 'OTHER_CHARGE', label: 'Other Charge' },
    ]);

    const handleOnSubmit = () => {
        console.log("add bill submit clicked !!");

        let data = {};
        if (billType && billType.name === sparePart) {
            data = {
                billType: billType.name,
                spareType: spareType,
                sparePartName: sparePartName.id,
                jobType: jobType.name,
                newPartSerialNumber: newPartSerialNumber,
                partMrp: partMrp ? partMrp : null,
                discount: discount ? discount : null
            }

        } else if (billType && billType.name === labour) {
            data = {
                billType: billType.name,
                labourType: labourType.name,
                jobType: jobType.name,
                labourCharges: labourCharges
            }
        }

        props.handleAddBill(data);
    }

    return (
        <div className="add-bill-material-container">
            <div className="add-bill-material-heading">
                {props.machineSerialName}
            </div>
            <div className="add-bill-details">
                <CustomSelect
                    id="bill-type"
                    handleChange={(item) => setBillType(item)}
                    label='label'
                    options={billTypeOptions}
                    value={billType}
                    placeholder='Bill Type'
                    components={{
                        IndicatorSeparator: () => null
                    }}
                />
                {
                    billType && billType.name === sparePart &&
                    <>
                        <div className="spare-type-container">
                            <div className="replace-container radio-button">
                                <input
                                    className="radio"
                                    type="radio"
                                    name="spare-part"
                                    checked={spareType === replace}
                                    onChange={() => setSpareType(replace)} />
                                <label className="label">Replace</label>
                            </div>
                            <div className="request-container radio-button">
                                <input
                                    className="radio"
                                    type="radio"
                                    name="spare-part"
                                    checked={spareType === request}
                                    onChange={() => setSpareType(request)} />
                                <label className="label">Request</label>
                            </div>
                        </div>
                        <div className="spare-part-name-container item" >
                            <CustomSelect
                                id="spare-part-name"
                                handleChange={(item) => setSparePartName(item)}
                                label='label'
                                options={sparePartNameOptions}
                                value={sparePartName}
                                placeholder='Spare Part Name'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        <div className="job-type-container item" >
                            <CustomSelect
                                id="job-type"
                                handleChange={(item) => setJobType(item)}
                                label='label'
                                options={jobTypeOptions}
                                value={jobType}
                                placeholder='Job Type'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        {
                            spareType === replace &&
                            <>
                                <div className="new-part-serial-number-container item">
                                    <input
                                        className="new-part-serial-number input"
                                        placeholder="New Part Serial Number"
                                        onChange={(event) => setNewPartSerialNumber(event.target.value)}
                                    />
                                </div>
                                {
                                    jobType && jobType.name === OutOfWarranty &&
                                    <>
                                        <div className="input-symbol-container item">
                                            <div className="rupee-symbol-container" >
                                                <p>&#8377;</p>
                                            </div>
                                            <input
                                                className="input"
                                                placeholder="Part MRP"
                                                value={partMrp}
                                                onChange={(event) => setPartMrp(event.target.value)} />
                                        </div>
                                        <div className="input-symbol-container item">
                                            <div className="rupee-symbol-container" >
                                                <p>%</p>
                                            </div>
                                            <input
                                                className="input"
                                                placeholder="Discount"
                                                value={discount}
                                                onChange={(event) => setDiscount(event.target.value)} />
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </>
                }
                {
                    billType && billType.name === labour &&
                    <>
                        <div className="labour-type-container item" >
                            <CustomSelect
                                id="labour-type"
                                handleChange={(item) => setLabourType(item)}
                                label='label'
                                options={labourTypeOptions}
                                value={labourType}
                                placeholder='Labour Type'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        <div className="job-type-container item" >
                            <CustomSelect
                                id="job-type"
                                handleChange={(item) => setJobType(item)}
                                label='label'
                                options={jobTypeOptions}
                                value={jobType}
                                placeholder='Job Type'
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                        <div className="input-symbol-container item">
                            <div className="rupee-symbol-container" >
                                <p>&#8377;</p>
                            </div>
                            <input
                                className="input"
                                placeholder="Labour Charges"
                                value={labourCharges}
                                onChange={(event) => setLabourCharges(event.target.value)} />
                        </div>
                    </>
                }
            </div>
            <div className="cta-container">
                <div className="submit-container" onClick={() => props.handleCancelAddBill()}>
                    Cancel
                </div>
                <div className="add-container" onClick={() => handleOnSubmit()}>
                    Submit
                </div>
            </div>
        </div>
    )
}