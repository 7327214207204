import { getUserTeamMemberAPI, addTeamMemberAPI, updateTeamMemberAPI , userStatusAPI} from "../adapter/service-adapter";


export const getUserTeamMembers = (data) => {
    let promise = new Promise(function (resolve, reject) {
        getUserTeamMemberAPI().then((res) => {

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}
export const addTeamMember = (data) => {
    let promise = new Promise(function (resolve, reject) {
        addTeamMemberAPI(data).then((res) => {

            if (res && res.data && res.data.status === 200 && res.data.data) {
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}
export const updateTeamMember = (data) => {

    let promise = new Promise(function (resolve, reject) {
        updateTeamMemberAPI(data).then((res) => {
            if (res && res.data && res.data.status === 200 && res.data.data) {
                // console.log("update team member api call res", res)
                resolve(res.data.data);
            } else {
                if (res && res.data && res.data.error) reject(res.data.error.message);
                else reject('error occured!!');
            }
        });
    })

    return promise;
}

export const userStatus = (data) => {
  let promise = new Promise(function (resolve, reject) {
    userStatusAPI(data).then((res) => {
      console.log("userStatus>>>", res.data);
      if (res && res.status === 200) {
        resolve(res.data);
      } else {
        reject("error occured!!");
      }
    });
  });

  return promise;
};