import React, { useEffect, useState } from "react";

import no_bill_svg from "../../common/icons/svg/no-bill.svg";

import "./BillSummary.scss";

export function BillSummary(props) {

    const [billArray, setBillArray] = useState(props.billArray)

    useEffect(() => {

        setBillArray(props.billArray);
    }, [props.billArray]);

    const handleOnSubmit = () => {
        console.log("summary submit clicked !!!");
    }

    const getCharge = (bill) => {

        if (bill && bill.discount) {
            let charge = bill.part_mrp || bill.labour_charges;
            let discount = bill.discount;

            return (charge - (charge / 100) * discount);
        } else {
            return bill.part_mrp || bill.labour_charges;
        }

    }

    const getUpdatedName = (billType) => {

        switch (billType) {

            case "SPARE_PART":
                return "Spare Part";
            case "LABOUR":
                return "Labour";
            case "OUT_OF_WARRANTY":
                return "Out Of Warranty";
            case "IN_WARRANTY":
                return "In Warranty";
            case "VISITING_CHARGE":
                return "Vising Charge";
            case "OTHER_CHARGE":
                return "Other Charge";
            default:
                return billType;
        }
    }

    return (
        <div className="bill-summary-container">
            <div className="bill-summary-heading">
                Bill of Materials
            </div>
            {!billArray || billArray.length === 0 &&
                <div className="no-bill-summary-details">
                    <div className="no-bill-container">
                        <div className="svg-container">
                            <img src={no_bill_svg} className="no-bill svg" />
                        </div>
                        <div className="no-bill-text">No bills added yet</div>
                    </div>
                </div>}
            <div className="bill-summary-container-body">
                {
                    billArray && billArray.length > 0 &&
                    <>
                        {
                            billArray.map((bill, count) => {
                                return (
                                    <div className="bill-container" key={count}>
                                        <div className="left-panel">
                                            <div className="bill-type">
                                                {getUpdatedName(bill.bill_type)}
                                            </div>
                                            <div className="part-name-warranty-details">
                                                <div className="part-name">
                                                    {getUpdatedName(bill.spare_part_component_type_name || bill.labour_type)}
                                                </div>
                                                <div className={`warranty-detail ${bill.job_type}`}>{getUpdatedName(bill.job_type)}</div>
                                            </div>
                                            <div className="price-detail">
                                                {getCharge(bill) != undefined &&
                                                    <><div className="charges">
                                                        <span>&#8377;</span>
                                                        <span className="amount">{getCharge(bill)}</span>
                                                    </div>
                                                        {bill.new_serial_part_name && <div className="pipe">|</div>}
                                                    </>}

                                                {bill.new_serial_part_name && <div className="new-part-number">
                                                    {`Sr No.: ${bill.new_serial_part_name}`}
                                                </div>}
                                            </div>





                                            {/* <div className="warranty-details item">
                                                <div className="warranty">
                                                    {bill.job_type}
                                                </div>
                                                {
                                                    bill.bill_type === 'spare-part' && bill.spare_part_action === "replace" &&
                                                    <>
                                                        <div className="dot"></div>
                                                        <div className="issued">Issued</div>
                                                    </>
                                                }
                                                {
                                                    bill.bill_type === 'spare-part' && bill.spare_part_action === "request" &&
                                                    <>
                                                        <div className="grey-dot"></div>
                                                        <div className="requested">Requested</div>
                                                    </>
                                                }
                                            </div> */}
                                            {/* {
                                                bill.new_serial_part_name &&
                                                <div className="new-part-number item">
                                                    {`Sr No.: ${bill.new_serial_part_name}`}
                                                </div>
                                            } */}
                                        </div>
                                        {/* <div className="right-panel">
                                            {bill.billType === 'spare-part' && <div className="quantity">
                                                1
                                            </div>}
                                            {
                                                (bill.part_mrp || bill.labour_charges) &&
                                                <div className={`${bill.bill_type} charges`}>
                                                    <span>&#8377;</span>
                                                    <span className="amount">{getCharge(bill)}</span>
                                                </div>
                                            }
                                        </div> */}
                                    </div>)
                            })
                        }
                    </>
                }
            </div>
            <div className="cta-container">
                <div className="submit-container" onClick={() => props.onSubmit()}>
                    Submit
                </div>
                <div className="add-container" onClick={() => props.handleAddClick()}>
                    Add
                </div>
            </div>
        </div>
    )
}