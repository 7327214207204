import React, { useEffect, useState } from "react";
import { MACHINE_DETAIL_CONST, PAGE_CONST } from "../../common/constant/page-constants";
import { Header } from "../../components/Header/Header";

import downArrowSvg from "../../common/icons/svg/down-arrow.svg";
import upArrowSvg from "../../common/icons/svg/up-arrow.svg";
import pdfIconSvg from "../../common/icons/svg/pdf-icon.svg";
import menuIconSvg from "../../common/icons/svg/menu-icon.svg";
import crossIconSvg from "../../common/icons/svg/cross-black.svg";

import "./MachineDetail.scss";
import { redirect } from "../../common/utils/RedirectionUtils";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getMachineDetailsBySerialId } from "../../helper/machineHelper";
import moment from "moment";

function MachineDetail(props) {
    const navigate = useNavigate();

    const [activeItem, setActiveItem] = useState(MACHINE_DETAIL_CONST.GENERAL);
    const [activeContent, setActiveContent] = useState("");
    const [machineData, setMachineData] = useState(null);
    const [isReportMenuActiveId, setIsReportMenuActiveId] = useState(null);
    const [activeReportCta, setActiveReportCta] = useState(null);

    const loadData = () => {
        // let data = {
        //     "product_details": {
        //         "warranty_status": "In Warranty",
        //         "pm_due_date": "07-12-2022",
        //         "stock_entry_date": "2022-08-23T09:51:43.355980Z",
        //         "software_version": "N45 - 2",
        //         "firmware_version": "N45 - 1",
        //     },
        //     "dealer_details": {
        //         "organisation_name": "test org",
        //         "contact_person": "contact person",
        //         "contact_number": "8960032462",
        //         "contact_mail": "vbhoria@gmail.com",
        //         "address": "test address",
        //         "pincode": "123448"
        //     },
        //     "customer_details": {
        //         "organisation_name": "test org",
        //         "contact_person": "contact person",
        //         "contact_number": "8960032462",
        //         "contact_mail": "vbhoria@gmail.com",
        //         "address": "test address",
        //         "pincode": "123448"
        //     },
        //     "report_details": [{
        //         "id": 1,
        //         "type": "Installation Report",
        //         "date": "12-03-2022",
        //         "url": "xyz_url"
        //     }, {
        //         "id": 2,
        //         "type": "Service Report",
        //         "date": "12-03-2022",
        //         "url": "xyz_url"
        //     }]
        // };

        // setMachineData({ ...data });

    }


    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {

        let id = props.activeMachine && props.activeMachine.id;
        if (id) getMachineDetails(id);
    }, [props.activeMachine]);


    const getMachineDetails = (id) => {
        getMachineDetailsBySerialId(id)
            .then((res) => {
                console.log(res);
                setMachineData(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleHeaderClick = (item) => {

        setActiveItem(item);
    }

    const handleContentClick = (item) => {

        if (item === activeContent) item = "";
        setActiveContent(item);
    }

    const handleHeaderBackClick = () => {

        redirect(navigate, PAGE_CONST.MACHINE_GROUP);
    }

    return (
        <div className="machine-detail-container">
            <Header handleBackClick={() => handleHeaderBackClick()} />
            <div className="machine-header">
                <div className="machine-name">{(props.activeMachine && props.activeMachine.serial_name)}</div>
                <div className="machine-status">{props.activeMachine && props.activeMachine.status}</div>
            </div>
            <div className="machine-detail-content-container">
                <div className="machine-detail-content-header">
                    <div
                        className={activeItem === MACHINE_DETAIL_CONST.GENERAL ?
                            "machine-content-header machine active-header" : "machine-content-header machine"}
                        onClick={() => handleHeaderClick(MACHINE_DETAIL_CONST.GENERAL)}>
                        General
                    </div>
                    <div
                        className={activeItem === MACHINE_DETAIL_CONST.REPORTS ?
                            "machine-content-header spare active-header" : "machine-content-header spare"}
                        onClick={() => handleHeaderClick(MACHINE_DETAIL_CONST.REPORTS)}>
                        Reports
                    </div>
                </div>
                {activeItem === MACHINE_DETAIL_CONST.GENERAL && <div className="machine-detail-content">
                    <div
                        className="product-container content"
                        onClick={() => handleContentClick(MACHINE_DETAIL_CONST.PRODUCT)}>
                        <div className="product-header content-header">
                            <div className="name">
                                Product Details
                            </div>
                            <div className="arrow-icon-container">
                                {activeContent === MACHINE_DETAIL_CONST.PRODUCT &&
                                    <img className="arrow-icon" src={upArrowSvg} alt="down-arrow" />}
                                {activeContent !== MACHINE_DETAIL_CONST.PRODUCT &&
                                    <img className="arrow-icon" src={downArrowSvg} alt="up-arrow" />}
                            </div>
                        </div>
                        {activeContent === MACHINE_DETAIL_CONST.PRODUCT
                            && machineData && machineData.product_details &&
                            <div className="product-details content-details">
                                {machineData.product_details.warranty_status &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Status</div>
                                        <div className="item-content warranty">
                                            {machineData.product_details.warranty_status}
                                        </div>
                                    </div>}
                                {machineData.product_details.pm_due_date &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">PM Due Date</div>
                                        <div className="item-content">
                                            {moment(machineData.product_details.pm_due_date).format("DD-MMM-YYYY")}
                                        </div>
                                    </div>}
                                {machineData.product_details.pm_due_date &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Software Version</div>
                                        <div className="item-content">
                                            {machineData.product_details.software_version}
                                        </div>
                                    </div>}
                                {machineData.product_details.stock_entry_date &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Stock Entry Date</div>
                                        <div className="item-content">
                                            {moment(machineData.product_details.stock_entry_date).format("DD-MMM-YYYY")}
                                        </div>
                                    </div>}
                                {machineData.product_details.firmware_version &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Firmware Version</div>
                                        <div className="item-content">
                                            {machineData.product_details.firmware_version}
                                        </div>
                                    </div>}
                            </div>
                        }
                    </div>
                    <div className="dealer-container content" onClick={() => handleContentClick(MACHINE_DETAIL_CONST.DEALER)}>
                        <div className="product-header content-header">
                            <div className="name">
                                Dealer Details
                            </div>
                            <div className="arrow-icon-container">
                                {activeContent === MACHINE_DETAIL_CONST.DEALER &&
                                    <img className="arrow-icon" src={upArrowSvg} alt="down-arrow" />}
                                {activeContent !== MACHINE_DETAIL_CONST.DEALER &&
                                    <img className="arrow-icon" src={downArrowSvg} alt="up-arrow" />}
                            </div>
                        </div>
                        {activeContent === MACHINE_DETAIL_CONST.DEALER && machineData && machineData.dealer_details &&
                            <div className="product-details content-details">
                                {machineData.dealer_details.organisation_name &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Name of Organisation</div>
                                        <div className="item-content">
                                            {machineData.dealer_details.organisation_name}
                                        </div>
                                    </div>}
                                {machineData.dealer_details.contact_person &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Contact person</div>
                                        <div className="item-content">
                                            {machineData.dealer_details.contact_person}
                                        </div>
                                    </div>}
                                {machineData.dealer_details.contact_number &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Contact Number</div>
                                        <div className="item-content">
                                            {machineData.dealer_details.contact_number}
                                        </div>
                                    </div>}
                                {machineData.dealer_details.contact_email &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Conatct Email ID</div>
                                        <div className="item-content">
                                            {machineData.dealer_details.contact_email}
                                        </div>
                                    </div>}
                                {machineData.dealer_details.address &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Address</div>
                                        <div className="item-content">
                                            {machineData.dealer_details.address}
                                        </div>
                                    </div>}
                                {machineData.dealer_details.pincode &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Pincode</div>
                                        <div className="item-content">
                                            {machineData.dealer_details.pincode}
                                        </div>
                                    </div>}
                            </div>
                        }
                    </div>
                    <div className="customer-container content" onClick={() => handleContentClick(MACHINE_DETAIL_CONST.CUSTOMER)}>
                        <div className="customer-header content-header">
                            <div className="name">
                                Customer Details
                            </div>
                            <div className="arrow-icon-container">
                                {activeContent === MACHINE_DETAIL_CONST.CUSTOMER &&
                                    <img className="arrow-icon" src={upArrowSvg} alt="down-arrow" />}
                                {activeContent !== MACHINE_DETAIL_CONST.CUSTOMER &&
                                    <img className="arrow-icon" src={downArrowSvg} alt="up-arrow" />}
                            </div>
                        </div>
                        {activeContent === MACHINE_DETAIL_CONST.CUSTOMER && machineData && machineData.customer_details &&
                            <div className="product-details content-details">
                                {machineData.customer_details.organisation_name &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Name of Organization</div>
                                        <div className="item-content">
                                            {machineData.customer_details.organisation_name}
                                        </div>
                                    </div>}
                                {machineData.customer_details.contact_person &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Contact person</div>
                                        <div className="item-content">
                                            {machineData.customer_details.contact_person}
                                        </div>
                                    </div>}
                                {machineData.customer_details.contact_number &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Contact Number</div>
                                        <div className="item-content">
                                            {machineData.customer_details.contact_number}
                                        </div>
                                    </div>}
                                {machineData.customer_details.contact_email &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Conatct Email ID</div>
                                        <div className="item-content">
                                            {machineData.customer_details.contact_email}
                                        </div>
                                    </div>}
                                {machineData.customer_details.address &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Address</div>
                                        <div className="item-content">
                                            {machineData.customer_details.address}
                                        </div>
                                    </div>}
                                {machineData.customer_details.pincode &&
                                    <div className="product-detail-item content-details-item">
                                        <div className="item-header">Pincode</div>
                                        <div className="item-content">
                                            {machineData.customer_details.pincode}
                                        </div>
                                    </div>}
                            </div>
                        }
                    </div>
                </div>}
                {
                    activeItem === MACHINE_DETAIL_CONST.REPORTS &&
                    <div className="report-container">
                        {
                            (!machineData || !machineData.report_details) &&
                            <div className="no-report-container">
                                Reports are not generated yet.
                            </div>
                        }
                        {
                            machineData && machineData.report_details &&
                            machineData.report_details.map((report) => {
                                return (<div className="product-report-item">
                                    <div className="report-details">
                                        <div className="icon-container">
                                            <img className="svg" src={pdfIconSvg} />
                                        </div>
                                        <div className="report-data-container">
                                            <div className="report-name">
                                                {report.type}
                                            </div>
                                            <div className="report-date">
                                                {moment(report.date).format("DD-MM-YYYY")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="download-details">
                                        <div className="download-icon-container">
                                            {isReportMenuActiveId !== report.id && <div className="menu-icon-container"
                                                onClick={() => setIsReportMenuActiveId(report.id)}>
                                                <img className="svg" src={menuIconSvg} />
                                            </div>}
                                            {isReportMenuActiveId === report.id && <div className="menu-icon-container"
                                                onClick={() => setIsReportMenuActiveId(null)}>
                                                <img className="svg" src={crossIconSvg} />
                                            </div>}
                                        </div>
                                        {isReportMenuActiveId === report.id && <div className="report-cta-container">
                                            <div
                                                className={activeReportCta === "Share" ? "cta-item active-item" : "cta-item"}
                                                onClick={() => setActiveReportCta("Share")}

                                            >Share</div>
                                            <div
                                                className={activeReportCta === "Download" ? "cta-item active-item" : "cta-item"}
                                                onClick={() => setActiveReportCta("Download")}
                                            >
                                                <a href={report.url} >Download</a>
                                            </div>
                                        </div>}
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                }
            </div>
        </div >
    )
}

const mapStateToProps = state => {

    return {
        activeMachine: state.activeDataReducer.activeMachine || {}
    };
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(MachineDetail);