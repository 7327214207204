import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LOGIN_CONST, PAGE_CONST } from "../../common/constant/page-constants";
import { useAlert } from "react-alert";
import { redirect } from "../../common/utils/RedirectionUtils";
import {
  getCookieCustom,
  setCookieCustom,
} from "../../common/utils/StorageUItils";
import { sendOtp, verifyOtp } from "../../helper/loginHelper";
import { userDataAction } from "../../modules/actions/action";
import "./Login.scss";
import { handleOnFocusById } from "../../common/utils/CommonUtils";
import { userStatus } from "../../helper/user-helper.js";
import { useSelector } from "react-redux";

function Login(props) {
  const navigate = useNavigate();
  const alert = useAlert();
  const logRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [currentState, setCurrentState] = useState(LOGIN_CONST.SEND_OTP);
  const [otpObj, setOtpObj] = useState({
    "digit-1": "",
    "digit-2": "",
    "digit-3": "",
    "digit-4": "",
  });
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [timer, setTimer] = useState(30);
  const [canResendOtp, setCanResendOtp] = useState(false);

  const fetchUserStatus = async () => {
    try {
      const res = await userStatus({
        mobile_no: phoneNumber,
      });
      if (res?.stage === "ACTIVE") {
        navigate("/my-machine");
      } else if (res?.stage === "UNDER_REVIEW") {
       navigate("/personalDetails");
      } else if (res?.stage === "KYC_STAGE") {
        navigate("/myAccount");
      } else if (res.status === "KYC_UNDER_REVIEW") {
        navigate("myAccount");
      } else if (res?.stage === "REJECTED") {
        alert.show("Sorry! Your details have been rejected");
      } else {
        navigate("/personalDetails");
      }
    } catch (err) {
      console.error("Error checking user status:", err);
    }
  };

  useEffect(() => {
    if (
      getCookieCustom(LOGIN_CONST.ACCESS_TOKEN) &&
      getCookieCustom(LOGIN_CONST.REFRESH_TOKEN)
    ) {
      fetchUserStatus();
    }
  }, []);

  useEffect(() => {
    if (
      currentState === LOGIN_CONST.SEND_OTP &&
      phoneNumber &&
      phoneNumber.length === 10
    ) {
      setIsButtonEnabled(true);
    } else if (currentState === LOGIN_CONST.VERIFY_OTP) {
      if (
        otpObj["digit-1"] &&
        otpObj["digit-2"] &&
        otpObj["digit-3"] &&
        otpObj["digit-4"]
      ) {
        setIsButtonEnabled(true);
      } else {
        setIsButtonEnabled(false);
      }
    } else {
      setIsButtonEnabled(false);
    }
  }, [phoneNumber, otpObj]);

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setCanResendOtp(true);
    }
  }, [timer]);

  const handleInputChange = (event) => {
    setErrorMsg(null);
    let value = event.target.value;
    if (value === undefined) return;
    setPhoneNumber(value);
  };

  const handleChange = (event) => {
    setErrorMsg(null);
    let dataObj = otpObj;
    let value = event && event.target && event.target.value;
    dataObj = { ...dataObj, [event.target.id]: value };
    setOtpObj({ ...dataObj });
  };

  const handleKeyDown = (event, next, previous) => {
    let key = event.keyCode || event.charCode;
    if (
      event.code === "Backspace" ||
      key == 8 ||
      key == 46 ||
      event.target.value == ""
    ) {
      let dataObj = otpObj;
      dataObj[event.target.id] = "";
      setOtpObj({ ...dataObj });
      if (!previous) return;
      let ele = document.getElementById(previous);
      ele.focus();
      return;
    } else {
      if (!next) return;
      let ele = document.getElementById(next);
      ele.focus();
    }
  };

  const handleSubmit = () => {
    setErrorMsg(null);
    if (currentState === LOGIN_CONST.SEND_OTP) {
      let data = { phoneNumber: phoneNumber };
      sendOtp(data)
        .then((result) => {
          console.log("result: " , result);
          if(result.data.status === 400) {
            alert.error(result?.data.error.message);
          } else if(result?.data.status === 200){
              setCurrentState(LOGIN_CONST.VERIFY_OTP);
              setTimer(60);
              setCanResendOtp(false);
          }
        })
        .catch((err) => {
          setErrorMsg(err);
          console.log("err:", err);
        });
    } else {
      let data = {
        phoneNumber: phoneNumber,
        otp:
          "" +
          otpObj["digit-1"] +
          otpObj["digit-2"] +
          otpObj["digit-3"] +
          otpObj["digit-4"],
      };
      verifyOtp(data)
        .then((data) => {
          const access_token = data[LOGIN_CONST.ACCESS_TOKEN];
          const refresh_token = data[LOGIN_CONST.REFRESH_TOKEN];
          if (access_token && refresh_token) {
            setCookieCustom(LOGIN_CONST.ACCESS_TOKEN, access_token);
            setCookieCustom(LOGIN_CONST.REFRESH_TOKEN, refresh_token);
          }
          props.userDataAction(data.user);
          fetchUserStatus(); // Check user status after successful OTP verification
        })
        .catch((err) => {
          console.log(err);
          setErrorMsg(err);
        });
    }
  };

  const handleResendOtp = () => {
    let data = { phoneNumber: phoneNumber };
    sendOtp(data)
      .then(() => {
        setTimer(60);
        setCanResendOtp(false);
      })
      .catch((err) => {
        setErrorMsg(err);
        console.log("err:", err);
      });
  };

   const handleRegisterClick = () => {
     navigate("/personalDetails");
   };


  return (
    <div className="login-container">
      <div className="login-form">
        {currentState === LOGIN_CONST.SEND_OTP && (
          <div className="sign-in-container">
            <div className="login-header">Sign In</div>
            <div className="input-container">
              <input
                className="input"
                id="phone-input"
                placeholder="Mobile Number"
                onChange={(event) => handleInputChange(event)}
                type="number"
                ref={logRef}
                onWheel={() => {
                  logRef.current.blur();
                }}
                maxLength={10}
                minLength={10}
                onFocus={(event) => handleOnFocusById(event.target.id)}
              />
            </div>
            <div className="logo-header">
              <label htmlFor="name"></label>
              <img src="/Noccarc_logo.png" alt="logo" />
            </div>
            <div className="otp-text">
              OTP will be sent on the registered number
            </div>
            <div className="new-user">
              <h4>New User?</h4>
              <button className="register" onClick={handleRegisterClick}>
                Register
              </button>
            </div>
          </div>
        )}
        {currentState === LOGIN_CONST.VERIFY_OTP && (
          <div className="verification-container">
            <div className="login-header">OTP Verification</div>
            <div className="verification-text">
              The OTP has been sent to your Mobile Number:
              <span className="phone-number">{` ${phoneNumber}. `}</span>
              <span
                className="change-number"
                onClick={() => {
                  setCurrentState(LOGIN_CONST.SEND_OTP);
                  setOtpObj({});
                }}
              >
                Change Number
              </span>
            </div>
            <div className="otp-container">
              <input
                className="input-digit"
                type="number"
                id="digit-1"
                autoComplete="off"
                value={otpObj["digit-1"]}
                maxLength="1"
                onChange={(event) => handleChange(event, "digit-2", null)}
                onKeyUp={(event) => handleKeyDown(event, "digit-2", null)}
              />
              <input
                className="input-digit"
                type="number"
                id="digit-2"
                autoComplete="off"
                value={otpObj["digit-2"]}
                maxLength="1"
                onChange={(event) => handleChange(event, "digit-3", "digit-1")}
                onKeyUp={(event) => handleKeyDown(event, "digit-3", "digit-1")}
              />
              <input
                className="input-digit"
                type="number"
                id="digit-3"
                autoComplete="off"
                value={otpObj["digit-3"]}
                maxLength="1"
                onChange={(event) => handleChange(event, "digit-4", "digit-2")}
                onKeyUp={(event) => handleKeyDown(event, "digit-4", "digit-2")}
              />
              <input
                className="input-digit"
                type="number"
                id="digit-4"
                autoComplete="off"
                value={otpObj["digit-4"]}
                maxLength="1"
                onChange={(event) =>
                  handleChange(event, "submit-button", "digit-3")
                }
                onKeyUp={(event) =>
                  handleKeyDown(event, "submit-button", "digit-3")
                }
              />
            </div>
            {errorMsg && <div className="error-msg">{errorMsg}</div>}
            <div className="resend-otp-container">
              {canResendOtp ? (
                <span className="resend-otp" onClick={handleResendOtp}>
                  Resend OTP
                </span>
              ) : (
                <span className="resend-timer">{`Resend OTP in ${timer}s`}</span>
              )}
            </div>
          </div>
        )}
        <div className="button-container">
          <button
            className={
              isButtonEnabled
                ? "submit-button"
                : "submit-button button-disabled"
            }
            id="submit-button"
            onClick={() => handleSubmit()}
          >
            {currentState}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    userDataAction: (payload) => dispatch(userDataAction(payload)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
