import React, { useEffect, useRef, useState } from "react";
import "./ExperienceandTerritory.scss";
import { sellerExperienceTerritory } from "../../helper/sellerExperienceAndTerritoryHelper";
import {
  fetchSellerExperienceTerritory,
  UpdateSellerExperienceTerritory,
} from "../../helper/UpdateandRetrieveExperienceTerritoryHelper";
import { useAlert } from "react-alert";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userStatus } from "../../helper/user-helper.js";
import { LOGIN_CONST } from "../../common/constant/page-constants";
import { getCookieCustom } from "../../common/utils/StorageUItils";

function ExperienceandTerritory(props) {
  const alert = useAlert();
  const yearRef = useRef(null);
  const tenderRef = useRef(null);
  const staffRef = useRef(null);
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [userStatusState, setUserStatusState] = useState("");
  const [experienceDetails, setExperienceDetails] = useState({
    team: props.userData.team_id,
    last_3_years_average_annual_turnover: "",
    services_provided: "",
    sales_territory: "",
    year_of_incorporation: "",
    number_of_government_tender_received: "",
    size_of_staff: "",
  });

  console.log("team_id>>>", props.userData.team_id);

  useEffect(() => {
    if (props.userData.mobile_no) {
      setIsLoggedIn(true);
    }
  }, [props.userData.mobile_no]);

  const fetchExperienceDetails = async () => {
    try {
      const data = await fetchSellerExperienceTerritory({
        mobile_no: props.userData.mobile_no,
      });
      if (data) {
        setExperienceDetails({
          mobile_no: props.userData.mobile_no,
          team: data.team,
          last_3_years_average_annual_turnover:
            data.last_3_years_average_annual_turnover,
          services_provided: data.services_provided,
          sales_territory: data.sales_territory,
          year_of_incorporation: data.year_of_incorporation,
          number_of_government_tender_received:
            data.number_of_government_tender_received,
          size_of_staff: data.size_of_staff,
        });
      }
    } catch (error) {
      console.error("Error fetching experience details:", error);
      alert.error("Error fetching experience details");
    }
  };

  const fetchUserStatus = async () => {
    try {
      const res = await userStatus({
        mobile_no: props.userData.mobile_no,
      });
      if (res) {
        console.log("Fetching user status", res);
        setUserStatusState(res);
        if (res.status.experience_and_territory == "filled") {
          fetchExperienceDetails();
        }
      }
    } catch (err) {
      console.error("Error checking user status:", err);
    }
  };

  useEffect(() => {
    fetchUserStatus();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setExperienceDetails({
      ...experienceDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEditMode) {
      UpdateSellerExperienceTerritory(experienceDetails)
        .then((result) => {
          alert.show("Saved successfully!");
          console.log(
            "-----------------------------------------------------------------------",
            result
          );
        })
        .catch((err) => {
          alert.error("Error occured!");
        });
    } else if (isLoggedIn) {
      sellerExperienceTerritory(experienceDetails)
        .then((result) => {
          if (result.status === 200 || result.status === 201) {
            alert.show("Saved successfully!");
          } else if (result.status === 202) {
            alert.error(result.data["error"]);
          }
          console.log(
            "-----------------------------------------------------------------------",
            result
          );
        })
        .catch((err) => {
          console.log("error occured!", err);
          alert.error("Error occured!" + err);
        });
    }
    setIsEditMode(false);
  };

  const toggleEditMode = () => {
    if (
      isLoggedIn &&
      (userStatusState.stage === "KYC_STAGE" ||
        userStatusState.stage === "KYC_UNDER_REVIEW") &&
      userStatusState.status.experience_and_territory === "filled"
    ) {
      setIsEditMode(!isEditMode);
    }
  };

  const isInputDisabled =
    isLoggedIn &&
    (userStatusState.stage === "KYC_STAGE" ||
      userStatusState.stage === "KYC_UNDER_REVIEW") &&
    userStatusState.status.experience_and_territory === "filled" &&
    !isEditMode;

  const isFormValid = () => {
    return (
      experienceDetails.last_3_years_average_annual_turnover &&
      experienceDetails.services_provided &&
      experienceDetails.sales_territory &&
      experienceDetails.year_of_incorporation &&
      experienceDetails.number_of_government_tender_received &&
      experienceDetails.size_of_staff
    );
  };

  return (
    <div>
      <h2 className="title">Registration</h2>
      <div className="container-1">
        <h1 className="info1">
          Experience & Territory
          {!isEditMode && <span className="page-indicator-2">2/4</span>}
          {isLoggedIn &&
            (userStatusState.stage === "KYC_STAGE" ||
              userStatusState.stage === "KYC_UNDER_REVIEW") &&
            userStatusState.status.experience_and_territory === "filled" && (
              <button className="edit-button" onClick={toggleEditMode}>
                {isEditMode ? "Cancel" : "Edit"}
              </button>
            )}
        </h1>
        <form>
          <div className="form-group">
            <label htmlFor="turnover"></label>
            <select
              id="turnover"
              name="last_3_years_average_annual_turnover"
              value={experienceDetails.last_3_years_average_annual_turnover}
              onChange={handleChange}
              disabled={isInputDisabled}
              required
            >
              <option value="" disabled hidden>
                Last 3 Years Average Annual Turnover*
              </option>
              <option value="<3Cr">Less than 3Cr</option>
              <option value="3-5C">3 to 5Cr</option>
              <option value="5-10Cr">5 to 10Cr</option>
              <option value="10-20Cr">10 to 20Cr</option>
              <option value="20+Cr">20+ Cr</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="services"></label>
            <select
              id="services"
              name="services_provided"
              value={experienceDetails.services_provided}
              onChange={handleChange}
              disabled={isInputDisabled}
              required
            >
              <option value="" disabled hidden>
                Services you provide to customers*
              </option>
              <option value="Installation Support">Installation Support</option>
              <option value="Preventive Maintenance">
                Preventive Maintenance
              </option>
              <option value="Service & Breakdown call">
                Service & Breakdown call
              </option>
              <option value="No we don't provide any service">
                No we don't provide any service
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="name"></label>
            <input
              type="text"
              id="name"
              name="sales_territory"
              placeholder="Sales (Territory District)*"
              value={experienceDetails.sales_territory}
              onChange={handleChange}
              disabled={isInputDisabled}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="year"></label>
            <input
              type="number"
              ref={yearRef}
              onWheel={() => {
                yearRef.current.blur();
              }}
              id="year"
              name="year_of_incorporation"
              placeholder="Year of incorporation of Firm*"
              value={experienceDetails.year_of_incorporation}
              onChange={handleChange}
              disabled={isInputDisabled}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="tenders"></label>
            <input
              type="number"
              ref={tenderRef}
              onWheel={() => {
                tenderRef.current.blur();
              }}
              id="tenders"
              name="number_of_government_tender_received"
              placeholder="Number of Government tenders received*"
              value={experienceDetails.number_of_government_tender_received}
              onChange={handleChange}
              disabled={isInputDisabled}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="staff"></label>
            <input
              type="number"
              ref={staffRef}
              onWheel={() => {
                staffRef.current.blur();
              }}
              id="staff"
              name="size_of_staff"
              placeholder="Number of staff*"
              value={experienceDetails.size_of_staff}
              onChange={handleChange}
              disabled={isInputDisabled}
              required
            />
          </div>
          {((userStatusState?.status?.experience_and_territory !== "filled" &&
            isFormValid()) ||
            (userStatusState?.status?.experience_and_territory === "filled" &&
              isEditMode &&
              isFormValid())) && (
            <button
              type="save"
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              Save
            </button>
          )}
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log("mapStateToProps experience", state.userDataReducer);
  return {
    userData: state.userDataReducer.userData,
  };
};

export default connect(mapStateToProps, null)(ExperienceandTerritory);
