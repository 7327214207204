import moment from "moment";
import React from "react";
import { MACHINE_GROUP_CONST, PAGE_CONST, PROGRESS_STATUS } from "../../common/constant/page-constants";

import "./MachineCard.scss";

export function MachineCard(props) {

    const handleInstallClick = (event) => {
        event.stopPropagation();

        props.onInstallClick(props.data);
    }

    return (
        <div className="machine-card-container" onClick={() => props.onCardClick(props.data)} key={props.id}>
            <div className="machine-name">
                {
                    props.data.serial_name
                }
            </div>
            {props.data.customer &&
                <div className="address">
                    {`${props.data.customer.organization_name}, ${props.data.customer.city}, ${props.data.customer.state}`}
                </div>}
            {props.data.stocked_at && props.data.status === MACHINE_GROUP_CONST.IN_STOCK &&
                <div className="machine-date">
                    {`Stock Entry Date: ${moment(props.data.stocked_at).format(MACHINE_GROUP_CONST.DATE_FORMAT)}`}
                </div>}
            {props.data.installed_at && props.data.status === MACHINE_GROUP_CONST.INSTALLED &&
                <div className="machine-date">
                    {`Installation Date: ${moment(props.data.installed_at).format(MACHINE_GROUP_CONST.DATE_FORMAT)}`}
                </div>}
            {props.data.sold_at && props.data.status === MACHINE_GROUP_CONST.PENDING &&
                <div className="machine-date">
                    {`Sold On: ${moment(props.data.sold_at).format(MACHINE_GROUP_CONST.DATE_FORMAT)}`}
                </div>}
            {props.data.status === MACHINE_GROUP_CONST.PENDING &&
                < div
                    className={props.data.sub_status ? `install-button ${props.data.sub_status.toLowerCase()}` : "install-button"}
                    onClick={(event) => handleInstallClick(event)}
                >
                    {props.data.sub_status === PROGRESS_STATUS.IN_PROGRESS ? 'Continue Installation' : 'Install'}
                </div>}
        </div >
    );
}