import React, { useState } from "react";
import Select from "react-select";
import { handleOnFocusById } from "../../common/utils/CommonUtils";

export default function CustomSelect(props) {
  const [data, setData] = useState(null);

  const customStyles = {
    container: (base) => ({
      ...base,
      height: "100%",
      minWidth: "142px",
    }),
    control: (base) => ({
      ...base,
      height: "100%",
      minHeight: props.controlHeight || "54px",
      borderStyle: props.borderStyle || "solid",
      borderRadius: "8px",
      // backgroundColor: '#333333'
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "16px",
      // padding: '2px 16px'
      // color: '#00173D'
    }),
    menu: (base) => ({
      ...base,
      marginTop: "5px",
      marginBottom: "0px",
      zIndex: "1000",
      // backgroundColor: '#333333',
      "&:hover": {
        borderColor: "red",
      },
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "2px 16px",
    }),
    option: (base, state) => ({
      ...base,
      padding: "17px",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#6d1df0",
      },
      backgroundColor: state.isSelected ? "#6d1df0" : "",
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: "18px",
      // color: '#FFFFFF'
    }),
    input: (base) => ({
      ...base,
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "16px",
      // color: '#FFFFFF;'
    }),
  };

  const handleChange = (item) => {
    setData(item);

    props.handleChange(item);
  };

  return (
    <Select
      id={props.id}
      className={props.className || "custom-select"}
      value={props.hasOwnProperty("value") ? props.value : data}
      onChange={handleChange}
      isClearable={props.isClearable || false}
      options={props.options || []}
      getOptionValue={(option) => option[props["label"]] || option.name}
      getOptionLabel={(option) => option[props["label"]] || option.name}
      placeholder={props.placeholder}
      styles={customStyles}
      isMulti={props.isMulti || false}
      // menuIsOpen={true}
      components={{
        IndicatorSeparator: () => null,
      }}
      onFocus={() => {
        handleOnFocusById(`${props.id || props.placeholder}`);
      }}
    />
  );
}

// behavior Optional
// Defines the transition animation. One of auto or smooth. Defaults to auto.

// block Optional
// Defines vertical alignment. One of start, center, end, or nearest. Defaults to start.

// inline Optional
// Defines horizontal alignment. One of start, center, end, or nearest. Defaults to nearest.
